import { responseFormat } from '../Util/Util';
import api from './API';

const pathList = {
    list: `licensed-employee/`
};

export const licensedEmployeeList = async () => {
    // return faqCategory;

    try {
        const response = await api.getCall(pathList.list);

        return response;
    } catch(e) {
        console.log(e.toString());

        return responseFormat(-1, "Failed to do API Call!");
    }
}