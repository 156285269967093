import * as actionTypes from './product-types';

const INITIAL_STATE = {
    data: [],
    isLoaded: false
}

const productReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case actionTypes.LOAD_PRODUCTS: 
            return {
                ...state,
                data: action.payload,
            }
        default: 
            return state;
    }
};

export default productReducer;