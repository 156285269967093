import Init from '../Init/Init';
import { responseFormat } from '../Util/Util';

const products = [
    {
        image: `${Init.config.imageFolder}product-equity-1.png`,
        title: "Equity Trading",
        description: "Akses pasar saham dengan cepat dan mudah, kapan saja dan di mana saja. Platform kami menawarkan pengalaman trading yang intuitif dan responsif, lengkap dengan fitur canggih untuk mendukung setiap langkah anda.",
        href: "/winvest-mobile",
        disabled: true
    },
    // {
    //     image: `${Init.config.imageFolder}product-repo-2.png`,
    //     title: "Repurchase Agreement (REPO)",
    //     description: "Waterfront Sekuritas memberikan fasilitas kepada klien untuk dapat menjaminkan saham/obligasi yang dimiliki sebagai jaminan untuk mendapatkan pinjaman jangka pendek.",
    //     href: "/repo",
    //     disabled: false,
    // },
    {
        image: `${Init.config.imageFolder}product-margin-3.png`,
        title: "Margin Facility",
        description: "Fasilitas yang diberikan oleh Waterfront Sekuritas yang memungkinkan klien untuk membeli saham dengan dana pinjaman.",
        href: "/margin-facility",
        disabled: false,
    },
    {
        image: `${Init.config.imageFolder}product-ib-4.png`,
        title: "Investment Banking",
        description: "Capai tujuan finansial anda dengan keahlian kami! Dapatkan solusi Investment Banking yang disesuaikan untuk penggalangan dana, M&A, dan strategi pertumbuhan.",
        href: "/investment-banking",
        disabled: false,
    },
    // {
    //     image: `${Init.config.imageFolder}product-income-5.png`,
    //     title: "Fixed Income",
    //     description: "Layanan Fixed Income kami menawarkan investasi stabil melalui obligasi pemerintah dan korporasi, dirancang untuk menghasilkan pendapatan tetap dan melindungi dari volatilitas pasar ideal untuk investor yang mencari stabilitas.",
    //     href: "",
    //     disabled: false,
    // },
    // {
    //     image: `${Init.config.imageFolder}product-aperd-6.png`,
    //     title: "Agen Penjual Efek Reksa Dana (APERD)",
    //     description: "APERD dari perusahaan sekuritas kami hadir untuk memudahkan Anda berinvestasi dengan berbagai pilihan produk reksa dana yang menguntungkan dan aman, semua berizin OJK.",
    //     href: "",
    //     disabled: false,
    // },
];

export const productList = async () => {
    try {
        const response = responseFormat(
            0,
            "Fetch static data",
            products
        );

        return response;
    } catch(e) {
        return responseFormat(-1, 'Failed to get products!', []);
    }
};