import React, {Component} from 'react';
import './WinvestWebPage.scss';

import Init from '../../Init/Init';

import ReactGA from 'react-ga4';
import WinvestWebHeader from '../../Component/PagesComponent/WinvestWebHeader/WinvestWebHeader';
import WinvestWebAdvantage from '../../Component/PagesComponent/WinvestWebAdvantage/WinvestWebAdvantage';
import WinvestWebFeature from '../../Component/PagesComponent/WinvestWebFeature/WinvestWebFeature';
import WinvestWebFAQ from '../../Component/PagesComponent/WinvestWebFAQ/WinvestWebFAQ';
import Winvest from '../../Component/PagesComponent/Winvest/Winvest';

class WinvestWebPage extends Component {
    constructor(props) {
        super(props);
        
        this.config = Init.config;
        this.state = {
            // downloadBackground: window.screen.width > 950 ? `${this.config.sectionAssets.winvest.background4}` : `${this.config.sectionAssets.winvest.background4Mobile}`
            downloadBackground: `${ this.config.sectionAssets.winvest.background4 }`,
            downloadImage: `${ this.config.sectionAssets.winvest.laptop }`,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', (event) => {
            // console.log(this.screen.width);
            // const screenWidth = event.target.innerWidth;

            // if( screenWidth > 950 ) {
            //     if( this.state.downloadBackground !== this.config.sectionAssets.winvest.background4 ) {
            //         this.setState({
            //             ...this.state,
            //             downloadBackground: `${this.config.sectionAssets.winvest.background4}`
            //         });
            //     }
            // } else {
            //     if( this.state.downloadBackground !== this.config.sectionAssets.winvest.background4Mobile ) {
            //         this.setState({
            //             ...this.state,
            //             downloadBackground: `${this.config.sectionAssets.winvest.background4Mobile}`
            //         });
            //     }
            // }
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', null);
    }

    render() {
        const { winvest } = Init.config.sectionAssets;

        return (
            <div className="winvest-web-page">
                {/* 
                    1. WinvestWebHeader
                    2. WinvestWebAdvantage
                */}
                <WinvestWebHeader />
                <WinvestWebAdvantage />
                <WinvestWebFeature />
                <WinvestWebFAQ />
                <Winvest 
                    customBg={ this.state.downloadBackground }
                    customImage={ this.state.downloadImage }
                    title={"Akses Online melalui Website"}
                    desc={`
                        Bersama Winvest raih kemenangan di setiap tujuan finansialmu! 
                        <br />
                        Jangan sampai kelewatan.
                    `}
                    showWebsiteLink={true}
                />
            </div>
        );
    }
}

export default WinvestWebPage;