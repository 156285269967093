import React, { useEffect, useState } from 'react';

import Init from '../../../Init/Init';
import { buildWaLink, productsLink, numberFormatting } from '../../../Util/Util';

import './ResearchCategory.scss';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { researchCategoryList } from '../../../Data/Research';

const { config } = Init;

const ResearchCategoryList = (props) => {
    const { items } = props;
    
    const gaEventCategory = 'Layanan Hukum';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const { FontAwesomeIcon } = Init;

    return (
        <div className='research-category-item-container'>
            {
                items.map( (v, k) => {
                    return (
                        <div key={k} className="research-category-item-holder">
                            <div className="research-category-item">
                                <Link 
                                    className="research-category-item-title-clickable"
                                    to={ `/research/${ v.id }` }
                                >
                                    <h3 className="research-category-item-title">
                                            { v.category_name }
                                    </h3>
                                    <FontAwesomeIcon icon={['fas', 'arrow-right']} className="title-icon" />
                                </Link>
                                <img src={v.category_image} alt={ v.category_name } className='research-category-item-icon' />
                            </div>
                        </div>
                    );
                } )
            }
        </div>
    );
}

const ResearchCategory = (props) => {
    const [ categories, setCategories ] = useState([]);

    useEffect( () => {
        ( async () => {
            const response = await researchCategoryList(props.limit);
    
            if( response.errorCode == 0 ) {
                setCategories(response.data);
            }
        } )();
    }, [props.limit]);

    return (
        <div className="research-category">
            {
                categories.length > 0 ? (
                    <ResearchCategoryList items={categories} />
                ) : (
                    <div className="research-category-loading-section">
                        <LoadingPage />
                    </div>
                )
            }
        </div>
    );
}

ResearchCategory.defaultProps = {
    limit: null
}


export default ResearchCategory;