import React, {Component} from 'react';
import './RepoPage.scss';

import Init from '../../Init/Init';

import ReactGA from 'react-ga4';
import RepoHeader from '../../Component/PagesComponent/RepoHeader/RepoHeader';
import RepoRoles from '../../Component/PagesComponent/RepoRoles/RepoRoles';
import RepoNotes from '../../Component/PagesComponent/RepoNotes/RepoNotes';
import RepoGoals from '../../Component/PagesComponent/RepoGoals/RepoGoals';
import RepoRisk from '../../Component/PagesComponent/RepoRisk/RepoRisk';
import ContactBanner from '../../Component/PagesComponent/ContactBanner/ContactBanner';

class RepoPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    componentDidMount() {

    }
    
    componentWillUnmount() {
    
    }

    render() {
        const { winvest } = Init.config.sectionAssets;

        return (
            <div className="winvest-mobile-page">
                {/* 
                    1. RepoHeader
                    2. RepoNotes
                    3. RepoGoals
                    4. RepoRisk
                    5. RepoRoles
                    6. ContactBanner
                */}
                <RepoHeader />
                <RepoNotes />
                <RepoGoals />
                <RepoRisk />
                <RepoRoles />
                <ContactBanner />
            </div>
        );
    }
}

export default RepoPage;