import React, {Component} from 'react';
import './InvestmentBankingPage.scss';

import Init from '../../Init/Init';

import ReactGA from 'react-ga4';
import ContactBanner from '../../Component/PagesComponent/ContactBanner/ContactBanner';
import InvestmentBankingHeader from '../../Component/PagesComponent/InvestmentBankingHeader/InvestmentBankingHeader';
import InvestmentBankingAdvantageTitle from '../../Component/PagesComponent/InvestmentBankingAdvantageTitle/InvestmentBankingAdvantageTitle';
import InvestmentBankingAdvantage from '../../Component/PagesComponent/InvestmentBankingAdvantage/InvestmentBankingAdvantage';

class InvestmentBankingPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    componentDidMount() {

    }
    
    componentWillUnmount() {
    
    }

    render() {
        return (
            <div className="investment-banking-page">
                {/* 
                    1. InvestmentBankingHeader
                    2. InvestmentBankingAdvantageTitle
                    3. InvestmentBankingAdvantage
                    4. ContactBanner
                */}
                <InvestmentBankingHeader />
                <InvestmentBankingAdvantageTitle />
                <InvestmentBankingAdvantage />
                <ContactBanner />
            </div>
        );
    }
}

export default InvestmentBankingPage;