import React, {Component} from 'react';
import './ContactMap.scss';

const ContactMap = (props) => {
    return (
        <div className="contact-map">
            <iframe src={`${ props.link }`} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
}

export default ContactMap;