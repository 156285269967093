import React, {Component} from 'react';
import './AboutPage.scss';

import Init from '../../Init/Init';

import ReactGA from 'react-ga4';
import AboutUs from '../../Component/PagesComponent/AboutUs/AboutUs';
import VisionMission from '../../Component/PagesComponent/VisionMission/VisionMission';
import Teams from '../../Component/PagesComponent/Teams/Teams';
import Compliance from '../../Component/PagesComponent/Compliance/Compliance';
import ContactBanner from '../../Component/PagesComponent/ContactBanner/ContactBanner';
import AboutUsHeader from '../../Component/PagesComponent/AboutUsHeader/AboutUsHeader';
import Structure from '../../Component/PagesComponent/Structure/Structure';
import LicensedEmployee from '../../Component/PagesComponent/LicensedEmployee/LicensedEmployee';

class AboutPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    componentDidMount() {

    }
    
    componentWillUnmount() {
    
    }

    render() {
        return (
            <div className="about-page">
                {/* 
                    1. AboutUs
                    2. VisionMission
                    3. Teams
                    4. Compliance

                    -- Additional: Company Structure, Licensed Employees

                    5. Contact Banner
                */}
                <AboutUsHeader />
                <AboutUs />
                <VisionMission />
                <Teams />
                <Structure />
                <LicensedEmployee />
                <Compliance />
                <ContactBanner />
            </div>
        );
    }
}

export default AboutPage;