import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './RepoRisk.scss';

class RepoRisk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            risks: [
                {
                    title: "Stabilitas Sistem Keuangan",
                    description: "Repo membantu menjaga stabilitas sistem keuangan dengan menyediakan mekanisme untuk meminjamkan dan meminjamkan dana jangka pendek.",
                    image: `${ Init.config.sectionAssets.repo.risk1 }`
                },
                {
                    title: "Transmisi Kebijakan Moneter",
                    description: "Bank sentral menggunakan Repo untuk mengimplementasikan kebijakan moneter, seperti menaikkan atau menurunkan suku bunga.",
                    image: `${ Init.config.sectionAssets.repo.risk2 }`
                },
                {
                    title: "Efisiensi Pasar",
                    description: "Repo meningkatkan efisiensi pasar dengan memfasilitasi pergerakan dana antar lembaga keuangan.",
                    image: `${ Init.config.sectionAssets.repo.risk3 }`
                },
            ]
        }
    }

    render() {
        const { repo } = Init.config.sectionAssets;
        
        return (
            <div className="repo-risk">
                <div className="repo-risk-wrapper">
                    {/* 
                        - Berizin dan diawasi oleh:
                        - OJK, IDX, IDClear, KSEI
                    */}
                    <div className="repo-risk-text">
                        Risiko dalam Transaksi Repurchase Agreement
                    </div>
                    <div className="repo-risk-items">
                        {
                            this.state.risks.map((v, k) => {
                                return (
                                    <div key={k} className="repo-risk-item">
                                        <img src={v.image} alt={ v.title } className='repo-risk-item-icon' />
                                        <h3 className="repo-risk-item-title">
                                                { v.title }
                                        </h3>
                                        {/* <a className="repo-risk-item-title-clickable" href={ v.href }>
                                        </a> */}
                                        {
                                            v.hasOwnProperty('description') ? (
                                                <p className="repo-risk-item-desc">
                                                    { v.description }
                                                </p>
                                            ) : ''
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default RepoRisk;