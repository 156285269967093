import React, {Component} from 'react';
import './ResearchHeader.scss';
import Init from '../../../Init/Init';

const ResearchHeader = (props) => {
    const { searchIcon } = Init.config;

    return (
        <div 
            className="research-header"
            style={{
                // backgroundImage: `url(${ research.background })`,
                backgroundPosition: `center`,
                backgroundSize: `cover`,
            }}
        >
           <h1 className="research-header-title">
                Riset Ekonomi dan Keuangan Terupdate
           </h1>
           <p className="research-header-subtitle">
                Dapatkan riset ekonomi dan keuangan terbaru untuk mendukung keputusan investasi Anda yang lebih cerdas dan tepat sasaran
           </p>
           <div className="research-header-search">
                <input
                    className="form-control"
                    name="keyword" 
                    defaultValue="" 
                    placeholder="Cari riset"
                    onChange={ (event) => props.updateKeyword(event.target.value) }
                />
                <span className="search-icon">
                    <img src={ searchIcon } />
                </span>
           </div>
        </div>
    );
}

export default ResearchHeader;