import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import Init from '../../../Init/Init';
import articlesData from '../../../Data/Article';

import './ArticleList.scss';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import { connect } from 'react-redux';
import articleActions from '../../../Redux/Article/article-action';

const { loadArticle } = articleActions;

const mapStateToProps = (state) => {
    return {
        article: state.article
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadArticle: (startId, limit, refresh) => dispatch(loadArticle(startId, limit, '', refresh))
    };
}

const ArticleList = (props) => {
    /**
        Two parts:
        1. List
        2. Embed Twitter
    */
    const [ article, setArticle ] = useState([]);
    const [ isTwitterLoaded, setIsTwitterLoaded ] = useState(false);
    const twitterAccount = 'Waterfront Sekuritasid';
    const { FontAwesomeIcon } = Init;

    const navigate = useNavigate();

    const gaEventCategory = 'Article';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    useEffect(() => {
        props.loadArticle(0, props.limit, true);
    }, []);

    useEffect( () => {
        setArticle(Object.values(props.article.data));    
    }, [props.article.data]);

    useEffect( () => {
        if(isTwitterLoaded) {
            console.log('Twitter is loaded');
        }
    }, [isTwitterLoaded]);

    return (
        <div className='article-list-container'>
            {
                article.length > 0 ? (
                    <div className="article-list">
                        {
                            article.map( (v, k) => {
                                return (
                                    <div key={k} className="article-item" onClick={() => navigate(`/article/${v.article_slug}`, {
                                        replace: false
                                    })}>
                                        <div className="article-thumbnail">
                                            <img src={v.article_image} alt="" />
                                        </div>
                                        <div className="article-content">
                                            <h5 className='content-title'>
                                                { v.article_title }
                                            </h5>
                                            <span className="content-publisher">{ v.published_by }</span> - <span className='content-publish-time'>{ v.published_time }</span>
                                            <p className="article-text">
                                                {v.short_description}
                                            </p>
                                        </div>
                                    </div>
                                )
                            } )
                        }
                        <div className="article-item see-more">
                            <Link className='btn-article-see-more' to={`/article/`}>
                                Lihat lebih banyak...
                            </Link>
                        </div>
                    </div>
                ) : (
                    isTwitterLoaded ? (
                        ''    
                    ) : (
                        <div className="article-list">
                            <LoadingPage />
                        </div>

                    )
                )
            }
        </div>
    );
}

ArticleList.defaultProps = {
    article: {
        data: {},
        amount: 0
    },
    limit: 3
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);