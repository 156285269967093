import React, {Component, Fragment} from 'react';
import './TermsPage.scss';

const Paragraph = (props) => {
    return (
        props.data.map( (item, index) => {
            return (
                <p key={index} className="item-paragraph" dangerouslySetInnerHTML={{ __html: item }}>           
                </p>
            );
        } )
    );
};

const List = (props) => {
    console.log({
        title: props.data.title,
        list: props.data.list
    });

    return (
        <Fragment>
            <p className="item-lists-title">
                {props.data.title}
            </p>
            <ol className='item-lists'>
                {
                    props.data.list.map( (item, index) => {
                        return(
                            typeof item === 'object' ? (
                                item.map( (v, i) => {
                                    switch(v.type) {
                                        case 'paragraph':
                                            return (
                                                <Paragraph key={i} data={v.content} />
                                            );
                                        case 'list':
                                            return (
                                                <List key={i} data={v.content} />
                                            );
                                        case 'table':
                                            return (
                                                <Table key={i} data={v.content} />
                                            );
                                        default:
                                            return '';
                                    }
                                } )
                            ) : (
                                <li key={index}> {item} </li>
                            )
                        );
                    })
                }
            </ol>
        </Fragment>
    );
};

const Table = (props) => {
    const { useNo, columns, data } = props.data;

    return (
        <table className='item-table'>
            <thead>
                <tr>
                    { useNo && (
                        <th>
                            No
                        </th>
                    ) }
                    { columns.map( (item, index) => {
                        return (
                            <th key={index}>
                                { item.name }
                            </th>
                        )
                    } ) }
                </tr>
            </thead>
            <tbody>
                {
                    data.map( (item, index) => {
                        return item.hasOwnProperty('isHeader') && item.isHeader ? (
                            <tr>
                                <th align={"center"} colSpan={ item.config.colSpan + ( useNo ? 1 : 0 ) }>
                                    { item.config.label }
                                </th>
                            </tr>
                        ) : (
                            <tr>
                                {
                                    useNo && (
                                        <td className="index-no" align={'center'}> { index } </td>
                                    )
                                }
                                {
                                    columns.map( (value, key) => {
                                        return (
                                            <td> { item.hasOwnProperty(value.key) ? item[value.key] : '-' } </td>
                                         )
                                    } )
                                }
                            </tr>
                        )
                    } )
                }
            </tbody>
        </table>
    );
};
class TermsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: {
                title: "Syarat Dan Ketentuan",
                description: [
                    `Dengan mengunjungi atau menggunakan Platform Waterfront Sekuritas dan menggunakan, Anda menerima persyaratan dan ketentuan yang ditentukan oleh PT Waterfront Securities, suatu perseroan terbatas yang berdomisili di Jalan Gading Batavia LC11 No.26, Kelapa Gading, Jakarta Utara 14240, sebagai pengelola dan pemilik Platform Waterfront Sekuritas Anda sepakat untuk mengikat diri dengan persyaratan dan ketentuan yang ditentukan oleh Waterfront Sekuritas, sebagaimana suatu perjanjian yang sah dan mengikat (“Syarat dan Ketentuan”), sebagai berikut:`
                ]
            },
            list: [
                /*
                    Format: 
                    {
                        title: "",
                        description: [
                            {
                                type: "list",
                                content: { title, list }
                            },
                            {
                                type: "paragraph",
                                content: [text]
                            },
                            {
                                type: "table",
                                content: {
                                    columns: [
                                        {key, name}
                                    ],
                                    data: [{key: value, isHeader: true, config: {
                                        colSpan,
                                        label
                                    }}]
                                }
                            }
                        ]
                    }
                */
                // Definisi Dan Penafsiran
                {
                    title: "Definisi dan Penafsiran",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "Kecuali ditentukan lain dalam Syarat dan Ketentuan, definisi ini berlaku: ",
                                list: [
                                    "“Anda” berarti: ", 
                                    [
                                        {
                                            type: 'list',
                                            content: {
                                                title: '', 
                                                list: [
                                                    "Pengunjung, pembaca, pengguna Situs,", 
                                                    "Pengguna Jasa, dan/atau", 
                                                    "Pihak lain menggunakan dan menerima Layanan, selain Pengguna Jasa."
                                                ]
                                            }
                                        }
                                    ],
                                    `“Akun” berarti akun yang Anda peroleh setelah pendaftaran melalui Platform Waterfront Sekuritas untuk menerima layanan dari Waterfront Sekuritas melalui Platform Waterfront Sekuritas;`,
                                    `“Daftar Keterangan” berarti keterangan yang diperlukan untuk pelaksanaan layanan, termasuk tapi tidak terbatas pada Informasi Pribadi Anda, keterangan yang disediakan baik melalui Platform Waterfront Sekuritas atau di luar Platform Waterfront Sekuritas, sebagaimana dapat diminta oleh dan apabila dipandang perlu oleh Waterfront Sekuritas untuk pelaksanaan layanan dari waktu ke waktu;`,
                                    `“Penyedia Jasa”, berarti mitra Waterfront Sekuritas yang menyediakan jasa hukum, baik di dalam maupun di luar pengadilan yang telah memenuhi persyaratan berdasarkan ketentuan yang dimaksud dalam Undang – Undang Nomor 18 Tahun 2003 tentang Advokat dan/atau peraturan perundang-undangan yang berlaku di Indonesia;`,
                                    `“Pengguna Jasa”, berarti perseorangan, badan hukum dan/atau organisasi yang telah menggunakan dan/atau mengakses Platform Waterfront Sekuritas dan/atau mengajukan permohonan untuk mendapatkan Layanandari Penyedia Jasa melalui Platform Waterfront Sekuritas;`,
                                    `“Hari Kerja” adalah hari selain Sabtu, Minggu, dan hari libur nasional yang ditetapkan pemerintah;`,
                                    `“Jam Kerja” berarti jam 09.00 – 20.00 Waktu Indonesia Barat setiap Hari Kerja;`,
                                    `“Informasi Pribadi”, berarti setiap dan seluruh data pribadi yang diberikan oleh Penyedia Jasa dan Pengguna Jasa, yaitu nama, nomor identitas, data masalah hukum, lokasi, kontak, serta dokumen dan data lainnya sebagaimana diminta pada formulir pendaftaran Akun atau formulir lainnya pada saat menggunakan Platform Waterfront Sekuritas;`,
                                    `“Kebijakan Privasi” adalah ketentuan Kebijakan Privasi atas Layanan sebagaimana tercantum dalam Platform Waterfront Sekuritas;`,
                                    `“Layanan” adalah seluruh Layanan yang disediakan oleh Penyedia Jasa melalui Platform Waterfront Sekuritas yang meliputi fasilitas untuk pemberian jasa hukum oleh Penyedia Jasa melalui telepon, surat elektronik (surel), pertemuan, pesan singkat (chat), dan Layanan lainnya yang ditentukan oleh Waterfront Sekuritas dari waktu ke waktu;`,
                                    `“Materi” berarti artikel, panduan, penjelasan dan/atau keterangan lainnya sehubungan dengan Layanan yang disediakan oleh Waterfront Sekuritas pada dan/atau melalui Platform Waterfront Sekuritas;`,
                                    `“Platform Waterfront Sekuritas” adalah sistem yang dikelola dan dimiliki oleh PT Waterfront Securities yang terdapat di halaman Platform Waterfront Sekuritas web, Platform Waterfront Sekuritas web seluler, aplikasi seluler, dan aplikasi lain yang menggunakan merek dagang Waterfront Sekuritas;`,
                                    `“Biaya Layanan” adalah biaya yang akan dibayarkan oleh Pengguna Jasa kepada Penyedia Jasa melalui Platform Waterfront Sekuritas untuk mendapatkan Layanan.`

                                ]
                            }
                        },
                    ]
                },
                // Layanan
                {
                    title: "Layanan",
                    description: [
                        {
                            type: "list",
                            content: {
                                list: [
                                    "Waterfront Sekuritas adalah platform yang mempertemukan pihak yang membutuhkan jasa hukum dari Penyedia Jasa yang menjadi mitra Waterfront Sekuritas Oleh karena itu, Waterfront Sekuritas tidak memberikan jasa hukum secara langsung maupun tidak langsung kepada pihak manapun. Anda memahami dan menyetujui bahwa pemberian Layanan dan pelaksanaan Layanan oleh Waterfront Sekuritas:", 
                                    [
                                        {
                                            type: 'list',
                                            content: {
                                                title: '', 
                                                list: [
                                                    `bukan merupakan, tidak dimaksudkan dan/atau tidak dapat ditafsirkan sebagai suatu pemberian jasa hukum oleh Waterfront Sekuritas; dan/atau`,
                                                    `tidak menimbulkan hubungan advokat dan klien antara Waterfront Sekuritas dengan Anda dan/atau Pengguna Jasa, sebagaimana dimaksud dalam Undang-Undang Republik Indonesia Nomor 18 Tahun 2003 tentang Advokat, sehingga Waterfront Sekuritas tidak bertanggung jawab dan tidak dapat dimintai pertanggungjawaban dalam bentuk apapun berkenaan dengan jasa hukum yang diberikan oleh Penyedia Jasa.`

                                                ]
                                            }
                                        }
                                    ],
                                    `Waterfront Sekuritas tidak memiliki kewajiban untuk menerjemahkan dokumen dalam Bahasa Indonesia ke dalam bahasa asing dan/atau sebaliknya;`,
                                    `Waterfront Sekuritas tidak memiliki keahlian dalam memberi saran atau pendapat hukum terkait dengan masalah hukum Anda dan/atau Pengguna Jasa;`,
                                    `Waterfront Sekuritas tidak berjanji dan menjamin bahwa permasalahan hukum Anda dan/atau Pengguna Jasa dapat terselesaikan melalui Layanan yang disediakan pada Platform Waterfront Sekuritas;`,
                                    `Dalam pelaksanaan Layanan, apabila dipandang perlu oleh Waterfront Sekuritas, Waterfront Sekuritas berhak dan diberikan kewenangan oleh Pengguna Jasa untuk menunjuk atau mengalihkan seluruh atau sebagian Layanan kepada pihak lain tanpa pemberitahuan terlebih dahulu kepada Pengguna Jasa;`,
                                    `Waterfront Sekuritas hanya akan memulai Layanan berdasarkan tahap yang ditentukan setelah Waterfront Sekuritas menerima pembayaran dari Pengguna Jasa berdasarkan ketentuan pembayaran sebagaimana diatur dalam Syarat dan Ketentuan ini;`,
                                    `Waterfront Sekuritas berhak menolak permintaan atau mengakhiri Layanan apabila berdasarkan pandangan dan pendapat Waterfront Sekuritas terdapat indikasi pemalsuan, penipuan dan/atau tindakan lain yang melanggar peraturan perundang-undangan yang berlaku;`,
                                    `Waterfront Sekuritas akan mengupayakan agar Anda dan/atau Pengguna Jasa dapat mengakses dan/atau menggunakan Platform Waterfront Sekuritas tanpa gangguan dan hambatan, namun tidak menjamin bahwa tidak akan ada gangguan dan/atau hambatan yang akan terjadi. Dengan ini Anda dan/atau Pengguna Jasa mengerti bahwa sehubungan dengan peningkatan Layanan dan kualitas Waterfront Sekuritas, Platform Waterfront Sekuritas dapat diperbaiki, dihentikan sementara, maupun ditangguhkan dari waktu ke waktu tanpa pemberitahuan sebelumnya.`


                                ]
                            }
                        },
                    ]
                },
                // Pelaksanaan Layanan
                {
                    title: "Pelaksanaan Layanan",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "Dengan tidak mengesampingkan ketentuan lainnya dalam Syarat dan Ketentuan ini: ",
                                list: [
                                    `Anda bertanggungjawab atas segala pertanyaan, pernyataan, keterangan yang disampaikan atau dikirim kepada Waterfront Sekuritas dan/atau Penyedia Jasa baik melalui Platform Waterfront Sekuritas maupun di luar Platform Waterfront Sekuritas;`,
                                    `Segala keterangan yang diperlukan untuk pelaksanaan Layanan sehubungan dengan Anda sebagaimana diperlukan untuk pelaksanaan Layanan, harus dipilih, ditentukan, disediakan, diisi dan/atau dilengkapi oleh Anda dalam Daftar Keterangan;`,
                                    `Anda menyatakan dan menjamin seluruh dokumen dan keterangan yang diberikan kepada Waterfront Sekuritas dan/atau Penyedia Jasa, baik secara tertulis maupun tidak tertulis, baik melalui Platform Waterfront Sekuritas maupun di luar Platform Waterfront Sekuritas, baik secara langsung maupun tidak langsung sehubungan dengan Layanan, sebagaimana diberikan oleh Anda atau pihak lain yang ditunjuk oleh Anda:`,
                                    [
                                        {
                                            type: 'list',
                                            content: {
                                                title: '', 
                                                list: [
                                                    `telah dibaca, dipahami, disetujui dan/atau ditandatangani oleh atau pihak lainnya yang memiliki kepentingan atas keterangan yang diberikan kepada Waterfront Sekuritas tersebut;`,
                                                    `merupakan keterangan yang benar, tepat, akurat, tidak menyesatkan, sesuai keadaan yang sebenarnya termasuk tapi tidak terbatas pada kebenaran, keaslian dan keabsahan identitas dan tanda tangan oleh pihak penandatangan dalam setiap dokumen yang diberikan kepada Waterfront Sekuritas oleh Anda.`

                                                ]
                                            }
                                        }
                                    ],
                                    `Anda menyatakan dan menjamin pemberian keterangan kepada Waterfront Sekuritas tidak melanggar peraturan perundang-undangan yang berlaku, rahasia dagang dan perjanjian apapun yang terkait termasuk tapi tidak terbatas pada perjanjian kerahasiaan (apabila ada);`,
                                    `Anda memahami bahwa peraturan perundang-undangan dan kebijakan pemerintah yang berwenang dapat berubah sewaktu-waktu;`,
                                    `Anda mengakui dan memahami bahwa dengan adanya penundaan, kelalaian dan/atau tidak dapat dipenuhinya Daftar Keterangan oleh Anda dapat mengakibatkan: tertundanya pengajuan Layanan; tertunda dan/atau terhentinya kelanjutan Layanan; memperlambat waktu proses pengerjaan Layanan; dan/atau timbulnya penambahan Biaya Layanan sesuai dengan harga satuan Layanan yang berlaku;`,
                                    `Penyedia Jasa akan menghubungi Pengguna Jasa melalui fitur yang disediakan Waterfront Sekuritas setelah Pengguna Jasa melunasi pembayaran Biaya Layanan;`,
                                    `Segala percakapan melalui fitur yang disediakan Waterfront Sekuritas antara Penyedia Jasa dengan Pengguna Jasa akan secara otomatis terekam oleh sistem di Waterfront Sekuritas sebagai arsip. Waterfront Sekuritas tidak akan membuka arsip rekaman percakapan tersebut tanpa persetujuan dari Penyedia Jasa dan Pengguna Jasa, kecuali diwajibkan oleh peraturan perundang-undangan yang berlaku;`,
                                    `Setiap layanan yang disediakan oleh Waterfront Sekuritas yang bekerja sama dengan mitra Waterfront Sekuritas memiliki ketentuan dan batasan secara spesifik yang mana termasuk namun tidak terbatas pada:`,
                                    [
                                        {
                                            type: 'list',
                                            content: {
                                                title: '1) Layanan Konsultasi Hukum', 
                                                list: [
                                                    "Ruang Lingkup",
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Biaya Konsultasi Hukum adalah sebesar yang tertera pada Platform Waterfront Sekuritas dan dapat berubah sewaktu-waktu tanpa pemberitahuan sebelumnya kepada Pengguna Jasa.`
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "Batasan", 
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Durasi Konsultasi Hukum akan mulai dihitung 30 detik sejak Pengguna Jasa berhasil melakukan pembayaran Biaya Layanan.`,
                                                                    `Konsultasi Hukum akan berakhir secara otomatis setelah durasi Konsultasi Hukum habis atau diakhiri lebih awal oleh Pengguna Jasa.`,
                                                                    `Dalam hal Konsultasi Hukum diakhiri lebih awal oleh Pengguna Jasa, waktu Konsultasi Hukum yang tersisa tidak dapat dikembalikan atau digunakan di lain waktu.`
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                ]
                                            }
                                        },
                                        {
                                            type: 'list',
                                            content: {
                                                title: '2) Review Dokumen Hukum', 
                                                list: [
                                                    "Ruang Lingkup",
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Biaya Review Dokumen Hukum Bahasa Indonesia adalah sebesar Rp100.000,00/halaman.`,
                                                                    `Biaya Review Dokumen Hukum bilingual/Bahasa Inggris adalah sebesar Rp200.000,00/halaman.`,
                                                                    `Untuk setiap 10 halaman, waktu pengerjaan adalah 3 – 5 Hari Kerja.`
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "Batasan", 
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Jenis dokumen yang dapat di review adalah Perjanjian, Pernyataan, Surat resmi, dan Nota kesepahaman (MOU).`,
                                                                    `Dokumen yang ingin di review harus dikirimkan dalam bentuk PDF/DOCX.`
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                ]
                                            }
                                        },
                                        {
                                            type: 'list',
                                            content: {
                                                title: '3) Pembuatan Perjanjian', 
                                                list: [
                                                    "Ruang Lingkup",
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Biaya Pembuatan Perjanjian Bahasa Indonesia adalah Rp3.000.000,00/perjanjian.`,
                                                                    `Biaya Pembuatan Perjanjian bilingual/Bahasa Inggris adalah Rp6.000.000,00/perjanjian.`,
                                                                    `Waktu pengerjaan setiap perjanjian adalah 5 – 7 Hari Kerja, diluar revisi.`
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "Batasan", 
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Maksimum nilai yang diperjanjikan adalah Rp250.000.000,00.`,
                                                                    `Perjanjian dapat direvisi 1 kali selama tidak merubah substansi Perjanjian maksimal 2x24 jam setelah Perjanjian dikirimkan oleh Penyedia Jasa melalui Platform Waterfront Sekuritas`,
                                                                    `Perjanjian yang dibuat maksimum memiliki 2 pihak.`,
                                                                    `Tidak mencakup perjanjian investasi, share purchase agreement (SPA), share subscription agreement (SSA), shareholders agreement (SHA), dan joint venture agreement (JVA).`,
                                                                    `Tidak mencakup pembuatan opini hukum.`

                                                                ]
                                                            }
                                                        }
                                                    ],
                                                ]
                                            }
                                        },
                                        {
                                            type: 'list',
                                            content: {
                                                title: '4) Pembuatan Perjanjian Kerahasiaan', 
                                                list: [
                                                    "Ruang Lingkup",
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Biaya Pembuatan Perjanjian Kerahasiaan Bahasa Indonesia adalah Rp500.000,00/Perjanjian Kerahasiaan.`,
                                                                    `Biaya Pembuatan Perjanjian Kerahasiaan bilingual/Bahasa Inggris adalah Rp1.000.000,00/Perjanjian Kerahasiaan.`,
                                                                    `Waktu pengerjaan Perjanjian Kerahasiaan adalah 3 – 5 Hari Kerja.`
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                ]
                                            }
                                        },
                                        {
                                            type: 'list',
                                            content: {
                                                title: '5) Pembuatan Surat Somasi', 
                                                list: [
                                                    "Ruang Lingkup",
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Biaya Pembuatan Surat Somasi tanpa kop surat dari mitra law firm Waterfront Sekuritas adalah Rp500.000,00/Surat Somasi.`,
                                                                    `Biaya Pembuatan Surat Somasi dengan kop surat dari mitra law firm Waterfront Sekuritas adalah Rp1.000.000,00/Surat Somasi.`,
                                                                    `Surat Somasi dengan kop surat dari mitra law firm Waterfront Sekuritas dikirimkan langsung oleh mitra law firm ke pihak yang disomasi.`,
                                                                    `Waktu pengerjaan somasi dihitung setelah menandatangani surat kuasa khusus dan perjanjian jasa hukum, memberikan identitas diri/badan dan Pengguna Jasa telah memberikan penjelasan atau gambaran mengenai substansi Surat Somasi yang akan dibuat.`

                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "Batasan", 
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Wajib menandatangani surat kuasa khusus dan perjanjian jasa hukum.`,
                                                                    `Wajib memberikan identitas diri/badan secara lengkap dan menjamin kebenarannya.`,
                                                                    `Khusus ditujukan kepada pihak yang berada di Indonesia.`,
                                                                    `Somasi dapat direvisi 1 kali selama tidak merubah substansi Somasi maksimal 2x24 jam setelah Surat Somasi dikirimkan oleh Penyedia Jasa melalui Platform Waterfront Sekuritas`

                                                                ]
                                                            }
                                                        }
                                                    ],
                                                ]
                                            }
                                        },
                                        {
                                            type: 'list',
                                            content: {
                                                title: '6) Pembuatan Jawaban Somasi', 
                                                list: [
                                                    "Ruang Lingkup",
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Biaya Pembuatan Jawaban Somasi tanpa kop surat dari mitra law firm Waterfront Sekuritas adalah Rp500.000,00/Jawaban Somasi.`,
                                                                    `Biaya Pembuatan Jawaban Somasi dengan kop surat dari mitra law firm Waterfront Sekuritas adalah Rp1.000.000,00/Jawaban Somasi.`,
                                                                    `Jawaban Somasi dengan kop surat dari mitra law firm Waterfront Sekuritas dikirimkan langsung oleh mitra law firm ke pihak terkait.`,
                                                                    `Waktu pengerjaan Jawaban Somasi dihitung setelah menandatangani surat kuasa khusus dan perjanjian jasa hukum, memberikan identitas diri/badan dan Pengguna Jasa telah memberikan penjelasan atau gambaran mengenai substansi Jawaban Somasi yang akan dibuat.`

                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "Batasan", 
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Wajib menandatangani surat kuasa khusus dan perjanjian jasa hukum.`,
                                                                    `Wajib memberikan identitas diri/badan secara lengkap dan menjamin kebenarannya.`,
                                                                    `Khusus ditujukan kepada pihak yang berada di Indonesia.`,
                                                                    `Jawaban Somasi dapat direvisi 1 kali selama tidak merubah substansi Jawaban Somasi maksimal 2x24 jam setelah Jawaban Somasi dikirimkan oleh Penyedia Jasa melalui Platform Waterfront Sekuritas`

                                                                ]
                                                            }
                                                        }
                                                    ],
                                                ]
                                            }
                                        },
                                        {
                                            type: 'list',
                                            content: {
                                                title: '7) Layanan Uji Tuntas Hukum', 
                                                list: [
                                                    "Ruang Lingkup",
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Biaya Layanan Uji Tuntas Hukum akan ditawarkan oleh mitra law firm Waterfront Sekuritas dan disetujui oleh Pengguna Jasa berdasarkan tingkat kompleksitas dan kesulitan dari aspek Uji Tuntas Hukum yang akan dilakukan dengan biaya minimum sebesar Rp50.000.000,00/Hasil Uji Tuntas Hukum.`,
                                                                    `Waktu pengerjaan Uji Tuntas Hukum adalah 15 – 20 Hari Kerja.`
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "Batasan", 
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Pengguna Jasa wajib melakukan Konsultasi Hukum sebelumnya untuk memberikan informasi mengenai kebutuhan Uji Tuntas Hukum yang akan dilakukan dan dalam rangka bagi mitra law firm Waterfront Sekuritas untuk memberikan penawaran biaya Uji Tuntas Hukum kepada Pengguna Jasa.`,
                                                                    `Wajib menandatangani perjanjian jasa hukum setelah Pengguna Jasa membayar Biaya Layanan.`,
                                                                    `Wajib memberikan identitas diri/badan secara lengkap dan menjamin kebenarannya.`,
                                                                    `Wajib menyerahkan seluruh dokumen yang akan diperiksa ketika Uji Tuntas Hukum.`,
                                                                    `Waktu pengerjaan dihitung sejak seluruh dokumen yang akan diperiksa dalam Uji Tuntas Hukum telah diserahkan oleh Pengguna Jasa.`,
                                                                    `Hanya dapat dilakukan untuk transaksi atau entitas yang berada di Indonesia.
                                                                    Tidak mencakup pemeriksaan kondisi keuangan.`
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                ]
                                            }
                                        },
                                        {
                                            type: 'list',
                                            content: {
                                                title: '8) Layanan Opini Hukum', 
                                                list: [
                                                    "Ruang Lingkup",
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    `Biaya Layanan Opini Hukum akan ditawarkan oleh mitra law firm Waterfront Sekuritas dan disetujui oleh Pengguna Jasa berdasarkan tingkat kompleksitas dan kesulitan dari aspek Opini Hukum yang akan diberikan dengan biaya minimum sebesar Rp5.000.000,00/Hasil Opini Hukum.`,
                                                                    `Waktu pengerjaan Opini Hukum  adalah 3 - 5 Hari Kerja.`
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                    "Batasan", 
                                                    [
                                                        {
                                                            type: 'list',
                                                            content: {
                                                                title: '',
                                                                list: [
                                                                    "Pengguna Jasa wajib melakukan Konsultasi Hukum sebelumnya untuk memberikan informasi mengenai kebutuhan Opini Hukum yang akan dilakukan dan dalam rangka bagi mitra law firm Waterfront Sekuritas untuk memberikan penawaran biaya Opini Hukum kepada Pengguna Jasa.",
                                                                    "Wajib menyerahkan seluruh dokumen yang dibutuhkan untuk pembuatan Opini Hukum.",
                                                                    "Waterfront Sekuritas dan Penyedia Jasa tidak dapat diminta pertanggungjawabannya atas apapun yang dilakukan dan/atau seluruh akibatnya yang dilakukan oleh Pengguna Jasa setelah menerima dan/atau membaca opini hukum.",
                                                                    "Hanya dapat dilakukan untuk permasalahan hukum di Indonesia",
                                                                    "Opini Hukum hanya dibuat dalam bahasa Indonesia."
                                                                ]
                                                            }
                                                        }
                                                    ],
                                                ]
                                            }
                                        },
                                    ]
                                ]
                            }
                        },
                    ]
                },
                // Pendaftaran, Akun, dan Akses Pada Situs
                {
                    title: "Pendaftaran, Akun dan Akses Pada Situs",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    "Anda berusia minimal 17 (tujuh belas) tahun atau sudah menikah;",
                                    "Anda setuju untuk menjadi Pengguna Jasa sesuai dengan Syarat dan Ketentuan ini;",
                                    "Anda menyatakan bukan individu dan/atau badan hukum yang dilarang oleh hukum negara Republik Indonesia untuk menerima atau menggunakan Layanan Waterfront Sekuritas;",
                                    "Anda menyatakan bahwa semua informasi dan/atau dokumen yang Anda isi atau sampaikan kepada Waterfront Sekuritas dan/atau Penyedia Jasa adalah benar, akurat, lengkap, terkini, dan sesuai dengan yang sebenar – benarnya;",
                                    "Waterfront Sekuritas berhak namun tidak wajib untuk melakukan pengecekan terhadap informasi dan/atau dokumen yang Anda serahkan kepada Waterfront Sekuritas dan/atau Penyedia Jasa atau Platform Waterfront Sekuritas Apabila pada saat pengecekan oleh Waterfront Sekuritas ditemukan adanya ketidaksesuaian atau ketidakabsahan atau adanya indikasi penipuan atas informasi dan/atau dokumen yang Anda berikan, maka Waterfront Sekuritas berhak melakukan pembatalan, pengakhiran atau pemblokiran akses Akun Anda pada Platform Waterfront Sekuritas maupun Layanan Waterfront Sekuritas dan seluruh Biaya Layanan yang telah dibayarkan tidak dapat dikembalikan kepada Anda;",
                                    "Penggunaan Platform Waterfront Sekuritas dan Layanan tidak ditujukan untuk melanggar hukum dan peraturan yang berlaku;",
                                    "Anda bertanggung jawab sepenuhnya atas keamanan kata sandi Akun Anda dan segala aktivitas yang dilakukan oleh Akun Anda. Anda setuju untuk melapor dan memberitahukan kepada Waterfront Sekuritas jika terdapat dugaan dan/atau tindakan penggunaan Akun yang tidak sah, perusakan dan pelanggaran keamanan tanpa persetujuan Anda;",
                                    "Anda bertanggung jawab terhadap ketersediaan koneksi internet, biaya pulsa, biaya telekomunikasi dan/atau biaya lainnya berdasarkan peraturan perundang-undangan yang berlaku agar Anda dapat melakukan akses pada Platform Waterfront Sekuritas;",
                                    "Waterfront Sekuritas melakukan upaya sebaik-baiknya agar Platform Waterfront Sekuritas dapat diakses dan digunakan oleh Anda tanpa gangguan dalam bentuk apapun, namun Waterfront Sekuritas tidak memberikan jaminan Platform Waterfront Sekuritas akan senantiasa dapat digunakan dan dilakukan akses dari waktu ke waktu.",
                                    "Waterfront Sekuritas tidak memungut biaya pendaftaran Akun.",
                                    "Layanan melalui Platform Waterfront Sekuritas sewaktu-waktu dapat dihentikan atau ditunda untuk sementara waktu karena pemeriksaan, pemeliharaan, perbaikan, perubahan, penambahan sistem pada Platform Waterfront Sekuritas Apabila terdapat gangguan terhadap Platform Waterfront Sekuritas dan/atau sistem pembayaran yang disediakan pada Platform Waterfront Sekuritas yang disebabkan karena alasan apapun termasuk tapi tidak terbatas pada gangguan virus, jaringan internet, Waterfront Sekuritas akan memberikan pemberitahuan mengenai gangguan tersebut melalui Platform Waterfront Sekuritas;",
                                    "Waterfront Sekuritas tidak bertanggung jawab dan tidak dapat dimintakan pertanggungjawabannya oleh Anda dan/atau pihak manapun, terkait:",
                                    [
                                        {
                                            type: "list",
                                            content: {
                                                list: [
                                                    `Segala akibat yang timbul sehubungan dengan penggunaan Platform Waterfront Sekuritas yang meliputi namun tidak terbatas pada validasi, keakuratan dan kebenaran Materi, hasil dari Layanan yang diperoleh dari Penyedia Jasa, analisa yang diberikan oleh Penyedia Jasa, perilaku Penyedia Jasa, penyelesaian yang disarankan oleh Penyedia Jasa atau yang dilakukan oleh Pengguna yang didasarkan atas analisa Penyedia Jasa bukan merupakan tanggung jawab dan tidak dapat dimintakan pertanggungjawabannya kepada Waterfront Sekuritas; dan`,
                                                    `Segala akibat yang timbul sehubungan dengan penggunaan Platform Waterfront Sekuritas atau tidak dapat digunakannya Platform Waterfront Sekuritas, baik sebagian maupun seluruhnya, yang disebabkan karena, termasuk namun tidak terbatas pada, gangguan virus, malware atau gangguan lainnya yang berada di luar kekuasaan Waterfront Sekuritas dan dapat mempengaruhi beroperasinya Platform Waterfront Sekuritas`
                                                ]
                                            }
                                        }
                                    ]
                                ]
                            }
                        },
                    ]
                },
                // Materi Layanan
                {
                    title: "Materi Layanan",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    `Kecuali dinyatakan lain, keterangan yang disampaikan melalui Platform Waterfront Sekuritas diperuntukkan atau dimaksudkan sebagai pemberian keterangan atau informasi secara umum dan bukan merupakan pendapat hukum. Waterfront Sekuritas melakukan upaya sebaik-baiknya untuk menyediakan Materi dan Layanan yang terkini dan sesuai dengan keadaan yang sebenarnya namun Waterfront Sekuritas tidak menjamin kelengkapan, kebenaran, keberlakuan, ketepatan, kepastian dan/atau kesesuaian Materi dan Layanan pada keadaan yang sebenarnya;`,
                                    `Pemanfaatan keterangan yang berasal dari Platform Waterfront Sekuritas oleh Anda merupakan tanggung jawab Anda sepenuhnya. Anda disarankan untuk tidak mengandalkan sepenuhnya pada Materi atau keterangan dari Layanan untuk mengambil suatu keputusan atau suatu tindakan apapun tanpa memperoleh pendapat hukum dari Penyedia Jasa dan/atau pendapat dari praktisi hukum lainnya yang kompeten di bidang yang berhubungan dengan masalah hukum Anda.`

                                ]
                            }
                        },
                    ]
                },
                // Pernyataan dan Jaminan
                {
                    title: "Pernyataan dan Jaminan",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "Waterfront Sekuritas menyatakan bahwa: ",
                                list: [
                                    `Tidak menjamin bahwa permasalahan hukum Pengguna Jasa dapat diselesaikan cukup melalui fitur digital seperti chat dan/atau telepon. Mengingat tingkat kebutuhan jasa hukum bisa berbeda bagi Anda. Pengguna Jasa nantinya bisa mempertimbangkan kebutuhan untuk melanjutkan ke layanan lanjutan yang tersedia.`,
                                    `Dalam menggunakan Layanan, Anda menjamin Anda tidak akan:`,
                                    [
                                        {
                                            type: 'list',
                                            content: {
                                                title: '', 
                                                list: [
                                                    `Mengalihkan Akun Anda yang sudah didaftarkan di Platform Waterfront Sekuritas kepada pihak lain tanpa persetujuan dari Waterfront Sekuritas;`,
                                                    `Mengirimkan atau mengunggah ke Platform Waterfront Sekuritas, pesan elektronik, dokumen dan/atau konten yang memuat atau berisi virus atau perangkat lain yang dapat merusak, mengganggu, membuat tidak berfungsi dan/atau memberikan dampak buruk lainnya baik sebagian maupun keseluruhan bagian dari Platform Waterfront Sekuritas;`,
                                                    `Mengubah, meretas, memodifikasi dan/atau melakukan akses ke Platform Waterfront Sekuritas dengan melawan hukum; dan
                                                    Menggunakan Platform Waterfront Sekuritas untuk hal-hal yang dilarang berdasarkan hukum dan peraturan perundang-undangan yang berlaku.`
                                                ]
                                            }
                                        }
                                    ],
                                ]
                            }
                        },
                    ]
                },
                // Biaya Layanan dan Ketentuan Pembayaran
                {
                    title: "Biaya Layanan dan Ketentuan Pembayaran",
                    description: [
                        {
                            type: "paragraph",
                            content: [
                                "Layanan ini adalah layanan berbayar yang besar. Berikut adalah daftra Biaya Layanan dan Ketentuan Pembayaran:"
                            ]
                        },
                        {
                            type: "table",
                            content: {
                                useNo: true,
                                columns: [
                                    {key: "name", name: "Nama Layanan"},
                                    {key: "price", name: "Biaya Layanan"},
                                    {key: "unit", name: "Satuan"},
                                    {key: "paymentTerm", name: "Ketentuan Pembayaran"}
                                ],
                                data: [
                                    /*
                                        A La Carte:
                                        1. Konsultasi Hukum
                                        2. Review Dokumen Hukum
                                        3. Bilingual
                                        4. Pembuatan Perjanjian
                                        5. Bilingual
                                        6. Pembuatan Perjanjian Kerahasiaan
                                        7. Bilingual
                                        8. Pembuatan Somasi
                                        9. Pake Kop
                                        10. Pembuatan Jawab Somasi
                                        11. Pake Kop
                                        12. Template Kontrak
                                            - Sewa Gudang
                                            - Sewa Ruko
                                            - Jual Beli Barang
                                            - Pekerjaan Konstruksi
                                            - Pekerjaan Jasa Alih Daya
                                            - Event Organizer
                                            - Agen
                                            - Fotografi
                                            - Jual Beli Jasa
                                            - Utang Piutang
                                            - Surat Kuasa
                                        13. Pendaftaran Merek ke Ditjen HKI
                                        14. Legal Opinion
                                        15. Uji Tuntas Hukum

                                        Pembuatan Badan Usaha:
                                        1. PT + Izin + VO + PKP
                                        2. CV + Izin + VO + PKP
                                        3. PT Perorangan + Izin + VO + PKP
                                        4. Firma + Izin + VO + PKP
                                        5. Persekutuan Perdata + Izin + VO + PKP
                                        6. Yayasan + Izin
                                        7. Perkumpulan + Izin
                                        8. PT PMA + Izin + VO + PKP

                                        Layanan Berlangganan:
                                        1. Waterfront Sekuritas Full Service
                                        2. Waterfront Sekuritas Legal Boost
                                        3. Waterfront Sekuritas Membership

                                        Layanan Starter Kit:
                                        1. Waterfront Sekuritas Lite Starter Kit
                                        2. Waterfront Sekuritas Start Now! Starter Kit
                                        3. Waterfront Sekuritas Premium Starter Kit
                                     */
                                    { isHeader: true, config: {colSpan: 4, label: "Layanan A La Carte"} },
                                    
                                    { name: "Konsultasi Hukum", price: "Rp 15.000,00", unit: "per 15 menit", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Review Dokumen Hukum", price: "Rp 105.000,00", unit: "per halaman", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Review Dokumen Hukum Bilingual", price: "Rp 210.000,00", unit: "per halaman", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Pembuatan Perjanjian", price: "Rp 3.000.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Pembuatan Perjanjian (Bilingual)", price: "Rp 6.000.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Pembuatan Perjanjian Kerahasiaan", price: "Rp 510.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Pembuatan Perjanjian Kerahasiaan (Bilingual)", price: "Rp 1.020.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Pembuatan Surat Somasi", price: "Rp 510.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Pembuatan Surat Somasi (Dengan Kop Surat Firma Hukum)", price: "Rp 1.020.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Pembuatan Jawaban Somasi", price: "Rp 510.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Pembuatan Jawaban Somasi (Dengan Kop Surat Firma Hukum)", price: "Rp 1.020.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },

                                    { name: "Template Kontrak - Perjanjian Sewa Menyewa Gudang", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Sewa Bangunan Tempat Usaha Ruko", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Sewa Bangunan Tempat Usaha Rumah", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Jual Beli Barang", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Pekerjaan Konstruksi", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Agen Properti", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Pekerjaan Jasa Fotografer", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Tenaga Kerja", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Surat Kuasa", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Jasa Event Organizer", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Template Kontrak - Perjanjian Jasa Boga", price: "Rp 90.000,00", unit: "per dokumen", paymentTerm: "Pembayaran via Aplikasi" },

                                    { name: "Pendaftaran Merek ke Ditjen HKI", price: "Rp 3.705.000,00", unit: "per kelas merek", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Legal Opinion", price: "Rp 5.000.000,00", unit: "minimum per hasil", paymentTerm: "Pembayaran via Bank Transfer" },
                                    { name: "Uji Tuntas Hukum", price: "Rp 50.000.000,00", unit: "minimum per hasil", paymentTerm: "Pembayaran via Bank Transfer" },

                                    { isHeader: true, config: {colSpan: 4, label: "Pembuatan Badan Usaha"} },
                                    { name: "PT", price: "Rp 3.210.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT + Izin", price: "Rp 5.700.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT + Izin + VO", price: "Rp 7.920.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT + Izin + VO + PKP", price: "Rp 9.930.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    
                                    { name: "CV", price: "Rp 2.205.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "CV + Izin", price: "Rp 4.710.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "CV + Izin + VO", price: "Rp 6.930.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "CV + Izin + VO + PKP", price: "Rp 8.925.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                   
                                    { name: "PT Perorangan", price: "Rp 2.700.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT Perorangan + Izin", price: "Rp 4.200.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT Perorangan + Izin + VO", price: "Rp 6.420.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT Perorangan + Izin + VO + PKP", price: "Rp 8.430.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    
                                    { name: "Firma", price: "Rp 2.700.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Firma + Izin", price: "Rp 4.905.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Firma + Izin + VO", price: "Rp 7.125.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Firma + Izin + VO + PKP", price: "Rp 9.120.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    
                                    { name: "Persekutuan Perdata", price: "Rp 2.700.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Persekutuan Perdata + Izin", price: "Rp 4.905.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Persekutuan Perdata + Izin + VO", price: "Rp 7.125.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Persekutuan Perdata + Izin + VO + PKP", price: "Rp 9.120.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },

                                    { name: "Yayasan", price: "Rp 3.210.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Yayasan + Izin", price: "Rp 6.210.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    
                                    { name: "Perkumpulan", price: "Rp 3.210.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Perkumpulan + Izin", price: "Rp 6.210.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    
                                    { name: "PT PMA", price: "Rp 4.200.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT PMA + Izin", price: "Rp 10.200.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT PMA + Izin + VO", price: "Rp 12.420.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "PT PMA + Izin + VO + PKP", price: "Rp 14.430.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    
                                    { name: "Stempel", price: "Rp 60.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    { name: "Kartu Nama", price: "Rp 60.000,00", unit: "per entitas", paymentTerm: "Pembayaran via Aplikasi" },
                                    
                                    { isHeader: true, config: {colSpan: 4, label: "Layanan Berlangganan"} },
                                    { name: "Waterfront Sekuritas Full Service", price: "Rp 10.000.000,00", unit: "per bulan selama 12 bulan", paymentTerm: "Pembayaran via Bank Transfer" },
                                    { name: "Waterfront Sekuritas Legal Boost", price: "Rp 15.200.000,00", unit: "", paymentTerm: "Pembayaran via Bank Transfer" },
                                    { name: "Waterfront Sekuritas Membership", price: "Rp 10.000.000,00", unit: "per tahun", paymentTerm: "Pembayaran via Bank Transfer" },

                                    { isHeader: true, config: {colSpan: 4, label: "Layanan Starter Kit"} },
                                    { name: "Waterfront Sekuritas Lite Starter Kit", price: "Rp 6.000.000,00", unit: "", paymentTerm: "Pembayaran via Bank Transfer" },
                                    { name: "Waterfront Sekuritas Start Now! Starter Kit", price: "Rp 15.200.000,00", unit: "", paymentTerm: "Pembayaran via Bank Transfer" },
                                    { name: "Waterfront Sekuritas Premium Starter Kit", price: "Rp 19.800.000,00", unit: "", paymentTerm: "Pembayaran via Bank Transfer" },
                                ]
                            }
                        }
                    ]
                },
                // Pengakhiran Layanan
                {
                    title: "Pengakhiran Layanan",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "Dengan tidak mengesampingkan hak-hak Waterfront Sekuritas berdasarkan Syarat dan Ketentuan serta peraturan perundang-undangan yang berlaku, Layanan diakhiri apabila (mana yang terjadi lebih dahulu): ",
                                list: [
                                    "Pengguna Jasa tidak memenuhi pembayaran sesuai dengan Biaya Layanan dan Ketentuan Pembayaran yang telah ditetapkan oleh Waterfront Sekuritas;",
                                    "Pengguna Jasa mengajukan permohonan secara tertulis kepada Waterfront Sekuritas;",
                                    "Pengguna Jasa dinilai oleh Waterfront Sekuritas telah melakukan pelanggaran Syarat dan Ketentuan dan/atau peraturan perundangan yang berlaku; dan/atau",
                                    "Adanya indikasi penipuan, pemalsuan dan/atau kejahatan sehubungan dengan Layanan yang dilakukan oleh Pengguna Jasa."
                                ]
                            }
                        },
                        {
                            type: 'paragraph',
                            content: [
                                'Dengan berakhirnya Layanan, Pengguna Jasa mengakui dan menyatakan Waterfront Sekuritas tidak lagi memiliki kewajiban untuk melanjutkan Layanan dan seluruh Biaya Layanan yang sudah dibayarkan tidak dapat dikembalikan kepada Pengguna Jasa.'
                            ]
                        }
                    ]
                },
                // Hak Kekayaan Intelektual
                {
                    title: "Hak Kekayaan Intelektual",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    "Waterfront Sekuritas memiliki seluruh hak kekayaan intelektual yang terdapat dalam Materi dan Platform Waterfront Sekuritas;",
                                    "Anda tidak diperkenankan untuk menyalin, mendistribusikan, menerbitkan, menyebarkan dan/atau menjual bagian atau seluruh dari Materi atau Platform Waterfront Sekuritas tanpa persetujuan tertulis terlebih dahulu dari Waterfront Sekuritas;",
                                    "Pengguna hanya diperbolehkan untuk menggunakan Platform Waterfront Sekuritas semata-mata untuk kebutuhan pribadi dan tidak dapat dialihkan;",
                                    "Waterfront Sekuritas dapat mengambil tindakan hukum terhadap setiap pelanggaran yang dilakukan oleh Anda terkait dengan hak kekayaan intelektual terkait Materi maupun Platform Waterfront Sekuritas"
                                ]

                            }
                        },
                    ]
                },
                // Pemberitahuan dan Komunikasi
                {
                    title: "Pemberitahuan dan Komunikasi",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    "Segala korespondensi kepada Waterfront Sekuritas sehubungan dengan Syarat dan Ketentuan agar ditujukan secara tertulis pada alamat berikut:",
                                    [
                                        {
                                            type: 'paragraph',
                                            content: [
                                                `PT Waterfront Securities, Jalan Gading Batavia LC11 No.26, Kelapa Gading, Jakarta Utara 14240
                                                <br />
                                                Alamat surel	: <a href="mailto:info@landack.id">info@landack.id</a>`
                                            ]
                                        }
                                    ],
                                    `Komunikasi sehubungan dengan Layanan dapat dilakukan secara langsung melalui pertemuan, melalui telepon dan secara elektronik termasuk tapi tidak terbatas lewat surel dan layanan aplikasi melalui internet seperti percakapan daring;`,
                                    `Alamat surel dan nomor telepon yang didaftarkan oleh Pengguna Jasa pada Waterfront Sekuritas melalui Platform Waterfront Sekuritas akan digunakan oleh Waterfront Sekuritas untuk mengirim kata sandi Akun, status Layanan, keterangan dan pemberitahuan lainnya sehubungan dengan Layanan Waterfront Sekuritas`, `Pengguna Jasa wajib memberitahukan adanya perubahan alamat surel dan nomor telepon yang terdaftar pada Waterfront Sekuritas Waterfront Sekuritas tidak bertanggung jawab atas kebenaran dan kemutakhiran alamat surel dan nomor telepon yang didaftarkan pada Waterfront Sekuritas oleh Pengguna Jasa;`,
                                    `Dengan adanya komunikasi secara elektronik dalam bentuk apapun antara Anda dengan Waterfront Sekuritas, Anda menyetujui komunikasi secara elektronik sebagai bentuk komunikasi yang sah dan patut;`,
                                    `Waterfront Sekuritas berhak, tapi tidak memiliki kewajiban, untuk menjawab, membalas pesan, mengirim surel, mengirim dokumen, menyanggupi permintaan untuk bertemu dan/atau menerima komunikasi dalam bentuk apapun kepada atau dari Anda mengenai Layanan dan/atau hal lain sehubungan dengan Platform Waterfront Sekuritas`
                                ]
                            }
                        }
                    ]
                },
                // Hukum dan Penyelesaian Sengketa
                {
                    title: "Hukum dan Penyelesaian Sengketa",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    `Syarat dan Ketentuan dan/atau Daftar Keterangan ditafsirkan dan dilaksanakan berdasarkan ketentuan hukum dan peraturan perundang-undangan yang berlaku di Negara Republik Indonesia.`,
                                    `Apabila timbul perselisihan mengenai penafsiran dan/atau pelaksanaan Syarat dan Ketentuan dan/atau Daftar Keterangan, perselisihan tersebut akan diselesaikan secara musyawarah untuk mufakat.`,
                                    `Apabila perselisihan tersebut diatas tidak dapat diselesaikan secara musyawarah untuk mufakat, maka perselisihan tersebut akan diselesaikan melalui Pengadilan Negeri Jakarta Pusat.`

                                ]
                            }
                        }
                    ]
                },
                // Pembebasan
                {
                    title: "Pembebasan",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    "Anda menyatakan bahwa Waterfront Sekuritas, direksi, komisaris, karyawan, mitra, afiliasi, dan/atau perwakilannya tidak bertanggungjawab atas tuntutan, klaim, kerugian (baik secara langsung maupun tidak langsung termasuk tapi tidak terbatas pada kehilangan laba, penghasilan, produksi, nama baik, data atau kesempatan), kompensasi, biaya, pengeluaran, beban dan/atau kewajiban dalam bentuk apapun (termasuk biaya jasa hukum advokat) dan kepada pihak manapun, termasuk tapi tidak terbatas pada yang diakibatkan karena atau sehubungan dengan:",
                                    [
                                        {
                                            type: 'list',
                                            content: {
                                                title: '',
                                                list: [
                                                    "Materi;",
                                                    "Layanan;",
                                                    "Kelalaian Anda dalam memenuhi Syarat dan Ketentuan;",
                                                    "Pernyataan atau jaminan oleh Anda sebagaimana tertuang dalam Syarat dan Ketentuan dinilai atau ditemukan tidak benar;",
                                                    "Kunjungan pada Platform Waterfront Sekuritas atau penggunaan Platform Waterfront Sekuritas oleh Anda;",
                                                    "Penggunaan Akun oleh pihak lain yang menggunakan kata sandi dan kata pengguna Anda;",
                                                    "Kegagalan operasional Platform Waterfront Sekuritas atau tidak dapat digunakannya Platform Waterfront Sekuritas baik secara sebagian maupun keseluruhan; dan/atau",
                                                    "Tindakan Anda yang tidak sesuai dengan peraturan perundang-undangan yang berlaku."
                                                ]
                                            }
                                        }
                                    ],
                                    `Dalam hal Pengguna Jasa memberikan kuasa pada Waterfront Sekuritas, mitra Waterfront Sekuritas dan/atau pihak lain yang ditunjuk oleh Waterfront Sekuritas sebagai penerima kuasa untuk melakukan tindakan untuk dan atas nama Pengguna Jasa sehubungan dengan Layanan, dengan ini Pengguna Jasa menyatakan bahwa Pengguna Jasa membebaskan penerima kuasa termasuk penerima kuasa subtitusinya tersebut dari segala tuntutan, klaim, kerugian (baik secara langsung maupun tidak langsung termasuk tapi tidak terbatas pada kehilangan laba, penghasilan, produksi, nama baik, data atau kesempatan), kompensasi, biaya, pengeluaran, beban dan/atau kewajiban dalam bentuk apapun (termasuk biaya jasa hukum advokat) atas segala hal yang diakibatkan oleh pemberian kuasa tersebut;`,
                                    `Pengguna Jasa menyatakan bahwa Waterfront Sekuritas, direksi, komisaris, karyawan, mitra, afiliasi, perwakilannya, dan/atau pihak yang ditunjuk oleh Pengguna Jasa sebagai penerima kuasa sehubungan dengan Layanan tidak akan menjadi suatu pihak dalam satu atau lebih sengketa yang melibatkan Pengguna Jasa;`,
                                    `Ketentuan ini akan tetap berlaku meskipun terjadi pengakhiran Layanan dan/atau penutupan Akun;`,
                                    `Apabila Waterfront Sekuritas mengalami kerugian, baik kerugian materiil maupun imateriil yang diakibatkan oleh Pengguna Jasa, maka Pengguna Jasa akan bertanggung jawab untuk mengganti seluruh biaya atau kerugian tersebut.`

                                ]
                            }
                        }
                    ]
                },
                // Sanksi dan Penalti
                {
                    title: "Sanksi dan Penalti",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    `Apabila Pengguna Jasa dalam 3 (tiga) kali berturut-turut gagal atau dengan sengaja maupun tidak sengaja melakukan pembayaran atas Layanan yang telah dipilih oleh Pengguna Jasa dalam kurun waktu 24 jam, maka Akun Pengguna Jasa akan diberikan sanksi tidak bisa menggunakan Layanan Waterfront Sekuritas selama 24 jam;`,
                                    `Apabila Pengguna Jasa kembali melakukan pelanggaran yang sama sebagaimana yang dimaksud pada point 1 Ketentuan Sanksi dan Penalti dalam kurun waktu 6 (enam) bulan, maka Akun Pengguna Jasa akan dikenakan sanksi pemblokiran Akun dan jika ingin diaktifkan kembali Akunnya Pengguna Jasa wajib melakukan permohonan kepada customer service untuk mengaktifkan kembali Akun Pengguna Jasa.`,
                                    `Segala persetujuan mengenai pengaktifan kembali Akun Pengguna Jasa merupakah hak prerogatif Waterfront Sekuritas sepenuhnya dan tidak dapat diganggu gugat oleh siapapun.`

                                ]
                            }
                        }
                    ]
                },
                // Risiko
                {
                    title: "Risiko",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    `Dalam menyediakan Layanan Waterfront Sekuritas, Waterfront Sekuritas bekerja sama dengan Afiliasi dan/atau pihak ketiga yang dipercayakan untuk memberikan Layanan kepada Anda. Segala bentuk risiko yang timbul setelah penggunaan Layanan maka akan ditanggung oleh Anda.`,
                                    `Selain pengalihan risiko penggunaan Layanan, Anda dapat mengajukan keluhan terkait Layanan. Selanjutnya, Waterfront Sekuritas akan melakukan investigasi dan pemeriksaan tanggung jawab atas risiko Layanan. Semua ketentuan pemeriksaan sampai penyelesaiannya mengacu pada aturan yang berlaku secara internal di Waterfront Sekuritas dan hasilnya tidak dapat diganggu gugat.`
                                ]
                            }
                        }
                    ]
                },
                // Ketentuan-ketentuan Lain
                {
                    title: "Ketentuan-ketentuan Lain",
                    description: [
                        {
                            type: "list",
                            content: {
                                title: "",
                                list: [
                                    `Dengan Anda menggunakan dan melanjutkan penggunaan Platform Waterfront Sekuritas dan Layanan, maka Anda telah menyetujui Syarat dan Ketentuan serta Kebijakan Privasi;`,
                                    `Ketentuan mengenai Layanan yang belum dicakup di dalam Syarat dan Ketentuan ini akan diatur dalam bentuk kesepakatan di antara Penyedia Jasa dan Pengguna Jasa dengan sepengetahuan Waterfront Sekuritas;`,
                                    `Waterfront Sekuritas berhak untuk mengubah sewaktu-waktu Syarat dan Ketentuan, Materi dan/atau Layanan, dengan pemberitahuan melalui pengumuman di Platform Waterfront Sekuritas atau sarana lainnya yang dipandang wajar oleh Waterfront Sekuritas Anda disarankan untuk senantiasa memeriksa Platform Waterfront Sekuritas secara berkala untuk memastikan adanya perubahan tersebut. Anda diberi hak sepenuhnya untuk keluar dari Platform Waterfront Sekuritas dan/atau mengakhiri penggunaan Layanan Waterfront Sekuritas apabila Anda tidak setuju dengan Syarat dan Ketentuan, Kebijakan Privasi serta perubahan-perubahannya tersebut. Dengan dilanjutkannya penggunaan Platform Waterfront Sekuritas atau penggunaan Layanan oleh Anda setelah perubahan tersebut merupakan bentuk persetujuan dari Anda atas perubahan tersebut;`,
                                    `Apabila terdapat 1 (satu) atau lebih ketentuan dalam Syarat dan Ketentuan dan/atau Daftar Keterangan ini dinyatakan tidak sah, tidak sesuai dengan peraturan yang berlaku, tidak dapat dilaksanakan dan/atau dinyatakan tidak berlaku oleh Pejabat Yang Berwenang, maka ketentuan lainnya dalam Syarat dan Ketentuan dan/atau Daftar Keterangan tetap berlaku;`,
                                    `Kegagalan, penundaan atau pengesampingan oleh Waterfront Sekuritas untuk pelaksanaan atau penegakan setiap ketentuan Syarat dan Ketentuan dan/atau Daftar Keterangan, tidak dapat diartikan sebagai pengesampingan hak-hak Waterfront Sekuritas atas ketentuan tersebut atau ketentuan lainnya dalam Syarat dan Ketentuan dan/atau Daftar Keterangan.`

                                ]
                            }
                        }
                    ]
                },

            ]
        }
    }

    componentDidMount() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <div className="terms-and-condition">
                <div className="terms-and-condition-intro">
                    <h1> { this.state.header.title } </h1>
                    {
                        this.state.header.description.map( (item, index) => {
                            return (
                                <p key={index} dangerouslySetInnerHTML={{ __html: item }}>
                                </p>
                            );
                        })
                    }
                </div>
                <div className="terms-and-condition-content">
                    <ol>
                        {
                            this.state.list.map( (item, index) => {
                                return(
                                    <div key={index} className="terms-and-condition-content-item">
                                        <li> {item.title} </li>

                                        {
                                            item.description.map( (v, i) => {
                                                switch(v.type) {
                                                    case 'paragraph':
                                                        return (
                                                            <Paragraph key={i} data={v.content} />
                                                        );
                                                    case 'list':
                                                        return (
                                                            <List key={i} data={v.content} />
                                                        );
                                                    case 'table':
                                                        return (
                                                            <Table key={i} data={v.content} />
                                                        );
                                                    default:
                                                        return '';
                                                }
                                            } )
                                        }
                                    </div>
                                );
                            } )
                        }
                    </ol>
                </div>
            </div>
        );
    }
}

export default TermsPage;
