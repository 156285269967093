import React, {Component, createRef} from 'react';
import './FAQPage.scss';

import Init from '../../Init/Init';

import FAQ from '../../Component/PagesComponent/FAQ/FAQ';
import FAQHeader from '../../Component/PagesComponent/FAQHeader/FAQHeader';
import FAQCategories from '../../Component/PagesComponent/FAQCategories/FAQCategories';

class FAQPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            chosenCategory: 0,
            keyword: ""
        }
    }

    componentDidMount() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
     }

    setCategory(categoryId) {
        this.setState({
            ...this.state,
            chosenCategory: categoryId
        });
    }

    setKeyword(search) {
        this.setState({
            ...this.state,
            keyword: search
        });
    }

    render() {
        return (
            <div className="faq-page">
                {/* 
                    1. FAQHeader
                    2. FAQCategories
                    3. FAQ
                */}
                <FAQHeader updateKeyword={ (search) => this.setKeyword(search) } />
                <div className="faq-flex-row">
                    {/* {
                        this.state.keyword === "" ? (
                            <FAQCategories 
                                chosenCategory={ this.state.chosenCategory } 
                                setCategory={ (categoryId) => this.setCategory(categoryId) }
                            />
                        ) : ""
                    } */}
                    <FAQ 
                        category={ this.state.chosenCategory }
                        keyword={ this.state.keyword }
                    />
                </div>
            </div>
        );
    }
}

export default FAQPage;