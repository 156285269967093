import React, {Component, useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Accordion, Row, Col } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './WinvestMobileFAQ.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const CollapseBox = (props) => {
    const { boxNumber } = props;

    const gaEventCategory = 'FAQ';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const toggleQuestion = () => {
        if(!props.list.open) {
            gaEventTracker('Toggle Open Question', props.list.question);
        }

        props.onToggle();
    };

    return(
        <Accordion>
            <Accordion.Item eventKey={boxNumber} className="question-item">
                <Accordion.Header className="question-box" onClick={toggleQuestion}>
                    <span className="question-title">
                        {props.list.question}
                    </span>
                    {/* <Init.FontAwesomeIcon icon={['fas', (props.list.open ? 'angle-up' : 'angle-down')]} /> */}
                </Accordion.Header>
                <Accordion.Body className='question-content' dangerouslySetInnerHTML={{__html: props.list.answer}}>
                        {/* {props.list.answer}  */}
                    {/* <Card.Body> 
                    </Card.Body> */}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const WinvestMobileFAQ = (props) => {
    const { FontAwesomeIcon } = Init;
    const { winvest } = Init.config.sectionAssets;

    const [ list, setList ] = useState([
        {
            question: "Apakah Winvest aman untuk digunakan?", 
            answer: "Ya, Winvest aman untuk digunakan. Aplikasi ini dilengkapi dengan teknologi enkripsi berstandar internasional dan otentikasi dua faktor (2FA) untuk melindungi data dan dana pengguna dari akses tidak sah. Selain itu, Winvest juga diawasi oleh otoritas keuangan yang relevan, memastikan kepatuhan terhadap standar keamanan dan regulasi yang berlaku.",
            open: false
        },
        {
            question: "Produk investasi apa saja yang tersedia di Winvest?", 
            answer: "Winvest menawarkan berbagai produk investasi, termasuk saham, reksa dana, dan obligasi. Produk ini dirancang untuk memenuhi kebutuhan investor dengan profil risiko yang berbeda, baik pemula maupun yang sudah berpengalaman.investor dapat melakukan transaksi saham secara cepat, aman, dan mudah, dengan akses penuh ke pasar saham domestik.",
            open: false
        },
        {
            question: "Apakah ada biaya atau komisi untuk setiap transaksi di Winvest?", 
            answer: `Ya, Winvest mengenakan biaya atau komisi untuk setiap transaksi. Biaya ini bervariasi tergantung pada jenis produk investasi yang dipilih. Informasi detail mengenai struktur biaya dapat dilihat di bagian "Biaya dan Komisi" pada aplikasi Winvest.`,
            open: false
        },
        {
            question: "Apakah Winvest menyediakan fitur edukasi atau panduan untuk investor pemula?", 
            answer: "Winvest menyediakan berbagai fitur edukasi, termasuk tutorial, artikel, dan video panduan untuk membantu investor pemula memahami cara trading dan manajemen risiko. Ada juga webinar dan seminar rutin yang dapat diikuti pengguna untuk memperdalam pengetahuan investasi.",
            open: false
        },
    ]);
    const handleToggle = (index) => {
        let listCopy = list;

        listCopy[index].open = !listCopy[index].open;

        setList([
            ...listCopy
        ]);
    };

    return (
        <div id="winvest-mobile-faq" className="winvest-mobile-faq-box">
            <div className="winvest-mobile-faq-header">
                <h2 className='winvest-mobile-faq-title'> Sering Ditanyakan </h2>
            </div>
            <div className="winvest-mobile-faq-container">
                <div className="question-group">
                    {
                        list.map( (item, index) => {
                            return(
                                <CollapseBox key={index} boxNumber={index} list={item} onToggle={ () => handleToggle(index) } />
                            );
                        } )
                    }
                    <div className="winvest-mobile-see-all-btn-container">
                        <a href="/faq" className="winvest-mobile-see-all-btn">
                            Lihat Semua <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </a>
                    </div>
                </div>
                <div className="question-image">
                    <img src={ winvest.mobileFaqIcon } alt="" />
                </div>
            </div>
        </div>
    );
}

export default WinvestMobileFAQ;