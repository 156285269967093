import React, {Component} from 'react';
import './FAQHeader.scss';
import Init from '../../../Init/Init';

const FAQHeader = (props) => {
    const { sectionAssets, searchIcon } = Init.config;
    const { faq } = sectionAssets;

    return (
        <div 
            className="faq-header"
            style={{
                backgroundImage: `url(${ faq.background })`,
                backgroundPosition: `center`,
                backgroundSize: `cover`,
            }}
        >
           <h1 className="faq-header-title">
                Hai! Anda sedang butuh bantuan?
           </h1>
           <div className="faq-header-search">
                <input
                    className="form-control"
                    name="keyword" 
                    defaultValue="" 
                    placeholder="Cari"
                    onChange={ (event) => props.updateKeyword(event.target.value) }
                />
                <span className="search-icon">
                    <img src={ searchIcon } />
                </span>
           </div>
        </div>
    );
}

export default FAQHeader;