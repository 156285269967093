import React, { useEffect, useState } from 'react';

import Init from '../../../Init/Init';
import { buildWaLink, productsLink, numberFormatting } from '../../../Util/Util';

import './Products.scss';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const { config } = Init;

const mapStateToProps = (state) => {
    return {
        products: state.products.data
    };
}

const ProductsList = (props) => {
    const { prices } = props;
    
    const gaEventCategory = 'Layanan Hukum';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const { FontAwesomeIcon } = Init;
    
    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "products-item-container";
    carouselConfig.itemClass = "products-item-holder";
    carouselConfig.showDots = true;
    carouselConfig.infinite = false;
    carouselConfig.centerMode = false;
    carouselConfig.dotListClass = 'products-item-dot-list';
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            // items: 4,
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 920 },
            items: 3,
            partialVisibilityGutter: 40,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 920, min: 800 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

    carouselConfig.removeArrowOnDeviceType = [
        'desktop',
        'tablet',
        'mobileTablet',
        'mobile'
    ];

    return (
        <Carousel
                // arrows={false}
                // renderButtonGroupOutside={true}
                // customButtonGroup={<ButtonGroup />}
                swipeable={carouselConfig.swipeable}
                draggable={carouselConfig.draggable}
                showDots={carouselConfig.showDots}
                responsive={carouselConfig.responsive}
                ssr={carouselConfig.ssr} // means to render carousel on server-side.
                infinite={carouselConfig.infinite}
                autoPlay={carouselConfig.autoPlay}
                autoPlaySpeed={carouselConfig.autoPlaySpeed}
                keyBoardControl={carouselConfig.keyBoardControl}
                transitionDuration={carouselConfig.transitionDuration}
                removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                deviceType={carouselConfig.deviceType}
                containerClass={carouselConfig.containerClass}
                dotListClass={carouselConfig.dotListClass}
                itemClass={carouselConfig.itemClass}
                centerMode={carouselConfig.centerMode}
            >
                {
                    prices.map( (v, k) => {
                        return (
                            <div key={k} className="products-item">
                                <div className="products-item-icon">
                                    <img src={v.image} alt={ v.title } />
                                </div>
                                <Link 
                                    className="products-item-title-clickable"
                                    to={ v.href }
                                    style={ v.disabled ? { pointerEvents: 'none' } : null }
                                >
                                    <h3 className="products-item-title">
                                            { v.title }
                                    </h3>
                                    {
                                        v.disabled ? (
                                            <h3 className="products-item-title">
                                                [Coming Soon]
                                            </h3>
                                        ) : (
                                            <FontAwesomeIcon icon={['fas', 'arrow-right']} className="title-icon" />
                                        )
                                    }
                                </Link>
                                {/* <a className="products-item-title-clickable" href={ v.href }>
                                </a> */}
                                {
                                    v.hasOwnProperty('description') ? (
                                        <p className="products-item-desc">
                                            { v.description }
                                        </p>
                                    ) : ''
                                }
                                {/* <a 
                                    className="btn-products" 
                                    rel="noreferrer"
                                    target="_blank" 
                                    href={ v.buttonLink } 
                                    onClick={ () => gaEventTracker('Click Order Now Button', v.actionLabel)}
                                    onMouseEnter={ () => gaEventTracker('Hover Order Now Button', v.actionLabel)}
                                >
                                    Pesan Sekarang
                                </a> */}
                            </div>
                        );
                    } )
                }
        </Carousel>
    );
}

const Products = (props) => {
    const [ products, setProducts ] = useState([]);
    const productsNotes = [
        // Fill extra notes here
    ];

    useEffect( () => {
        const response = props.products;

        if( response.length > 0 ) {
            setProducts(response);
        }
        
    }, [props.products]);

    return (
        <div id="individual-pricing" className="products">
            <h2 className='products-title'>
                Pilihan Produk Lengkap untuk 
                <br />
                Semua Tujuan Finansial Anda
            </h2>
            {
                products.length > 0 ? (
                    <ProductsList prices={products} />
                    // <ProductsChart prices={products} />
                ) : (
                    <div className="products-loading-section">
                        <LoadingPage />
                    </div>
                )
            }
            <div className="products-notes">
                {
                    productsNotes.map((v, k) => {
                        return (
                            <span key={k} className="products-notes-item">
                                { v }
                            </span>
                        )
                    })
                }
            </div>
        </div>
    );
}



export default connect(mapStateToProps)(Products);