import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './VisionMission.scss';

class VisionMission extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { visionIcon, missionIcon } = Init.config.sectionAssets.aboutUs;
        
        return (
            <div className="vision-mission">
                {/* 
                    1. Vision: Title, Text
                    2. Mission: Title, Text
                */}
                <div className="vimi-box vision">
                    <img src={visionIcon} alt="" className="vimi-icon" />
                    <h3 className="vimi-title">
                        Visi
                    </h3>
                    <p className="vimi-desc">
                        Waterfront Sekuritas memiliki visi untuk menjadi tujuan utama nasabah dalam memberikan solusi finansial dan investasi paling <b>lengkap, cepat, dan terpercaya.</b>
                    </p>
                </div>
                <div className="vimi-box mission">
                    <img src={missionIcon} alt="" className="vimi-icon" />
                    <h3 className="vimi-title">
                        Misi
                    </h3>
                    <p className="vimi-desc">
                        Memberikan layanan terlengkap, menyediakan teknologi investasi termutakhir dan terpercaya, serta mengedepankan edukasi bagi seluruh mitra investasi Waterfront Sekuritas.
                    </p>
                </div>
            </div>
        );
    }
}

export default VisionMission;