import React, {Component} from 'react';
import './ContactPage.scss';

import Init from '../../Init/Init';

import ReactGA from 'react-ga4';
import ContactInfo from '../../Data/Contact';
import ContactMap from '../../Component/PagesComponent/ContactMap/ContactMap';
import ContactHeader from '../../Component/PagesComponent/ContactHeader/ContactHeader';
import ContactAddress from '../../Component/PagesComponent/ContactAddress/ContactAddress';
import ContactForm from '../../Component/PagesComponent/ContactForm/ContactForm';

class ContactPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    componentDidMount() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    componentWillUnmount() {
    
    }

    render() {
        const contacts = ContactInfo();

        return (
            <div className="contact-page">
                {/* 
                    1. ContactHeader
                    2. ContactForm
                    3. ContactAddress
                    4. ContactMap
                */}
                <ContactHeader />
                <div className="contact-page-container">
                    <ContactForm 
                        address={ contacts.address } 
                        email={ contacts.email } 
                        phone={ contacts.phone } 
                        fax={ contacts.fax } 
                        website={ contacts.website }
                    />
                </div>
                <ContactAddress branchOffice={ contacts.branchOffice } />
                <ContactMap link={contacts.maps.link} />
            </div>
        );
    }
}

export default ContactPage;