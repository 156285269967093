import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './WinvestDesktopAdvantage.scss';

class WinvestDesktopAdvantage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feature: [
                {
                    image: Init.config.sectionAssets.winvest.desktopFeature1,
                    title: "Kinerja Lebih Cepat dan Stabil",
                    desc: "Aplikasi desktop memanfaatkan penuh daya perangkat Anda untuk performa lebih cepat dan stabil dibandingkan versi web atau mobile."
                },
                {
                    image: Init.config.sectionAssets.winvest.desktopFeature2,
                    title: "Tampilan Layar Lebih Luas",
                    desc: "Layar lebih besar memungkinkan Anda melihat grafik, data pasar, dan analisis portfolio sekaligus untuk keputusan yang lebih tepat."
                },
                {
                    image: Init.config.sectionAssets.winvest.desktopFeature3,
                    title: "Akses Offline untuk Data Tertentu",
                    desc: "Akses data dan laporan offline, sehingga Anda tetap terhubung dengan informasi penting tanpa koneksi internet."
                },
            ]
        }
    }

    render() {
        return (
            <div className="winvest-desktop-advantage">
                {/* 
                    1. Title
                    2. 3 Items: Buat akun dengan mudah, pilih produk investasi yang tepat, dan kembangkan investasi anda
                */}
                <h2 className="winvest-desktop-advantage-title">
                    Trading Tanpa Batas dengan Fitur Multi-Monitor untuk Saham Anda
                </h2>
                <div className="winvest-desktop-advantage-items">
                    {
                        this.state.feature.map((item, index) => {
                            return (
                                <div key={ index } className="winvest-desktop-advantage-item">
                                    {/* 
                                        1. Image
                                        2. Text Content: Title, Desc
                                    */}
                                    {/* <hr className='winvest-desktop-advantage-line' /> */}
                                    <img src={ item.image } alt="" />
                                    <div className="winvest-desktop-advantage-item-content">
                                        <h4 className="winvest-desktop-advantage-item-title">
                                            { item.title }
                                        </h4>
                                        <p className="winvest-desktop-advantage-item-desc">
                                            { item.desc }
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default WinvestDesktopAdvantage;