import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './AboutUs.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const AboutUs = (props) => {
    const { aboutUs } = Init.config.sectionAssets;

    return (
        <div 
            id="about-us" 
            className="about-us"
            style={{
                backgroundImage: `url(${ aboutUs.background2 })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <div className="about-us-section">
                {/* <div className="about-us-header-container">
                    <h2 className='about-us-title'>
                        Dengan pengalaman lebih dari 30 tahun, PT. Waterfront Sekuritas Indonesia berkomitmen membantu pertumbuhan aset mitra investasi melalui berbagai lini layanan finansial, seperti Equity Trading, Repurchase Agreement (Repo), Equity Research, dan Investment Banking.
                    </h2>
                </div> */}
                <p className="about-us-desc bold">
                    Dengan pengalaman lebih dari 30 tahun, PT. Waterfront Sekuritas Indonesia berkomitmen membantu pertumbuhan aset mitra investasi melalui berbagai lini layanan finansial, seperti Equity Trading, Repurchase Agreement (Repo), Equity Research, dan Investment Banking.
                </p>
                <p className="about-us-desc">
                    PT. Waterfront Sekuritas Indonesia berkantor pusat di Jakarta, dan memiliki kantor cabang di beberapa kota besar di Indonesia. Kesuksesan perusahaan tentunya tidak lepas dari pengalaman dan integritas yang dimiliki oleh para profesional perusahaan yang sudah berkecimpung dalam industri finansial dan pasar modal lebih dari dari 35 tahun pengalaman.
                </p>
                <p className="about-us-desc">
                    Dengan kepercayaan yang diberikan bagi kami dari klien ritel maupun korporat, PT. Waterfront Sekuritas Indonesia berkomitmen untuk terus berkembang menjadi mitra finansial dan investasi terbaik di Indonesia.
                </p>
            </div>
            <div className="about-us-image">
                <img src={aboutUs.image} alt="" />
            </div>
        </div>
    );
}



export default AboutUs;