import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './WinvestWebFeature.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const WinvestWebFeature = (props) => {
    const { winvest } = Init.config.sectionAssets;
    const features = [
        "Personalisasi Halaman Trading",
        "Sistem Keamanan yang Andal",
        "Notifikasi Real-Time"
    ];

    return (
        <div 
            id="winvest-web-feature" 
            className="winvest-web-feature"
        >
            <div className="winvest-web-feature-image-container">
                <img src={winvest.chart} alt="" />
            </div>
            <div className="winvest-web-feature-section">
                <h5 className="winvest-web-feature-subtitle">
                    Winvest Web
                </h5>
                <div className="winvest-web-feature-header-container">
                    <h2 className='winvest-web-feature-title'>
                        Online Trading melalui Winvest Website.
                    </h2>
                </div>
                <p className="winvest-web-feature-desc">
                    Winvest hadir dalam versi website dan dapat diakses di berbagai device. Melalui jaringan internet, Anda dapat memantau aset dan trading dimanapun Anda berada.
                </p>
                {/* Item List */}
                <ul className='winvest-web-feature-items'>
                    {
                        features.map( (item, index) => {
                            return (
                                <li key={index} className="winvest-web-feature-item">
                                    { item }
                                </li>
                            );
                        } )
                    }
                </ul>
            </div>
        </div>
    );
}



export default WinvestWebFeature;