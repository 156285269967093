import React, { useEffect, useState } from 'react';

import Init from '../../../Init/Init';

import './WinvestMobileAdvantage.scss';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

const WinvestMobileAdvantagesList = (props) => {
    const { prices } = props;
    
    const gaEventCategory = 'Winvest Mobile App';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const { FontAwesomeIcon } = Init;
    
    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "winvest-mobile-advantages-item-container";
    carouselConfig.itemClass = "winvest-mobile-advantages-item-holder";
    carouselConfig.showDots = true;
    carouselConfig.infinite = false;
    carouselConfig.centerMode = false;
    carouselConfig.dotListClass = 'winvest-mobile-advantages-item-dot-list';
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1550 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1550, min: 1200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 1200, min: 800 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

    carouselConfig.removeArrowOnDeviceType = [
        'desktop',
        'tablet',
        'mobileTablet',
        'mobile'
    ];

    return (
        <Carousel
                // arrows={false}
                // renderButtonGroupOutside={true}
                // customButtonGroup={<ButtonGroup />}
                swipeable={carouselConfig.swipeable}
                draggable={carouselConfig.draggable}
                showDots={carouselConfig.showDots}
                responsive={carouselConfig.responsive}
                ssr={carouselConfig.ssr} // means to render carousel on server-side.
                infinite={carouselConfig.infinite}
                autoPlay={carouselConfig.autoPlay}
                autoPlaySpeed={carouselConfig.autoPlaySpeed}
                keyBoardControl={carouselConfig.keyBoardControl}
                transitionDuration={carouselConfig.transitionDuration}
                removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                deviceType={carouselConfig.deviceType}
                containerClass={carouselConfig.containerClass}
                dotListClass={carouselConfig.dotListClass}
                itemClass={carouselConfig.itemClass}
                centerMode={carouselConfig.centerMode}
            >
                {
                    prices.map( (v, k) => {
                        return (
                            <div key={k} className="winvest-mobile-advantages-item">
                                <div className="winvest-mobile-advantages-content">
                                    <h3 className="winvest-mobile-advantages-item-title">
                                            { v.title }
                                    </h3>
                                    {
                                        v.hasOwnProperty('desc') ? (
                                            <p className="winvest-mobile-advantages-item-desc">
                                                { v.desc }
                                            </p>
                                        ) : ''
                                    }
                                </div>
                                <img src={v.image} alt={ v.title } className='winvest-mobile-advantages-item-icon' />
                            </div>
                        );
                    } )
                }
        </Carousel>
    );
}

const WinvestMobileAdvantages = (props) => {
    const [ advantages, setAdvantages ] = useState([]);
    const { winvest } = Init.config.sectionAssets;

    useEffect( () => {
        const response = [
            {
                title: "Pantau Portfolio Secara Real-Time",
                desc: "memungkinkan Anda melihat perkembangan investasi secara langsung kapan saja. Fitur ini memudahkan Anda memantau kinerja, mengambil keputusan cepat, dan mengelola risiko dengan lebih efektif.",
                image: `${ winvest.advantage1 }`
            },
            {
                title: "Fitur Fast Trade",
                desc: "Anda bisa memanfaatkan pergerakan pasar yang cepat dan membuat keputusan investasi secara instan. Fitur ini memberikan eksekusi transaksi dalam hitungan detik, mengurangi risiko keterlambatan, dan memaksimalkan potensi keuntungan.",
                image: `${ winvest.advantage2 }`
            },
            {
                title: "Analisis Pasar Mendalam",
                desc: "Winvest menyediakan data komprehensif dan analisis tren terkini untuk membantu Anda membuat keputusan yang lebih terinformasi, meminimalkan risiko, dan memaksimalkan hasil investasi.",
                image: `${ winvest.advantage3 }`
            },
            {
                title: "UI Friendly",
                desc: "Winvest dirancang untuk kemudahan dan kenyamanan. Dengan tata letak yang intuitif, navigasi yang sederhana, dan desain yang menarik, fitur ini memastikan pengguna dapat mengakses semua fungsi dengan cepat tanpa kesulitan.",
                image: `${ winvest.advantage4 }`
            },
            {
                title: "Portfolio for Investors",
                desc: "Dengan visualisasi data yang jelas dan real-time, Anda dapat memantau perkembangan aset, mengevaluasi strategi, dan membuat keputusan investasi yang lebih terinformasi.",
                image: `${ winvest.advantage5 }`
            },
        ];

        if( response.length > 0 ) {
            setAdvantages(response);
        }
        
    }, []);

    return (
        <div id="mobile-advantage" className="winvest-mobile-advantages">
            <h2 className='winvest-mobile-advantages-title'>
                Keunggulan Winvest Mobile untuk Investasi Anda
            </h2>
            {/* <img className="winvest-transparent-bg" src={winvest.transparentLogo} alt="" /> */}
            {
                advantages.length > 0 ? (
                    <WinvestMobileAdvantagesList prices={advantages} />
                ) : (
                    <div className="winvest-mobile-advantages-loading-section">
                        <LoadingPage />
                    </div>
                )
            }
        </div>
    );
}



export default WinvestMobileAdvantages;