import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './RepoRoles.scss';

class RepoRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [
                {
                    image: Init.config.sectionAssets.repo.role1,
                    title: "Penyedia Likuiditas",
                    desc: "Repurchase Agreement (Repo) memungkinkan institusi keuangan mendapatkan dana cepat dengan menjual aset sementara, membantu menjaga likuiditas pasar."
                },
                {
                    image: Init.config.sectionAssets.repo.role2,
                    title: "Manajemen Risiko",
                    desc: "Dengan mekanisme jaminan (collateral), Repo mengurangi risiko kredit bagi kedua belah pihak, menciptakan kepercayaan dalam transaksi."
                },
                {
                    image: Init.config.sectionAssets.repo.role3,
                    title: "Stabilitas Pasar",
                    desc: "Repo mendukung operasi pasar uang dan kebijakan moneter, menjaga kestabilan suku bunga dan aliran dana dalam sistem keuangan."
                },
            ]
        }
    }

    render() {

        return (
            <div className="repo-roles">
                {/* 
                    1. Title
                    2. 3 Items: Buat akun dengan mudah, pilih produk investasi yang tepat, dan kembangkan investasi anda
                */}
                <h2 className="repo-roles-title">
                    Peran Repurchase Agreement dalam Sistem Keuangan
                </h2>
                <div className="repo-roles-items">
                    {
                        this.state.roles.map((item, index) => {
                            return (
                                <div key={ index } className="repo-roles-item">
                                    {/* 
                                        1. Image
                                        2. Text Content: Title, Desc
                                    */}
                                    <img src={ item.image } alt="" />
                                    <div className="repo-roles-item-content">
                                        <h4 className="repo-roles-item-title">
                                            { item.title }
                                        </h4>
                                        <p className="repo-roles-item-desc">
                                            { item.desc }
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default RepoRoles;