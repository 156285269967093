import React, {Component} from 'react';
import './ContactAddress.scss';

const ContactAddress = (props) => {
    return (
        <div className="contact-address">
           {/* 
                1. Contact Address Title
                2. Contact Addresses
           */}
           <h3 className="contact-address-title">
                Branch Office
           </h3>
           <div className="contact-address-branches">
                {
                    props.branchOffice.map( (item, index) => {
                        return (
                            <div key={index} className="branch">
                                <h4 className="branch-name">{item.office}</h4>
                                <p className="address">
                                    { item.address }
                                </p>
                                <span className="phone"> Phone: {item.phone} | Fax: {item.fax}</span>
                            </div>
                        );
                    } )
                }
           </div>
        </div>
    );
}

export default ContactAddress;