import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './MarginFacilityUsage.scss';

class MarginFacilityUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workMethod: [
                {
                    title: "1. Pengajuan",
                    desc: "Ajukan fasilitas margin dengan mudah melalui akun Anda."
                },
                {
                    title: "2. Evaluasi Kelayakan",
                    desc: "Tim kami akan memproses dan menilai kelayakan Anda untuk fasilitas ini."
                },
                {
                    title: "3. Persetujuan & Aktivasi",
                    desc: "Setelah disetujui, margin akan aktif, dan Anda dapat segera menggunakannya."
                },
                {
                    title: "4. Pengelolaan Risiko",
                    desc: "Kami menyediakan alat pengelolaan risiko yang canggih untuk memastikan keamanan investasi Anda."
                },
            ]
        }
    }

    render() {
        return (
            <div className="margin-facility-usage">
                {/* 
                    1. Title
                    2. 4 items
                */}
                <h2 className="margin-facility-usage-title">
                    Cara Kerja Margin Facility
                </h2>
                <div className="margin-facility-usage-items">
                    {
                        this.state.workMethod.map((item, index) => {
                            return (
                                <div key={ index } className="margin-facility-usage-item">
                                    {/* 
                                        1. Image
                                        2. Text Content: Title, Desc
                                    */}
                                    <hr className='margin-facility-usage-line' />
                                    <div className="margin-facility-usage-item-content">
                                        <h4 className="margin-facility-usage-item-title">
                                            { item.title }
                                        </h4>
                                        <p className="margin-facility-usage-item-desc">
                                            { item.desc }
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default MarginFacilityUsage;