import React, {Component} from 'react';
import './ResearchListPage.scss';

import Init from '../../Init/Init';
import NavigationContext from '../../Context/NavigationContext';
import ResearchListHeader from '../../Component/PagesComponent/ResearchListHeader/ResearchListHeader';
import ResearchList from '../../Component/PagesComponent/ResearchList/ResearchList';

class ResearchListPage extends Component {
    constructor(props) {
        super(props);
        
        this.config = Init.config;
        this.state = {
            keyword: "",
            sort: 1, // 0: ASC, 1: DESC
            year: "2025",
            category: 1,
        }
    }

    static contextType = NavigationContext;

    resizeEvent(event) {}

    scrollEvent(event) {}

    componentDidMount() {
        const { id } = this.context.params;
        
        window.addEventListener('resize', this.resizeEvent);
        window.addEventListener('scroll', this.scrollEvent);

        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

        this.setState({
            ...this.state,
            category: parseInt(id),
        });
     }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEvent);
        window.removeEventListener('scroll', this.scrollEvent);
    }

    updateSearch(sort, year) {
        this.setState({
            ...this.state,
            sort: !isNaN(sort) ? sort : this.state.sort,
            year: year != null ? year : this.state.year,
        });
    }

    render() {
        return (
            <div className="research-list-page">
                {/* 
                    1. ResearchListHeader
                    2. ResearchList
                */}
                <img src={ this.config.logogram.first } className={`research-background-img first`} alt="" />
                <ResearchListHeader 
                    updateSearch={ (sort, year) => this.updateSearch(sort, year) } 
                    category={ this.state.category }
                    sort={ this.state.sort }
                    year={ this.state.year }
                />
                <ResearchList
                    category={ this.state.category }
                    sort={ this.state.sort }
                    year={ this.state.year }
                />
                <img src={ this.config.logogram.first } className={`research-background-img second`} alt="" />
            </div>
        );
    }
}

export default ResearchListPage;