import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './Advantages.scss';
import ContactInfo from '../../../Data/Contact';

const AdvantageList = (props) => {
    const { advantages } = props;

    return (
        <Fragment>
            <div className="advantage-section">
                <div className="advantage-header-container">
                    <h2 className='advantage-title'>
                        Pilihan Tepat untuk Berbagai Kebutuhan Finansial Anda
                    </h2>
                </div>
                {
                    advantages.map( (v, k) => {
                        return (
                            <div key={k} className={"advantage-item" + (k % 3 > 0 ? " advantage-item-reverse" : "")}>
                                <div className="advantage-item-illustration">
                                    <img src={v.icon} alt="Illustrasi" />
                                </div>
                                <div className="advantage-item-content">
                                    <h3 className="advantage-item-title">
                                        { v.name }
                                    </h3>
                                    <p className="advantage-item-desc" dangerouslySetInnerHTML={{__html: v.description}}>
                                        
                                    </p>
                                </div>
                            </div>
                        );
                    } )
                }
            </div>
        </Fragment>
    );
}

const Advantages = (props) => {
    const { whyUs } = Init.config.sectionAssets;
    const info = ContactInfo();
    const prpLink = info.prpLink;

    const [ advantages, setAdvantages ] = useState([
        {
            name: 'Kredensial Terpercaya',
            illustration: 'mobile-phone-bokeh-1.png',
            icon: whyUs.trustedCreds,
            description: `Kami menyediakan tim profesional yang berpengalaman lebih dari 30 tahun di industri untuk membantu Anda merasakan pengalaman investasi yang aman dan nyaman.`
        },
        {
            name: 'Investasi Real-Time',
            illustration: 'mobile-phone-bokeh-1.png',
            icon: whyUs.realtimeInvest,
            description: `Platform kami didukung oleh teknologi keamanan terkini, memastikan setiap transaksi Anda aman dan lancar.`
        },
        {
            name: 'Insight Pasar Extensive',
            illustration: 'mobile-phone-bokeh-1.png',
            icon: whyUs.extensiveMarketInsight,
            description: `Menyediakan fitur analitik yang mudah digunakan, termasuk charting tools, indikator teknikal, dan laporan kinerja portfolio untuk mendukung strategi investasi Anda.`
        }
    ]);

    return (
        <div id="advantage" className="advantages">
            <AdvantageList advantages={advantages} />
            <img src={whyUs.image} alt="" />
        </div>
    );
}



export default Advantages;