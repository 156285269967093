import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './RepoGoals.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const RepoGoals = (props) => {
    const { repo } = Init.config.sectionAssets;
    const goals = [
        {
            title: "Sumber Pendanaan Jangka Pendek",
            desc: "Bagi lembaga keuangan, Repo merupakan cara cepat dan fleksibel untuk mendapatkan dana tunai tanpa harus menjual aset jangka panjang."
        },
        {
            title: "Manajemen Likuiditas",
            desc: "Repo digunakan untuk mengelola tingkat likuiditas sehari-hari. Bank sentral sering menggunakan Repo untuk mempengaruhi jumlah uang beredar di pasar."
        },
        {
            title: "Transaksi Repo Terbalik",
            desc: "Selain Repo biasa, ada juga Repo terbalik di mana pihak yang biasanya menjadi pembeli menjadi penjual. Ini sering digunakan untuk mengelola risiko suku bunga."
        },
        {
            title: "Pasar Uang",
            desc: "Repo adalah instrumen penting di pasar uang. Banyak transaksi Repo terjadi antar bank, lembaga keuangan, dan bahkan dengan bank sentral."
        }
    ];

    return (
        <div 
            id="repo-goals" 
            className="repo-goals"
        >
            <img src={repo.goal} alt="" />
            <div className="repo-goals-section">
                <div className="repo-goals-header-container">
                    <h2 className='repo-goals-title'>
                        Tujuan Repurchase Agreement
                    </h2>
                </div>
                {/* Item List */}
                <ul className='repo-goals-items'>
                    {
                        goals.map( (item, index) => {
                            return (
                                <>
                                    <li className="repo-goals-item">
                                        { item.title }
                                        <p>
                                            { item.desc }
                                        </p>
                                    </li>
                                </>
                            );
                        } )
                    }
                </ul>
            </div>
        </div>
    );
}



export default RepoGoals;