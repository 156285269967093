import React, { useContext, useState, Fragment } from 'react';

import Init from '../../../Init/Init';
import './Winvest.scss';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const Winvest = (props) => {
    const { winvest } = Init.config.sectionAssets;

    return (
        <div 
            id="winvest" 
            className="winvest"
            style={{
                backgroundImage: `url(${props.customBg})`,
                backgroundPosition: `center`,
                backgroundSize: `cover`,
                backgroundRepeat: `no-repeat`
            }}
        >
            <div className="winvest-image">
                <img src={`${props.customImage}`} alt="" />
            </div>
            <div className="winvest-section">
                <div className="winvest-header-container">
                    <h2 className='winvest-title'>
                        { props.title }
                    </h2>
                </div>
                <p className="winvest-desc" dangerouslySetInnerHTML={{__html: `${ props.desc }`}}>
                    
                </p>
                <div className="winvest-download-container">
                    {
                        props.showGooglePlay && (
                            <a target="_blank" href="/google-play">
                                <img src={winvest.googlePlay} alt="Google Play Store" />
                            </a>
                        )
                    }
                    {
                        props.showAppStore && (
                            <a target="_blank" href="/app-store">
                                <img src={winvest.appStore} alt="Apple App Store" />
                            </a>
                        )
                    }
                    {
                        props.showMacDownload && (
                            <a target="_blank" href="/mac-os">
                                <img src={ winvest.macos } alt="" />
                            </a>
                        )
                    }
                    {
                        props.showWindowsDownload && (
                            <a target="_blank" href="/windows">
                                <img src={ winvest.windows } alt="" />
                            </a>
                        )
                    }
                </div>
                {/* <PrimaryButton 
                    text="Pelajari lebih lanjut"
                    customClass="winvest-btn"
                    href="/"
                /> */}
            </div>
        </div>
    );
}

Winvest.defaultProps = {
    customBg: "",
    customImage: "",
    title: "Download Winvest",
    desc: `Lengkap, cepat, dan terpercaya.
    <br />
    Bersama Winvest, raih pertumbuhan aset Anda!`,
    showGooglePlay: false,
    showAppStore: false,
    showMacDownload: false,
    showWindowsDownload: false,
    showWebsiteLink: false,
}

export default Winvest;