import React, { useState, useEffect, useRef } from 'react';
import './ResearchListHeader.scss';
import Init from '../../../Init/Init';
import { researchCategoryGet } from '../../../Data/Research';
import { Link } from 'react-router-dom';
import { Dropdown, Offcanvas } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const ResearchListHeader = (props) => {
    const [ category, setCategory ] = useState({});
    const { FontAwesomeIcon, config } = Init;
    const { sectionAssets } = config;

    const [ openFilter, setOpenFilter ] = useState(false);
    const [ sort, setSort ] = useState(1);
    const [ year, setYear ] = useState("2025");
    const [ isSetFilter, setIsSetFilter ] = useState(false);
    
    const resetSearch = () => {
        updateSearch(1, "2025");
        setIsSetFilter(false);
    }

    const updateSearch = (selectedSort, selectedYear) => {
        props.updateSearch(selectedSort, selectedYear);
        setIsSetFilter(true);
        closeFilterTab();
    }

    const sortOption = [
        { value: 1, label: "Newest" },
        { value: 0, label: "Oldest" } 
    ];

    const yearOption = [
        { value: "2025", label: "2025" },
        { value: "2024", label: "2024" },
        { value: "2023", label: "2023" },
    ];

    useEffect( () => {
        ( async () => {
            const response = await researchCategoryGet(props.category);

            if( response.errorCode === 0 ) {
                setCategory(response.data);
            }
        } )();
    }, [props.category]);

    /* Filter Tab */
    const openFilterTab = () => {
        setSort(props.sort);
        setYear(props.year);
        setOpenFilter(true);
    }

    const closeFilterTab = () => {
        setOpenFilter(false);
    }

    const checkDropdownToggle = (nextShow) => {
        if( nextShow ) {
            document.getElementById('drawer-year-option').classList.add("expand");
        } else {
            document.getElementById('drawer-year-option').classList.remove("expand");
        }
    }

    return (
        <div 
            className="research-list-header"
        >
            {/* 
                1. Path
                2. Dropdown: Sort and Dropdown
            */}
            <div className="research-list-header-breadcrumbs">
                <Link to="/research" className="breadcrumb-path">Riset</Link> / <Link to="#" className="breadcrumb-path breadcrumb-highlight">{ category?.category_name }</Link>
            </div>
            {
                !isMobile ? (
                    <div className="research-list-header-dropdown-container">
                        <Dropdown
                            className="research-list-header-dropdown"
                        >
                            <Dropdown.Toggle>
                                Select By <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    sortOption.map( (item, index) => {
                                        return (
                                            <Dropdown.Item key={index} className={`${ item.value === props.sort ? "active" : "" }`} onClick={() => updateSearch(item.value, null)}>
                                                { item.label }
                                            </Dropdown.Item>
                                        )
                                    } )
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown
                            className="research-list-header-dropdown"
                        >
                            <Dropdown.Toggle>
                                Select Year <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    yearOption.map( (item, index) => {
                                        return (
                                            <Dropdown.Item key={index} className={`${ item.value === props.year ? "active" : "" }`} onClick={() => updateSearch(null, item.value)}>
                                                { item.label }
                                            </Dropdown.Item>
                                        )
                                    } )
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                ) : (
                    <>
                        <div className="research-list-header-filter">
                            <button onClick={() => openFilterTab()} className={`research-list-header-filter-button`}>
                                Filter <img src={`${ isSetFilter ? sectionAssets.research.filterCheck : sectionAssets.research.filter }`} alt={`Filter`} />
                            </button>
                        </div>
                        <Offcanvas 
                            show={ openFilter } 
                            placement='bottom' 
                            onHide={() => closeFilterTab()}
                            className="research-list-header-filter-drawer h-auto"
                        >
                            <Offcanvas.Header className='research-list-header-filter-drawer-header'>

                            </Offcanvas.Header>
                            <Offcanvas.Body className='research-list-header-filter-drawer-body'>
                                {/* 
                                    Label:
                                    Select Sort
                                    Select year
                                    Button Container
                                */}
                                <span className="drawer-label">
                                    Filter By:
                                </span>
                                <div className="drawer-options">
                                    <span>
                                        Sort By
                                    </span>
                                    {/* Buttons */}
                                    <div className="drawer-option-btn-container">
                                        {
                                            sortOption.map( (item, index) => {
                                                return (
                                                    <PrimaryButton 
                                                        text={item.label}
                                                        customClass={`drawer-option-btn ${ sort === item.value ? "active" : "" }`}
                                                        customIcon={ sort === item.value ? <FontAwesomeIcon icon={['fas', 'check']} /> : null }
                                                        iconPosition={`left`}
                                                        onClick={() => setSort(item.value)}
                                                    />
                                                );
                                            } )
                                        }
                                    </div>
                                </div>
                                <div className="drawer-options" id="drawer-year-option">
                                    <span>
                                        Select Year
                                    </span>
                                    {/* Dropdown */}
                                    <Dropdown
                                        className="drawer-dropdown"
                                        drop="down"
                                        onToggle={(nextShow, meta) => checkDropdownToggle(nextShow)}
                                    >
                                        <Dropdown.Toggle>
                                            { year ? year : "Select Year"} <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            flip={false}
                                        >
                                            {
                                                yearOption.map( (item, index) => {
                                                    return (
                                                        <Dropdown.Item key={index} className={`${ item.value === year ? "active" : "" }`} onClick={() => setYear(item.value)}>
                                                            { item.label } { item.value === year ? (<FontAwesomeIcon icon={['fas', 'check']} />) : "" } 
                                                        </Dropdown.Item>
                                                    )
                                                } )
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="drawer-btn-container">
                                    <PrimaryButton 
                                        text={`Reset Filter`}
                                        customClass={`filter-reset`}
                                        onClick={() => resetSearch()}
                                    />
                                    <PrimaryButton 
                                        text={`Apply Filter`}
                                        customClass={`filter-apply`}
                                        onClick={() => updateSearch(sort, year)}
                                    />
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </>
                )
            }
        </div>
    );
}

export default ResearchListHeader;