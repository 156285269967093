import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './Supporter.scss';

class Supporter extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { ojk, idx, idclear, ksei } = Init.config.sectionAssets.supporter;
        
        return (
            <div className="supporter">
                <div className="supporter-wrapper">
                    {/* 
                        - Berizin dan diawasi oleh:
                        - OJK, IDX, IDClear, KSEI
                    */}
                    <div className="supporter-text">
                        berizin dan diawasi oleh
                    </div>
                    <div className="supporter-logos">
                        <div className="supporter-logo">
                            <img src={ojk} alt={`Otoritas Jasa Keuangan`} />
                        </div>
                        <div className="supporter-logo">
                            <img src={idx} alt={`IDX`} />
                        </div>
                        <div className="supporter-logo">
                            <img src={idclear} alt={`IDClear`} />
                        </div>
                        <div className="supporter-logo">
                            <img src={ksei} alt={`KSEI`} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Supporter;