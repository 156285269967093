import { responseFormat } from '../Util/Util';
import api from './API';

const faqCategory = [
    {
        id: 1,
        categoryName: "Pertanyaan Umum"
    },
    {
        id: 2,
        categoryName: "Reksa Dana"
    },
    {
        id: 3,
        categoryName: "Trading"
    },
    {
        id: 4,
        categoryName: "Riset / Analisis"
    },
    {
        id: 5,
        categoryName: "Obligasi"
    },
    {
        id: 6,
        categoryName: "SBN"
    },
];

const faqQuestion = [
    {
        id: 1,
        categoryId: 1,
        question: "Apa itu PT Waterfront Sekuritas Indonesia?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 2,
        categoryId: 1,
        question: "Apakah pentingnya akun Rekening Dana Nasabah (RDN) dalam bertransaksi?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 3,
        categoryId: 1,
        question: "Dokumen apa saja yang harus dilampirkan untuk persyaratan pembukaan rekening?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 4,
        categoryId: 1,
        question: "Bagaimana jika saya tidak menerima OTP?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 5,
        categoryId: 1,
        question: "Berapa minimum deposit untuk memulai investasi di PT Waterfront Sekuritas?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 6,
        categoryId: 1,
        question: "Bagaimana cara melakukan transaksi saham melalui Winvest?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 7,
        categoryId: 1,
        question: "Bagaimana cara mendapatkan dividen saham?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 8,
        categoryId: 1,
        question: "Bagaimana cara menghubungi tim customer service PT Waterfront Sekuritas?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 9,
        categoryId: 1,
        question: "Kemana saya harus menghubungi jika saya masih menemui kendala pada Winvest?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 10,
        categoryId: 1,
        question: "Bagaimana cara menarik dana dari akun saya?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 11,
        categoryId: 1,
        question: "Apakah Mandiri Sekuritas tetap memproses order (pesanan) meskipun terjadi kendala di Winvest?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
    {
        id: 12,
        categoryId: 1,
        question: "Apakah PT Waterfront Sekuritas aman dan diawasi oleh otoritas keuangan?",
        answer: "PT Waterfront Sekuritas Indonesia adalah perusahaan sekuritas yang menyediakan berbagai layanan keuangan, termasuk perdagangan saham, obligasi, penjaminan emisi, dan layanan konsultasi keuangan. Kami telah beroperasi sejak tahun 1990 dan merupakan anggota Bursa Efek Indonesia."
    },
];

const pathList = {
    categoryList: `faq-category/`,
    list: `faq/`
};

export const faqCategoryRender = async () => {
    // return faqCategory;

    try {
        const response = await api.getCall(pathList.categoryList);

        return response;
    } catch(e) {
        console.log(e.toString());

        return responseFormat(-1, "Failed to do API Call!");
    }
}

export const faqRender = async (categoryId, keyword = "") => {
    try {
        const response = await api.getCall(pathList.list, null, {
            keyword,
            categoryId,
        });

        return response;
        
        // let filteredFAQs = faqQuestion;
        
        // if( keyword !== "" ) {
        //     filteredFAQs = faqQuestion.filter((faq) => {
        //         const questionMatch = faq.question.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
        //         const answerMatch = faq.answer.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
    
    
        //         return questionMatch || answerMatch;
        //     }
        // );
        // } else {
        //     filteredFAQs = faqQuestion.filter((faq) => {
        //         const categoryMatch = faq.categoryId == category;
    
        //         return categoryMatch;
        //     });
        // }
    
        // return filteredFAQs;
    } catch(e) {
        console.log(e.toString());

        return responseFormat(-1, "Failed to do API Call!");
    }

}