import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './ContactBanner.scss';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';
import { buildWaLink } from '../../../Util/Util';

class ContactBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener("resize", (event) => this.onResize(event));
    }

    onResize(event) {
        const section = document.getElementById("contact-banner");
        const screenWidth = window.innerWidth;

        if( screenWidth > 1560 ) {
            // Cover
            section.style.backgroundSize = "cover";
        } else {
            // Contain
            section.style.backgroundSize = "contain";
        }
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    render() {
        const {FontAwesomeIcon} = Init;
        const { contactUs } = Init.config.sectionAssets;
        const { logogram } = Init.config;

        return (
            <div id="contact-banner" className="contact-banner"
                // style={{
                //     backgroundImage: `url(${ contactUs.bannerBg })`,
                //     backgroundPosition: 'center',
                //     backgroundSize: "cover",
                //     backgroundRepeat: "no-repeat"
                // }}
            >
                <img className='contact-banner-logogram first' src={`${ logogram.second }`} />
                <h3 className="contact-banner-title">
                    Hubungi Kami untuk Solusi Keuangan Anda
                </h3>
                <p className="contact-banner-desc">
                    Siap untuk memulai perjalanan investasi Anda? Tim kami siap membantu Anda mencapai tujuan keuangan dengan solusi yang tepat
                </p>
                <PrimaryButton 
                    text="Kontak Kami"
                    link={`/contact`}
                    customClass="contact-banner-btn"
                    iconPosition="right"
                    customIcon={
                        <FontAwesomeIcon icon={['far', 'user']} className='contact-banner-btn-icon' />
                    }
                />
                <img className='contact-banner-logogram second' src={`${ logogram.second }`} />
            </div>
        );
    }
}

export default ContactBanner;