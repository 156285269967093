import React, {Component, Fragment} from 'react';
import Init from '../../../Init/Init';
import './InvestmentBankingAdvantage.scss';
import { isMobile } from 'react-device-detect';
import Carousel from 'react-multi-carousel';

class InvestmentBankingAdvantage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                desktop: {
                    breakpoint: { max: 3000, min: 1200 },
                    // items: 4,
                    items: 3,
                    slidesToSlide: 1, // optional, default to 1.
                },
                tablet: {
                    breakpoint: { max: 1200, min: 920 },
                    items: 3,
                    partialVisibilityGutter: 40,
                    slidesToSlide: 1 // optional, default to 1.
                },
                mobileTablet: {
                    breakpoint: { max: 920, min: 800 },
                    items: 2,
                    slidesToSlide: 2 // optional, default to 1.
                },
                mobile: {
                    breakpoint: { max: 800, min: 0 },
                    items: 1,
                    slidesToSlide: 1, // optional, default to 1.
                    partialVisibilityGutter: 80
                }
            },
            target: [
                {
                    title: "Penjaminan Emisi Efek (Underwriting)",
                    description: "Membantu penerbitan saham dan obligasi di pasar modal sebagai penjamin emisi.",
                    image: `${ Init.config.sectionAssets.investmentBanking.advantage1 }`
                },
                {
                    title: "Penawaran Umum Perdana (Initial Public Offering/IPO)",
                    description: "Membantu penerbitan saham atau obligasi di pasar modal dengan perusahaan sekuritas sebagai penjamin emisi.",
                    image: `${ Init.config.sectionAssets.investmentBanking.advantage5 }`
                },
                {
                    title: "Penggabungan dan Akuisisi (Mergers & Acquisitions/M&A)",
                    description: "Konsultasi dan bantuan untuk merger, akuisisi, atau restrukturisasi, dengan analisis strategi dan valuasi.",
                    image: `${ Init.config.sectionAssets.investmentBanking.advantage2 }`
                },
                {
                    title: "Private Placement",
                    description: "Membantu penjualan saham atau obligasi langsung ke investor institusional untuk penggalangan modal cepat dan efisien.",
                    image: `${ Init.config.sectionAssets.investmentBanking.advantage6 }`
                },
                {
                    title: "Restrukturasi Keuangan",
                    description: "Konsultasi restrukturasi keuangan, termasuk pengelolaan hutang, struktur modal, dan aset untuk kinerja finansial lebih baik.",
                    image: `${ Init.config.sectionAssets.investmentBanking.advantage3 }`
                },
                {
                    title: "Riset Pasar dan Valuasi",
                    description: "Memberikan riset dan analisis pasar untuk memahami kondisi pasar, tren industri, dan valuasi perusahaan untuk jual-beli atau akuisisi.",
                    image: `${ Init.config.sectionAssets.investmentBanking.advantage7 }`
                },
                {
                    title: "Advisory (Konsultasi Keuangan)",
                    description: "Memberikan strategi investasi, keuangan, dan ekspansi untuk mendukung pertumbuhan dan stabilitas jangka panjang.",
                    image: `${ Init.config.sectionAssets.investmentBanking.advantage4 }`
                },
            ]
        }
    }

    render() {
        return (
            <div className="investment-banking-advantage">
                <div className="investment-banking-advantage-wrapper">
                    {
                        isMobile ? (
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                responsive={this.state.responsive}
                                ssr={false} // means to render carousel on server-side.
                                infinite={false}
                                containerClass={"investment-banking-advantage-carousel"}
                                dotListClass={"investment-banking-advantage-dots"}
                                itemClass={"investment-banking-advantage-item"}
                                centerMode={false}
                                removeArrowOnDeviceType={['mobile']}
                                partialVisbile={true}
                            >
                                {
                                    this.state.target.map((v, k) => {
                                        return (
                                            <div className='investment-banking-advantage-item-container' key={k}>
                                                <div className="investment-banking-advantage-item-image-container">
                                                    <img src={v.image} alt={ v.title } className='investment-banking-advantage-item-icon' />
                                                </div>
                                                <div className="investment-banking-advantage-item-content">
                                                    <h3 className="investment-banking-advantage-item-title">
                                                            { v.title }
                                                    </h3>
                                                    {/* <a className="investment-banking-advantage-item-title-clickable" href={ v.href }>
                                                    </a> */}
                                                    {
                                                        v.hasOwnProperty('description') ? (
                                                            <p className="investment-banking-advantage-item-desc">
                                                                { v.description }
                                                            </p>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                        </Carousel>
                        ) : (
                            <div className="investment-banking-advantage-items">
                                {
                                    this.state.target.map((v, k) => {
                                        return (
                                            <div key={k} className="investment-banking-advantage-item">
                                                <div className="investment-banking-advantage-item-image-container">
                                                    <img src={v.image} alt={ v.title } className='investment-banking-advantage-item-icon' />
                                                </div>
                                                <div className="investment-banking-advantage-item-content">
                                                    <h3 className="investment-banking-advantage-item-title">
                                                            { v.title }
                                                    </h3>
                                                    {/* <a className="investment-banking-advantage-item-title-clickable" href={ v.href }>
                                                    </a> */}
                                                    {
                                                        v.hasOwnProperty('description') ? (
                                                            <p className="investment-banking-advantage-item-desc">
                                                                { v.description }
                                                            </p>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default InvestmentBankingAdvantage;