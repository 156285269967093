import React, {Component} from 'react';
import './ResearchPage.scss';

import Init from '../../Init/Init';
import ResearchHeader from '../../Component/PagesComponent/ResearchHeader/ResearchHeader';
import ResearchCategory from '../../Component/PagesComponent/ResearchCategory/ResearchCategory';
import ResearchList from '../../Component/PagesComponent/ResearchList/ResearchList';

class ResearchPage extends Component {
    constructor(props) {
        super(props);
        
        this.config = Init.config;
        this.state = {
            keyword: "",
        }

    }

    resizeEvent(event) {}

    scrollEvent(event) {}

    componentDidMount() {
        window.addEventListener('resize', this.resizeEvent);
        window.addEventListener('scroll', this.scrollEvent);

        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
     }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEvent);
        window.removeEventListener('scroll', this.scrollEvent);
    }

    updateKeyword(value) {
        this.setState({
            ...this.state,
            keyword: value
        });
    }

    render() {
        return (
            <div className="research-page">
                {/* 
                    1. ResearchHeader
                    2. ResearchCategory
                */}
                <img src={ this.config.logogram.first } className={`research-background-img first`} alt="" />
                <ResearchHeader updateKeyword={(value) => this.updateKeyword(value)} />
                {
                    this.state.keyword !== "" ? 
                    <ResearchList 
                        category={null}
                        sort={1}
                        year={"2025"}
                        keyword={this.state.keyword}
                    /> : (
                        <ResearchCategory />
                    )
                }
                <img src={ this.config.logogram.first } className={`research-background-img second`} alt="" />
            </div>
        );
    }
}

export default ResearchPage;