import React, {Component, useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Accordion, Row, Col } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './WinvestWebFAQ.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const CollapseBox = (props) => {
    const { boxNumber } = props;

    const gaEventCategory = 'FAQ';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const toggleQuestion = () => {
        if(!props.list.open) {
            gaEventTracker('Toggle Open Question', props.list.question);
        }

        props.onToggle();
    };

    return(
        <Accordion>
            <Accordion.Item eventKey={boxNumber} className="question-item">
                <Accordion.Header className="question-box" onClick={toggleQuestion}>
                    <span className="question-title">
                        {props.list.question}
                    </span>
                    {/* <Init.FontAwesomeIcon icon={['fas', (props.list.open ? 'angle-up' : 'angle-down')]} /> */}
                </Accordion.Header>
                <Accordion.Body className='question-content' dangerouslySetInnerHTML={{__html: props.list.answer}}>
                        {/* {props.list.answer}  */}
                    {/* <Card.Body> 
                    </Card.Body> */}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const WinvestWebFAQ = (props) => {
    const { winvest } = Init.config.sectionAssets;

    const [ list, setList ] = useState([
        {
            question: "Bagaimana cara akses online Winvest Web di desktop?", 
            answer: "Caranya dengan menekan salah satu tombol download untuk Mac atau Windows sesuai OS yang kamu miliki. Setelah instalasi, kamu dapat login akun Winvest ataupun mendaftarkan sebagai user baru.",
            open: false
        },
        {
            question: "Apakah Winvest Web di desktop berbayar?", 
            answer: "Akses aplikasi saham Winvest versi Web bisa digunakan secara gratis. Kamu hanya perlu log-in atau mendaftarkan akun di Stockbit.",
            open: false
        },
        {
            question: "Fitur apa saja yang tersedia di Winvest Web yang tidak ada di versi lain?", 
            answer: `Winvest Web menawarkan beberapa fitur tambahan yang tidak ada di versi mobile, seperti dukungan multi-monitor, grafik interaktif dengan lebih banyak indikator teknis, dan opsi kustomisasi antarmuka. Fitur-fitur ini dirancang untuk membantu pengguna mendapatkan pengalaman trading yang lebih profesional dan efisien.`,
            open: false
        },
        {
            question: "Apakah Winvest Web memungkinkan notifikasi atau peringatan real-time langsung di layar?", 
            answer: "Ya, Winvest Web dilengkapi dengan fitur notifikasi real-time, memungkinkan Anda menerima peringatan langsung di layar untuk perubahan harga, berita pasar penting, atau aktivitas akun. Fitur ini memastikan Anda tidak melewatkan peluang trading atau informasi penting, bahkan saat aplikasi berjalan di latar belakang.",
            open: false
        },
    ]);
    const handleToggle = (index) => {
        let listCopy = list;

        listCopy[index].open = !listCopy[index].open;

        setList([
            ...listCopy
        ]);
    };

    return (
        <div id="winvest-web-faq" className="winvest-web-faq-box">
            <div className="winvest-web-faq-header">
                <h2 className='winvest-web-faq-title'> Sering Ditanyakan </h2>
            </div>
            <div className="winvest-web-faq-container">
                <div className="question-group">
                    {
                        list.map( (item, index) => {
                            return(
                                <CollapseBox key={index} boxNumber={index} list={item} onToggle={ () => handleToggle(index) } />
                            );
                        } )
                    }
                </div>
                <div className="question-image">
                    <img src={ winvest.mobileFaqIcon } alt="" />
                </div>
            </div>
        </div>
    );
}

export default WinvestWebFAQ;