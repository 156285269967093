import React, {Component} from 'react';
import './WinvestDesktopPage.scss';

import Init from '../../Init/Init';

import ReactGA from 'react-ga4';
import WinvestDesktopFAQ from '../../Component/PagesComponent/WinvestDesktopFAQ/WinvestDesktopFAQ';
import WinvestDesktopHeader from '../../Component/PagesComponent/WinvestDesktopHeader/WinvestDesktopHeader';
import WinvestDesktopAdvantage from '../../Component/PagesComponent/WinvestDesktopAdvantage/WinvestDesktopAdvantage';
import WinvestDesktopFeature from '../../Component/PagesComponent/WinvestDesktopFeature/WinvestDesktopFeature';
import Winvest from '../../Component/PagesComponent/Winvest/Winvest';

class WinvestDesktopPage extends Component {
    constructor(props) {
        super(props);
        
        this.config = Init.config;
        this.state = {
            // downloadBackground: window.screen.width > 950 ? `${this.config.sectionAssets.winvest.background5}` : `${this.config.sectionAssets.winvest.background5Mobile}`
            downloadBackground: `${ this.config.sectionAssets.winvest.background5 }`,
            downloadImage: `${ this.config.sectionAssets.winvest.desktopPc }`,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', (event) => {
            // console.log(this.screen.width);
            // const screenWidth = event.target.innerWidth;

            // if( screenWidth > 950 ) {
            //     if( this.state.downloadBackground !== this.config.sectionAssets.winvest.background5 ) {
            //         this.setState({
            //             ...this.state,
            //             downloadBackground: `${this.config.sectionAssets.winvest.background5}`
            //         });
            //     }
            // } else {
            //     if( this.state.downloadBackground !== this.config.sectionAssets.winvest.background5Mobile ) {
            //         this.setState({
            //             ...this.state,
            //             downloadBackground: `${this.config.sectionAssets.winvest.background5Mobile}`
            //         });
            //     }
            // }
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', null);
    }

    render() {
        const { winvest } = Init.config.sectionAssets;

        return (
            <div className="winvest-desktop-page">
                {/* 
                    1. WinvestDesktopHeader
                    2. WinvestDesktopAdvantage
                    3. WinvestDesktopFeature
                    4. WinvestDesktopFAQ
                */}
                <WinvestDesktopHeader />
                <WinvestDesktopAdvantage />
                <WinvestDesktopFeature />
                <WinvestDesktopFAQ />
                <Winvest  
                    customBg={ this.state.downloadBackground } 
                    customImage={ this.state.downloadImage }
                    title={"Download Aplikasi Winvest di Desktop"}
                    desc={`
                        Bersama Winvest raih kemenangan di setiap tujuan finansialmu! 
                        <br />
                        Jangan sampai kelewatan.
                    `}
                    showMacDownload={true}
                    showWindowsDownload={true}
                />
            </div>
        );
    }
}

export default WinvestDesktopPage;