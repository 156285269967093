import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './Structure.scss';

class Structure extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { aboutUs } = Init.config.sectionAssets;
        
        return (
            <div className="org-structure">
                {/* 
                    1. Header
                    2. Org Structure Image
                */}
                <div className="org-structure-header-container">
                    <h2 className="org-structure-title">
                        Struktur Organisasi
                    </h2>
                </div>

                <div className="org-structure-image">
                    <img src={aboutUs.structure} alt="" />
                </div>
            </div>
        );
    }
}

export default Structure;