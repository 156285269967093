import React, { useContext, useEffect, useRef } from 'react';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import Init from '../../../Init/Init';
import './MarginFacilityHeader.scss';

let config = Init.config;
let { imageFolder, headerFolder, carousel, logogram } = config;

const TemplateOne = ({ index, image, mascot, header, text, ctaButton, affirmation, displayButtonOnMobileView = false }) => {
    const { marginFacility } = Init.config.sectionAssets;

    return (
        <div 
            id={`margin-facility-header-${index}`} 
            className="margin-facility-header"
            style={{
                backgroundImage: `url(${ marginFacility.background })`,
                backgroundPosition: `center`,
            }}
        >
            <div className="margin-facility-header-logogram">
                <img src={ logogram.fourth } alt="" />
            </div>
            <div className={`margin-facility-header-content ${ index % 2 === 0 ? 'reversed' : '' }`}>
                <div className="margin-facility-header-title-container">
                    <h1 className='margin-facility-header-title' dangerouslySetInnerHTML={{__html: header}}>
                        
                    </h1>
                    <div className='margin-facility-header-tagline'> 
                        <span dangerouslySetInnerHTML={{__html: text.tagline}}>
                        </span>
                        {
                            text.whisper && (
                                <small>({ text.whisper })</small>
                            )
                        }
                    </div>
                    <div className="margin-facility-header-cta-button-container">
                        {
                            ctaButton.map( (item, index) => {
                                const buttonClick = item.hasOwnProperty('callback') ? item.callback : null;

                                return (
                                    <a target="_blank" href={ item.url } className='margin-facility-header-cta-button'>
                                        <img src={ item.image } alt="" />
                                    </a>
                                )
                            } )
                        }
                    </div>
                    <small className="notes" dangerouslySetInnerHTML={{__html: affirmation}}>
                    </small>
                </div>
            </div>
        </div>
    );
}

const MarginFacilityHeaderCarousel = (props) => {
    const { marginFacility } = Init.config.sectionAssets;

    let slider = [
        {
            /**
             * Background Image
             * Mascot
             * MarginFacilityHeader
             * Text(Tagline, Whisper)
             * CTA Button
             * Affirmation: Working with PRP
             */
            image: ``,
            header: `
                <span class="inline-mobile">Margin Facility</span> 
            `,
            text: {
                tagline: `Fasilitas yang diberikan oleh Waterfront Sekuritas yang memungkinkan klien untuk membeli saham dengan dana pinjaman.`,
            },
            ctaButton: [
                
            ],
            displayButtonOnMobileView: true,
            // affirmation: `Bekerja sama dengan <span class='underline'>Pribadi Randa & Partners Law Firm</span>`,
            templateType: 1
        }, // 1
    ]

    /**
     * Carousels
     */
    let carouselConfig = carousel;

    carouselConfig.containerClass = "margin-facility-header-container";
    carouselConfig.itemClass = "margin-facility-header-item-holder";
    carouselConfig.autoPlay = false;
    carouselConfig.autoPlaySpeed = 5000;
    carouselConfig.showDots = false;
    carouselConfig.infinite = false;
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 900 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 900, min: 650 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 650, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

   return (
        <Carousel
            swipeable={carouselConfig.swipeable}
            draggable={carouselConfig.draggable}
            showDots={carouselConfig.showDots}
            responsive={carouselConfig.responsive}
            ssr={carouselConfig.ssr} // means to render carousel on server-side.
            infinite={carouselConfig.infinite}
            autoPlay={carouselConfig.autoPlay}
            autoPlaySpeed={carouselConfig.autoPlaySpeed}
            keyBoardControl={carouselConfig.keyBoardControl}
            transitionDuration={carouselConfig.transitionDuration}
            removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
            deviceType={carouselConfig.deviceType}
            containerClass={carouselConfig.containerClass}
            dotListClass={carouselConfig.dotListClass}
            itemClass={carouselConfig.itemClass}
            centerMode={carouselConfig.centerMode}
        >
            {
                slider.length > 0 ? (
                    slider.map((v, k) => {
                        return <TemplateOne 
                            key={k} 
                            index={k} 
                            image={ v.image }
                            mascot={ v.mascot }
                            header={ v.header }
                            text={ v.text }
                            ctaButton={ v.ctaButton }
                            affirmation={ v.affirmation }
                            displayButtonOnMobileView={ v.displayButtonOnMobileView }
                        />
                    })
                ) : (
                    <LoadingPage />
                )
            }
        </Carousel>
    );
}

const MarginFacilityHeader = (props) => {
    return <MarginFacilityHeaderCarousel />
}



export default MarginFacilityHeader;