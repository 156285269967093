import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { isMobile } from 'react-device-detect';

var Init = {};

// Constants
Init.constants = {
    serviceType: {
        legalService: 1,
        package: 2,
        businessEntity: 3
    },
    paymentService: {
        purwantara: 1,
        cashbac: 2
    }
};

// Basic config
Init.config = {
    carousel: {
        swipeable: true,
        draggable: true,
        showDots: true,
        responsive: {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        },
        ssr: false, // means to render carousel on server-side.
        infinite: true,
        autoPlay: false,
        autoPlaySpeed: 99999,
        keyBoardControl: true,
        customTransition: "all .5",
        transitionDuration: 500,
        removeArrowOnDeviceType: [
            // "tablet", 
            // "mobile", 
            // "web"
        ],
        deviceType: "web",
        partialVisible: false,
        centerMode: false,
        containerClass: "carousel-container",
        dotListClass: "custom-dot-list-style",
        itemClass: "carousel-item-padding-40-px"
    },
    customImageFilter: {
        none: [
            1, 0, 0, 0, 0,
            0, 1, 0, 0, 0,
            0, 0, 1, 0, 0,
            0, 0, 0, 1, 0,
        ],
        grayscale: [
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0, 0, 0, 1, 0,
        ]
    },
    imageFolder: "/assets/images/",
    logoFolder: "/assets/logo/",
    iconFolder: "/assets/icons/",
    logogramFolder: "/assets/logogram/",
    sampleFolder: "/assets/sample/",
    isMobile: isMobile,
    // googlePlayLink: "https://play.google.com/store/apps/details?id=com.landackapp",
    // appStoreLink: "https://apps.apple.com/id/app/landack/id6466232103",
    googleCaptchaSiteKey: process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY,
    googleCaptchaSecretKey: process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET_KEY
}

Init.config.headerFolder = `${Init.config.imageFolder}header/`;
Init.config.collabFolder = `${Init.config.imageFolder}collab/`;
Init.config.clientFolder = `${Init.config.imageFolder}client/`;
Init.config.supporterFolder = `${Init.config.imageFolder}supporter/`;
Init.config.winvestFolder = `${Init.config.imageFolder}winvest/`;
Init.config.aboutUsFolder = `${Init.config.imageFolder}about-us/`;
Init.config.teamsFolder = `${Init.config.imageFolder}teams/`;
Init.config.repoFolder = `${ Init.config.imageFolder }repo/`;
Init.config.marginFacilityFolder = `${ Init.config.imageFolder }margin-facility/`;
Init.config.investmentBankingFolder = `${ Init.config.imageFolder }investment-banking/`;
Init.config.contactUsFolder = `${ Init.config.imageFolder }contact-us/`;
Init.config.researchFolder = `${ Init.config.imageFolder }research/`;
Init.config.contactBannerFolder = `${ Init.config.imageFolder }contact-banner/`;
Init.config.faqFolder = `${ Init.config.imageFolder }faq/`;

Init.config.imageLogo = `${Init.config.logoFolder}logo.png`;
Init.config.imageLogoAlt = `${Init.config.logoFolder}logo-white-big.png`;
Init.config.navbarLogo = `${Init.config.logoFolder}logo-white.png`;
Init.config.navbarLogoAlt = `${Init.config.logoFolder}logo.png`;
Init.config.searchIcon = `${Init.config.iconFolder}search-icon.svg`;

Init.config.markerIcon = `${Init.config.iconFolder}marker.png`;
Init.config.browserIcon = `${Init.config.iconFolder}browser.png`;
Init.config.faxIcon = `${Init.config.iconFolder}fax.png`;
Init.config.phoneIcon = `${Init.config.iconFolder}phone.png`;
Init.config.emailIcon = `${Init.config.iconFolder}email.png`;

Init.config.image404 = `${Init.config.imageFolder}404.png`;

Init.config.logogram = {
    first: `${Init.config.logogramFolder}logogram-1.png`,
    second: `${Init.config.logogramFolder}logogram-2.png`,
    third: `${Init.config.logogramFolder}logogram-3.png`,
    fourth: `${Init.config.logogramFolder}logogram-4.png`,
}

Init.config.sectionAssets = {
    header: `${Init.config.imageFolder}header-1.png`,
    whyUs: {
        trustedCreds: `${Init.config.iconFolder}trusted-creds-icon.png`,
        realtimeInvest: `${Init.config.iconFolder}real-time-invest-icon.png`,
        extensiveMarketInsight: `${Init.config.iconFolder}extensive-market-insight-icon.png`,
        image: `${Init.config.imageFolder}advantage-image.png`,
    },
    workMethod: {
        simpleRegister: `${Init.config.iconFolder}simple-icon.png`,
        perfectChoice: `${Init.config.iconFolder}coins-icon.png`,
        investment: `${Init.config.iconFolder}investment-icon.png`,
    },
    supporter: {
        ojk: `${Init.config.supporterFolder}ojk.png`,
        idx: `${Init.config.supporterFolder}idx.png`,
        idclear: `${Init.config.supporterFolder}idclear.png`,
        ksei: `${Init.config.supporterFolder}ksei.png`,
        ojkTransparent: `${Init.config.supporterFolder}ojk-transparent.png`,
        idxTransparent: `${Init.config.supporterFolder}idx-transparent.png`,
        idclearTransparent: `${Init.config.supporterFolder}idclear-transparent.png`,
        kseiTransparent: `${Init.config.supporterFolder}ksei-transparent.png`,
        sipfTransparent: `${Init.config.supporterFolder}sipf-transparent.png`,
    },
    exploration: {
        image: `${Init.config.imageFolder}exploration-image.png`,
    },
    winvest: {
        mobileApp: `${ Init.config.imageFolder }mobile-app.png`,
        desktopPc: `${ Init.config.imageFolder }desktop.png`,
        laptop: `${ Init.config.imageFolder }laptop.png`,

        background: `${Init.config.winvestFolder}winvest-bg.png`,
        background2: `${Init.config.winvestFolder}winvest-bg-3.png`,
        background3: `${Init.config.winvestFolder}winvest-web-bg.png`,
        background4: `${Init.config.winvestFolder}winvest-bg-4.png`,
        background5: `${Init.config.winvestFolder}winvest-bg-5.png`,
        backgroundMobile: `${Init.config.winvestFolder}winvest-bg-mobile.png`,
        background2Mobile: `${Init.config.winvestFolder}winvest-bg-3-mobile.png`,
        background4Mobile: `${Init.config.winvestFolder}winvest-bg-4-mobile.png`,
        background5Mobile: `${Init.config.winvestFolder}winvest-bg-5-mobile.png`,
        googlePlay: `${Init.config.winvestFolder}download-google-play.png`,
        appStore: `${Init.config.winvestFolder}download-app-store.png`,
        mobile: `${Init.config.winvestFolder}winvest-mobile.png`,
        mobileIcon: `${ Init.config.iconFolder }winvest-mobile-icon.png`,
        commitmentComplete: `${ Init.config.iconFolder }commitment-1.png`,
        commitmentAgile: `${ Init.config.iconFolder }commitment-2.png`,
        commitmentTrusted: `${ Init.config.iconFolder }commitment-3.png`,
        advantage1: `${Init.config.winvestFolder}mobile-advantage-1.png`,
        advantage2: `${Init.config.winvestFolder}mobile-advantage-2.png`,
        advantage3: `${Init.config.winvestFolder}mobile-advantage-3.png`,
        advantage4: `${Init.config.winvestFolder}mobile-advantage-4.png`,
        advantage5: `${Init.config.winvestFolder}mobile-advantage-5.png`,
        winvestBgLogo: `${Init.config.winvestFolder}winvest-logo.png`,
        mobileFaqIcon: `${ Init.config.winvestFolder }winvest-mobile-faq.png`,
        transparentLogo: `${ Init.config.winvestFolder }winvest-transparent-logo.png`,
        macos: `${Init.config.winvestFolder}download-macos.png`,
        windows: `${Init.config.winvestFolder}download-windows.png`,
        chart: `${Init.config.winvestFolder}winvest-chart.png`,
        desktop: `${Init.config.winvestFolder}winvest-desktop-header-image.png`,
        desktopIcon: `${Init.config.iconFolder}winvest-desktop-icon.png`,
        desktopFaqIcon: `${ Init.config.winvestFolder }winvest-desktop-faq.png`,
        desktopFeatureImage: `${Init.config.winvestFolder}winvest-feature-image.png`,
        web: `${Init.config.winvestFolder}winvest-web-image.png`,
        webIcon: `${Init.config.iconFolder}winvest-web-icon.png`,
        webAdvantage1: `${ Init.config.iconFolder }winvest-instant-access-icon.png`,
        webAdvantage2: `${ Init.config.iconFolder }winvest-compatibility-icon.png`,
        webAdvantage3: `${ Init.config.iconFolder }winvest-analyze-icon.png`,
        desktopFeature1: `${ Init.config.winvestFolder }desktop-feature-icon-1.png`,
        desktopFeature2: `${ Init.config.winvestFolder }desktop-feature-icon-2.png`,
        desktopFeature3: `${ Init.config.winvestFolder }desktop-feature-icon-3.png`,
    },
    aboutUs: {
        background: `${Init.config.aboutUsFolder}header-bg.png`,
        image: `${Init.config.aboutUsFolder}about-us-image.png`,
        visionIcon: `${Init.config.iconFolder}vision-icon.png`,
        missionIcon: `${Init.config.iconFolder}mission-icon.png`,
        background2: `${Init.config.aboutUsFolder}about-us-header.png`,
        structure: `${Init.config.aboutUsFolder}organization-structure.png`,
        building: `${Init.config.aboutUsFolder}waterfront-building.png`,
    },
    teams: {
        sample: `${Init.config.teamsFolder}photo-sample.png`
    },
    repo: {
        background: `${Init.config.repoFolder}repo-header-bg.png`,
        goal: `${Init.config.repoFolder}goals.png`,
        risk1: `${Init.config.repoFolder}risk-1.png`,
        risk2: `${Init.config.repoFolder}risk-2.png`,
        risk3: `${Init.config.repoFolder}risk-3.png`,
        role1: `${Init.config.iconFolder}role-1.png`,
        role2: `${Init.config.iconFolder}role-2.png`,
        role3: `${Init.config.iconFolder}role-3.png`,
    },
    marginFacility: {
        background: `${Init.config.marginFacilityFolder}margin-facility-header-bg.png`,
        advantage: `${Init.config.marginFacilityFolder}advantage.png`,
        target1: `${Init.config.marginFacilityFolder}target-1.png`,
        target2: `${Init.config.marginFacilityFolder}target-2.png`,
        target3: `${Init.config.marginFacilityFolder}target-3.png`,
        coins: `${Init.config.iconFolder}coins.png`
    },
    investmentBanking: {
        background: `${Init.config.investmentBankingFolder}investment-banking-header-bg.png`,
        advantage1: `${Init.config.investmentBankingFolder}advantage-1.png`,
        advantage2: `${Init.config.investmentBankingFolder}advantage-2.png`,
        advantage3: `${Init.config.investmentBankingFolder}advantage-3.png`,
        advantage4: `${Init.config.investmentBankingFolder}advantage-4.png`,
        advantage5: `${Init.config.investmentBankingFolder}advantage-5.png`,
        advantage6: `${Init.config.investmentBankingFolder}advantage-6.png`,
        advantage7: `${Init.config.investmentBankingFolder}advantage-7.png`,
    },
    contactUs: {
        background: `${Init.config.contactUsFolder}contact-us-header-bg.png`,
        bannerBg: `${Init.config.contactBannerFolder}contact-banner-bg.png`,
    },
    research: {
        dummy: `${Init.config.researchFolder}research-dummy-image.png`,
        download: `${Init.config.iconFolder}download.png`,
        filter: `${Init.config.iconFolder}slider.png`,
        filterCheck: `${Init.config.iconFolder}slider-check.png`,
    },
    faq: {
        background: `${Init.config.faqFolder}faq-header-bg.png`,
    },
    collaborator: {
        // Fill collaborator logos here
    },
    client: {
        // Fill client logos here
    }
}

Init.config.imageIcon = `${ Init.config.iconFolder }favicon.png`;

// Extensions
library.add(fab, fas, far);
Init.FontAwesomeIcon = FontAwesomeIcon;


export default Init;