import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './Exploration.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const Exploration = (props) => {
    const { FontAwesomeIcon } = Init;
    const { exploration } = Init.config.sectionAssets;
    const tags = [
        "Saham",
        "E-IPO",
        "ETF",
        "Reksa Dana",
        "Obligasi"
    ];

    return (
        <div 
            id="exploration" 
            className="exploration"
        >
            <div className="exploration-section">
                <div className="exploration-header-container">
                    <h2 className='exploration-title'>
                        Berinvestasi Saham itu Mudah;
                        <br />
                        Mulai dengan Winvest
                    </h2>
                </div>
                <div className="exploration-tags">
                    {
                        tags.map( ( item, index ) => {
                            return (
                                <div key={index} className="tag-item">
                                    { item }
                                </div>
                            )
                        } )
                    }
                </div>
                <p className="exploration-desc">
                    Mulai perjalanan menuju pertumbuhan aset anda bersama Winvest, maksimalkan eksplorasi 900+ saham perusahaan dan raih setiap tujuan finansialmu!
                </p>
                <PrimaryButton 
                    text="Pelajari lebih lanjut"
                    customClass="exploration-btn"
                    href="/"
                    customIcon={<FontAwesomeIcon icon={['fas', 'arrow-right']} />}
                    iconPosition="right"
                />
            </div>
            <img src={exploration.image} alt="" />
        </div>
    );
}



export default Exploration;