import React, {useCallback, useEffect, useRef, useState} from 'react';
import Init from '../../../Init/Init';
import './ResearchList.scss';
import { researchList } from '../../../Data/Research';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';
import { debounce, throttle } from '../../../Util/Util';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

const { FontAwesomeIcon } = Init;

const ResearchList = (props) => {
    const { sectionAssets } = Init.config;

    const [ research, setResearch ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const searchTimer = useRef(null);

    useEffect( () => {
        refreshResearch();
        setIsLoading(true);
    }, [props.category, props.year, props.sort, props.keyword]);

    // useEffect( () => {
    //     console.log(`Is Loading: ${ isLoading }`);
    //     if( isLoading ) {
    //     }
    // }, [isLoading]);

    const renderResearch = async () => {
        const response = await getResearch();
        console.log(response);

        setResearch([
            ...response
        ]);
        setIsLoading(false);
    };

    const refreshResearch = () => {
        if( searchTimer.current ) {
            clearTimeout(searchTimer.current);
        }

        searchTimer.current = setTimeout( renderResearch, 3000 );
    }

    const getResearch = async () => {
        const response = await researchList(props.category, props.year, props.sort, props.keyword);
        console.log("Called API!");
        if( response.errorCode === 0 ) {
            return response.data;
        }
    }

    return (
        <div className="research-list">
            <div className="research-list-wrapper">
                <div className="research-list-items">
                    {
                        !isLoading ? (
                            research.map((v, k) => {
                                return (
                                    <div key={k} className="research-list-item">
                                        <div className="research-list-item-content">
                                            <h3 className="research-list-item-title">
                                                { v.research_name }
                                            </h3>
                                            <p className="research-list-item-datetime">
                                                { v.created_at }
                                            </p>
                                            <p className="research-list-item-uploader">
                                                {/* { v.uploaded_by } */}
                                                Waterfront Research Team
                                            </p>
                                        </div>
                                        <div className="research-list-item-download-btn-container">
                                            <PrimaryButton
                                                text="Unduh"
                                                customClass={"research-list-item-download-btn"}
                                                // customIcon={<FontAwesomeIcon icon={['fas', 'download']} />}
                                                customIcon={ <img src={ sectionAssets.research.download } /> }
                                                iconPosition={"right"}
                                                href={ v.research_file }
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        ) : <LoadingPage />
                    }
                </div>
            </div>
        </div>
    );
}

export default ResearchList;