import React, {Component} from 'react';
import './ResearchTitleHeader.scss';

import Init from '../../../Init/Init';
import { Link } from 'react-router-dom';

const { FontAwesomeIcon } = Init;

class ResearchTitleHeader extends Component {
    constructor(props) {
        super(props);
        
        this.config = Init.config;
        this.state = {
            
        }

    }

    resizeEvent(event) {}

    scrollEvent(event) {}

    componentDidMount() {
        window.addEventListener('resize', this.resizeEvent);
        window.addEventListener('scroll', this.scrollEvent);

        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
     }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEvent);
        window.removeEventListener('scroll', this.scrollEvent);
    }

    render() {
        return (
            <div className="research-title-header">
                {/* 
                    1. Title
                    2. See all
                */}
                <h2 className='research-title-header-title'>
                    Riset Pasar Keuangan dan Ekonomi
                </h2>
                <Link to="/research" className="research-title-header-button">
                    Lihat Semua <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                </Link>
            </div>
        );
    }
}

export default ResearchTitleHeader;