import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './WinvestWebAdvantage.scss';

class WinvestWebAdvantage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workingMethod: [
                {
                    image: Init.config.sectionAssets.winvest.commitmentAgile,
                    title: "Akses Instan",
                    desc: "Kelola investasi langsung dari browser tanpa instal aplikasi. Mudah, cepat, dan praktis!"
                },
                {
                    image: Init.config.sectionAssets.winvest.commitmentComplete,
                    title: "Kompatibilitas Lintas Perangkat",
                    desc: "Kelola portfolio dengan mudah di desktop, tablet, atau smartphone."
                },
                {
                    image: Init.config.sectionAssets.winvest.webAdvantage3,
                    title: "Berbagai Alat Analisis Teknikal",
                    desc: "Grafik, indikator teknis, dan data historis tersedia di browser untuk analisis saham yang cepat dan akurat."
                },
            ]
        }
    }

    render() {

        return (
            <div className="winvest-web-advantage">
                {/* 
                    1. Title
                    2. 3 Items: Buat akun dengan mudah, pilih produk investasi yang tepat, dan kembangkan investasi anda
                */}
                <h2 className="winvest-web-advantage-title">
                    Memaksimalkan Potensi Investasi Anda dengan Teknologi Terkini!
                </h2>
                <div className="winvest-web-advantage-items">
                    {
                        this.state.workingMethod.map((item, index) => {
                            return (
                                <div key={ index } className="winvest-web-advantage-item">
                                    {/* 
                                        1. Image
                                        2. Text Content: Title, Desc
                                    */}
                                    <img src={ item.image } alt="" />
                                    <div className="winvest-web-advantage-item-content">
                                        <h4 className="winvest-web-advantage-item-title">
                                            { item.title }
                                        </h4>
                                        <p className="winvest-web-advantage-item-desc">
                                            { item.desc }
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default WinvestWebAdvantage;