import React, {Component} from 'react';
import './WinvestMobilePage.scss';

import Init from '../../Init/Init';

import ReactGA from 'react-ga4';
import Winvest from '../../Component/PagesComponent/Winvest/Winvest';
import WinvestMobileHeader from '../../Component/PagesComponent/WinvestMobileHeader/WinvestMobileHeader';
import WinvestMobileCommitment from '../../Component/PagesComponent/WinvestMobileCommitment/WinvestMobileCommitment';
import WinvestMobileAdvantages from '../../Component/PagesComponent/WinvestMobileAdvantage/WinvestMobileAdvantage';
import WinvestMobileFAQ from '../../Component/PagesComponent/WinvestMobileFAQ/WinvestMobileFAQ';

class WinvestMobilePage extends Component {
    constructor(props) {
        super(props);
        
        this.config = Init.config;
        this.state = {
            // downloadBackground: window.screen.width > 950 ? `${this.config.sectionAssets.winvest.background2}` : `${this.config.sectionAssets.winvest.background2Mobile}`
            downloadBackground: `${ this.config.sectionAssets.winvest.background2 }`,
            downloadImage: `${ this.config.sectionAssets.winvest.mobileApp }`
        }
    }

    componentDidMount() {
        window.addEventListener('resize', (event) => {
            // console.log(this.screen.width);
            // const screenWidth = event.target.innerWidth;

            // if( screenWidth > 950 ) {
            //     if( this.state.downloadBackground !== this.config.sectionAssets.winvest.background2 ) {
            //         this.setState({
            //             ...this.state,
            //             downloadBackground: `${this.config.sectionAssets.winvest.background2}`
            //         });
            //     }
            // } else {
            //     if( this.state.downloadBackground !== this.config.sectionAssets.winvest.background2Mobile ) {
            //         this.setState({
            //             ...this.state,
            //             downloadBackground: `${this.config.sectionAssets.winvest.background2Mobile}`
            //         });
            //     }
            // }
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', null);
    }

    render() {
        return (
            <div className="winvest-mobile-page">
                {/* 
                    1. WinvestMobileHeader
                    2. WinvestMobileCommitment
                    3. WinvestMobileAdvantage
                    4. WinvestMobileFAQ
                    5. Winvest
                */}
                <WinvestMobileHeader />
                <WinvestMobileCommitment />
                <WinvestMobileAdvantages />
                <WinvestMobileFAQ />
                <Winvest 
                    customBg={ this.state.downloadBackground } 
                    customImage={ this.state.downloadImage }
                    title={"Download Winvest Mobile"}
                    desc={`
                        Bersama Winvest raih kemenangan di setiap tujuan finansialmu! 
                        <br />
                        Jangan sampai kelewatan.
                    `}
                    showGooglePlay={true} 
                    showAppStore={true}
                />
            </div>
        );
    }
}

export default WinvestMobilePage;