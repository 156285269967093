import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './Teams.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const Teams = (props) => {
    const { teams } = Init.config.sectionAssets;
    const showPhoto = false;
    /**
     * 1. C-Level 1
     * 2. C-Level 2
     */
    const teamMember1 = [
        {
            image: `${teams.sample}`,
            name: 'Herman Gunadi',
            title: 'President Commissioner/Independent Commissioner',
            desc: 'Saat ini menjabat sebagai Komisaris Independen di PT Waterfront Sekuritas Indonesia dan masih menjabat sebagai Direktur Independen / Sekretaris Perusahaan di PT Surya Semesta Internusa Tbk. Sebelumnya, beliau juga pernah bekerja di beberapa perusahaan sekuritas, terakhir sebagaiDirektur Utama di PT Mahakarya Artha Sekuritas.',
            reverse: false
        },
        {
            image: `${teams.sample}`,
            name: 'Bambang Susanto',
            title: 'President Director',
            desc: 'Bambang Susanto adalah Direktur Utama PT Waterfront Sekuritas Indonesia. Ia meraih gelarsarjana dari Fakultas Ekonomi Universitas Trisakti dengan jurusan Akuntansi. Sebelum bergabung dengan PT Waterfront Sekuritas Indonesia, ia menjabat sebagai Direktur Utama PT Lautandhana Securindo dari tahun 2003 hingga 2008, Direktur di PT Trimegah Securities daritahun 1997 hingga 2003, dan Direktur Utama PT Bomar Securities daritahun 1990 hingga 1996. Ia juga merupakan anggota beberapa komite di Bursa Efek Indonesia.',
            reverse: true,
        }
    ];
    const teamMember2 = [
        {
            image: `${teams.sample}`,
            name: 'Binawati Junaedi',
            title: 'Director of Operations',
            desc: 'Binawati Junaedi adalah Direktur Operasional PT Waterfront Sekuritas Indonesia. Ia lulus dari Universitas Tarumanegara dengan jurusan Akuntansi. Sebelum bergabung dengan PT Waterfront Sekuritas Indonesia, ia pernah menjabat sebagai Direktur Utama PT Danpac Securities daritahun 2001 hingga 2008, Direktur PT Mahakarya Artha Sekuritas dari tahun 2000 hingga 2001, dan Direktur PT Asjaya Indosurya Securities daritahun 1991 hingga 2000.',
            reverse: false,
        },
        {
            image: `${teams.sample}`,
            name: 'Gunawan Susanto',
            title: 'Director of Equity',
            desc: 'Gunawan Susanto adalah Direktur Ekuitas PT Waterfront Sekuritas Indonesia. Ia lulus dari Universitas Borobudur dengan jurusan Akuntansi. Sebelum bergabung dengan PT Waterfront Sekuritas Indonesia, ia pernah menjabat sebagai Direktur Utama PT Interasia Sekuritindo dari tahun 2001 hingga 2008.',
            reverse: false,
        },
    ];
    const teamMember3 = [
        {
            image: `${teams.sample}`,
            name: 'Rian Putra',
            title: 'Vice President of Business and Technology',
            desc: 'Rian Putra Utama adalah VP of Business and Technology di PT Waterfront Sekuritas Indonesia. Ia meraih gelar sarjana dari Universitas Trisakti dan menyelesaikan gelar magister manajemennya dari Universitas Prasetiya Mulya. Ia mendirikan perusahaan media bernama WinNews dan juga ikut mendirikan startup finansial bernama StockPeek (keluar dari jajaran investor dan direksi pada tahun 2021). Ia juga perna hmenjadi dosen di Universitas Indonesia, mengajar di bidang Produksi Media (sekarang dalam cuti sabatikal). Saatini, ia bertanggung jawab mengembangkan platform trading online berbasis AI yang bernama Winvest oleh Waterfront Sekuritas.',
            reverse: true,
        },
    ];

    const teamMembers = [
        teamMember1,
        teamMember2,
        // teamMember3
    ];

    return (
        <div 
            id="teams" 
            className="teams"
        >
            <div className="teams-header-container">
                <h2 className='teams-title'>
                    Management
                </h2>
            </div>
            <div className="teams-section">
                {
                    teamMembers.map((teamMember, index) => {
                        return (
                            <>
                                {
                                    index !== 0 ? (<hr />) : ""
                                }
                                <div className="team-items">
                                {
                                    teamMember.map( (member, i) => {
                                        return (
                                            <div className={`team-item ${ member.reverse ? "reversed" : "" }`}>
                                                {
                                                    showPhoto ? (
                                                        <div className="team-item-photo">
                                                            <img src={member.image} alt="" className="team-item-photo" />
                                                        </div>
                                                    ) : ""
                                                }
                                                <div className="team-item-content">
                                                    <div className="team-item-identity">
                                                        <h3 className="team-item-name">
                                                            { member.name }
                                                        </h3>
                                                        <p className="team-item-title">
                                                            { member.title }
                                                        </p>
                                                    </div>
                                                    <p className="team-item-desc">
                                                        { member.desc }
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    } )
                                }
                                </div>
                            </>
                        );
                    })
                }
            </div>
        </div>
    );
}



export default Teams;