import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './AboutUsHeader.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const AboutUsHeader = (props) => {
    const { aboutUs } = Init.config.sectionAssets;

    return (
        <div 
            id="about-us-header" 
            className="about-us-header"
            style={{
                backgroundImage: `url(${ aboutUs.background })`,
                backgroundSize: 'cover',
                backgroundPosition: 'left'
            }}
        >
            <div className="about-us-image">
                <img src={aboutUs.building} alt="" />
            </div>
            <div className="about-us-header-section">
                <div className="about-us-header-header-container">
                    {/* Need partition to adjust breakline */}
                    <h2 className='about-us-header-title'>
                        <span>
                            Berdiri sejak 
                        </span> <span>
                            tahun 1990, 
                        </span> <span>
                            PT. Waterfront Sekuritas 
                        </span> <span>
                            Indonesia telah menjadi mitra 
                        </span> <span>
                            investasi terpercaya bagi seluruh kliennya.
                        </span>
                    </h2>
                </div>
            </div>
        </div>
    );
}



export default AboutUsHeader;