import React, {Component} from 'react';
import './MarginFacilityPage.scss';

import Init from '../../Init/Init';

import ReactGA from 'react-ga4';
import ContactBanner from '../../Component/PagesComponent/ContactBanner/ContactBanner';
import MarginFacilityHeader from '../../Component/PagesComponent/MarginFacilityHeader/MarginFacilityHeader';
import MarginFacilityAdvantage from '../../Component/PagesComponent/MarginFacilityAdvantage/MarginFacilityAdvantage';
import MarginFacilityUsage from '../../Component/PagesComponent/MarginFacilityUsage/MarginFacilityUsage';
import MarginFacilityTarget from '../../Component/PagesComponent/MarginFacilityTarget/MarginFacilityTarget';

class MarginFacilityPage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    componentDidMount() {

    }
    
    componentWillUnmount() {
    
    }

    render() {
        return (
            <div className="margin-facility-page">
                {/* 
                    1. MarginFacilityHeader
                    2. MarginFacilityAdvantage
                    3. MarginFacilityTarget
                    4. MarginFacilityUsage
                    5. ContactBanner
                */}
                <MarginFacilityHeader />
                <MarginFacilityAdvantage />
                <MarginFacilityTarget />
                {/* <MarginFacilityUsage /> */}
                <ContactBanner />
            </div>
        );
    }
}

export default MarginFacilityPage;