import React, {Component} from 'react';
import './ContactHeader.scss';
import Init from '../../../Init/Init';

const ContactHeader = (props) => {
    const { contactUs } = Init.config.sectionAssets;

    return (
        <div 
            className="contact-header"
            style={{
                backgroundImage: `url(${ contactUs.background })`,
                backgroundPosition: `center`,
                backgroundSize: `cover`,
            }}
        >
           <h1 className="contact-header-title">
                Kontak Kami
           </h1>
           <p className="contact-header-subtitle">
                Waterfront siap menyediakan solusi yang dirancang untuk memenuhi kebutuhan Anda.
           </p>
        </div>
    );
}

export default ContactHeader;