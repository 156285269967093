import React, {Component} from 'react';
import './Homepage.scss';

import Init from '../../Init/Init';

import Header from '../../Component/PagesComponent/Header/Header';
// import Identity from '../../Component/PagesComponent/Identity/Identity';
// import ComingSoon from '../../Component/PagesComponent/ComingSoon/ComingSoon';
import Advantages from '../../Component/PagesComponent/Advantages/Advantages';
import FAQ from '../../Component/PagesComponent/FAQ/FAQ';
import Articles from '../../Component/PagesComponent/Articles/Articles';
import TestimonySection from '../../Component/PagesComponent/Testimony/Testimony';

import ReactGA from 'react-ga4';
import Collaborator from '../../Component/PagesComponent/Collaborator/Collaborator';
import Client from '../../Component/PagesComponent/Client/Client';
import ComingSoon from '../../Component/PagesComponent/ComingSoon/ComingSoon';
import Supporter from '../../Component/PagesComponent/Supporter/Supporter';
import WorkingMethod from '../../Component/PagesComponent/WorkingMethod/WorkingMethod';
import Products from '../../Component/PagesComponent/Products/Products';
import Exploration from '../../Component/PagesComponent/Exploration/Exploration';
import Winvest from '../../Component/PagesComponent/Winvest/Winvest';
import Research from '../../Component/PagesComponent/Research/Research';

class Homepage extends Component {
    constructor(props) {
        super(props);
        
        this.config = Init.config;
        this.state = {
            // downloadBackground: window.screen.width > 950 ? `${this.config.sectionAssets.winvest.background}` : `${this.config.sectionAssets.winvest.backgroundMobile}`
            downloadBackground: `${ this.config.sectionAssets.winvest.background }`,
            downloadImage: `${ this.config.sectionAssets.winvest.mobileApp }`,
            researchDataLimit: window.screen.width > 576 ? 3 : 2
        }

    }

    scrollEvent(event) {
        // const sectionList = {
        //     header: {
        //         id: 'header',
        //         name: 'Header',
        //         min: document.getElementById('header').offsetTop,
        //         max: document.getElementById('header').offsetTop + document.getElementById('header').clientHeight
        //     },
        //     // background: {
        //     //   id: 'background',
        //     //   name: 'Latar Belakang',
        //     //   min: document.getElementById('background').offsetTop,
        //     //   max:  document.getElementById('background').offsetTop + document.getElementById('advantage').clientHeight
        //     // },
        //     // advantages: {
        //     //   id: 'advantage',
        //     //   name: 'Kenapa Waterfront Sekuritas?',
        //     //   min: document.getElementById('advantage').offsetTop,
        //     //   max:  document.getElementById('advantage').offsetTop + document.getElementById('advantage').clientHeight
        //     // },
        //     // individualPricing: {
        //     //   id: 'individual-pricing',
        //     //   name: 'Layanan',
        //     //   min: document.getElementById('individual-pricing').offsetTop,
        //     //   max:  document.getElementById('individual-pricing').offsetTop + document.getElementById('individual-pricing').offsetHeight
        //     // },
        //     // corporatePricing: {
        //     //   id: 'b2b-section',
        //     //   name: 'Waterfront Sekuritas Bisnis',
        //     //   min: document.getElementById('b2b-section').offsetTop,
        //     //   max:  document.getElementById('b2b-section').offsetTop + document.getElementById('b2b-section').offsetHeight
        //     // },
        //     // faq: {
        //     //   id: 'faq',
        //     //   name: 'FAQ',
        //     //   min: document.getElementById('faq').offsetTop,
        //     //   max:  document.getElementById('faq').offsetTop + document.getElementById('faq').offsetHeight
        //     // },
        //     // articles: {
        //     //   id: 'article',
        //     //   name: 'Artikel',
        //     //   min: document.getElementById('article').offsetTop,
        //     //   max:  document.getElementById('article').offsetTop + document.getElementById('article').offsetHeight
        //     // },
        //     footer: {
        //       id: 'contact',
        //       name: 'Footer Contact',
        //       min: document.getElementById('contact').offsetTop,
        //       max:  document.getElementById('contact').offsetTop + document.getElementById('contact').offsetHeight
        //     }
        // };
        
        // const scrollState = window.scrollY;
        
        // for(let key in sectionList) {
        //     let item = sectionList[key];

        //     if(scrollState > item.min && scrollState < item.max) {
        //         ReactGA.send({
        //             hitType: "scroll",
        //             page: `#${item.id}`,
        //             title: item.name
        //         });

        //         break;
        //     }

        // }
    }

    componentDidMount() {
        window.addEventListener('resize', (event) => {
            // const screenWidth = event.target.innerWidth;

            // if( screenWidth > 950 ) {
            //     if( this.state.downloadBackground !== this.config.sectionAssets.winvest.background ) {
            //         this.setState({
            //             ...this.state,
            //             downloadBackground: `${this.config.sectionAssets.winvest.background}`
            //         });
            //     }
            // } else {
            //     if( this.state.downloadBackground !== this.config.sectionAssets.winvest.backgroundMobile ) {
            //         this.setState({
            //             ...this.state,
            //             downloadBackground: `${this.config.sectionAssets.winvest.backgroundMobile}`
            //         });
            //     }
            // }
        });
        window.addEventListener('scroll', this.scrollEvent);

        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
     }
    
    componentWillUnmount() {
        window.removeEventListener('resize', function(){});
        window.removeEventListener('scroll', this.scrollEvent);
    }

    render() {
        return (
            <div className="homepage">
                <Header />
                {/* <Supporter /> */}
                <Advantages />
                <WorkingMethod />
                <Products />
                <Exploration />
                <Research limit={this.state.researchDataLimit} />
                <Winvest 
                    customBg={ this.state.downloadBackground } 
                    customImage={ this.state.downloadImage }
                    showGooglePlay={true} 
                    showAppStore={true}
                />
                {/* <ComingSoon title="Artikel"/> */}
                {/* <Client />
                <Collaborator />
                <FAQ />
                <TestimonySection />
                <Articles /> */}
            </div>
        );
    }
}

export default Homepage;