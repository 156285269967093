import React, {Component, useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Accordion, Row, Col } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './WinvestDesktopFAQ.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const CollapseBox = (props) => {
    const { boxNumber } = props;

    const gaEventCategory = 'FAQ';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const toggleQuestion = () => {
        if(!props.list.open) {
            gaEventTracker('Toggle Open Question', props.list.question);
        }

        props.onToggle();
    };

    return(
        <Accordion>
            <Accordion.Item eventKey={boxNumber} className="question-item">
                <Accordion.Header className="question-box" onClick={toggleQuestion}>
                    <span className="question-title">
                        {props.list.question}
                    </span>
                    {/* <Init.FontAwesomeIcon icon={['fas', (props.list.open ? 'angle-up' : 'angle-down')]} /> */}
                </Accordion.Header>
                <Accordion.Body className='question-content' dangerouslySetInnerHTML={{__html: props.list.answer}}>
                        {/* {props.list.answer}  */}
                    {/* <Card.Body> 
                    </Card.Body> */}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const WinvestDesktopFAQ = (props) => {
    const { winvest } = Init.config.sectionAssets;

    const [ list, setList ] = useState([
        {
            question: "Bagaimana cara download aplikasi Winvest versi desktop?", 
            answer: "Caranya dengan menekan salah satu tombol download untuk Mac atau Windows sesuai OS yang kamu miliki. Setelah instalasi, kamu dapat login akun Winvest ataupun mendaftarkan sebagai user baru.",
            open: false
        },
        {
            question: "Apakah aplikasi Winvest versi desktop berbayar?", 
            answer: "Akses aplikasi saham Winvest versi desktop bisa digunakan secara gratis. Kamu hanya perlu log-in atau mendaftarkan akun di Stockbit.",
            open: false
        },
        {
            question: "Fitur apa saja yang tersedia di Winvest desktop yang tidak ada di versi mobile?", 
            answer: `Winvest desktop menawarkan beberapa fitur tambahan yang tidak ada di versi mobile, seperti dukungan multi-monitor, grafik interaktif dengan lebih banyak indikator teknis, dan opsi kustomisasi antarmuka. Fitur-fitur ini dirancang untuk membantu pengguna mendapatkan pengalaman trading yang lebih profesional dan efisien.`,
            open: false
        },
        {
            question: "Apakah Winvest desktop memungkinkan notifikasi atau peringatan real-time langsung di layar?", 
            answer: "Ya, Winvest desktop dilengkapi dengan fitur notifikasi real-time, memungkinkan Anda menerima peringatan langsung di layar untuk perubahan harga, berita pasar penting, atau aktivitas akun. Fitur ini memastikan Anda tidak melewatkan peluang trading atau informasi penting, bahkan saat aplikasi berjalan di latar belakang.",
            open: false
        },
    ]);
    const handleToggle = (index) => {
        let listCopy = list;

        listCopy[index].open = !listCopy[index].open;

        setList([
            ...listCopy
        ]);
    };

    return (
        <div id="winvest-desktop-faq" className="winvest-desktop-faq-box">
            <div className="winvest-desktop-faq-header">
                <h2 className='winvest-desktop-faq-title'> Sering Ditanyakan </h2>
            </div>
            <div className="winvest-desktop-faq-container">
                <div className="question-group">
                    {
                        list.map( (item, index) => {
                            return(
                                <CollapseBox key={index} boxNumber={index} list={item} onToggle={ () => handleToggle(index) } />
                            );
                        } )
                    }
                </div>
                <div className="question-image">
                    <img src={ winvest.desktopFaqIcon } alt="" />
                </div>
            </div>
        </div>
    );
}

export default WinvestDesktopFAQ;