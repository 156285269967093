import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './LicensedEmployee.scss';

import { getFormattedDatetime, getTimestamp } from '../../../Util/Util';
import { licensedEmployeeList } from '../../../Data/LicensedEmployee';

class LicensedEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: [
                // { name: "WMI", count: 2 },
                // { name: "WPEE", count: 6 },
                // { name: "WPPE", count: 29 },
                // { name: "WPPE-P", count: 10 },
            ],
            lastUpdated: null
        }
    }

    componentDidMount() {
        licensedEmployeeList().then( (response) => {
            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                let updatedTime = null;

                for( let index in response.data ) {
                    let item = response.data[index];
                    if( item.hasOwnProperty('updated_at') ) {
                        if( updatedTime === null ) {
                            updatedTime = getFormattedDatetime(item.updated_at);
                        }

                        if( getTimestamp(updatedTime) < getTimestamp(item.updated_at) ) {
                            updatedTime = getFormattedDatetime(item.updated_at);
                        }
                    }
                }
                
                this.setState({ 
                    employee: response.data,
                    lastUpdated: getFormattedDatetime(updatedTime, "M Y")
                });
            }
        } );
    }

    render() {
        return (
            <div className="licensed-employee">
                {/* 
                    1. Header
                    2. Subtitle
                    -------------
                    3. Box Counter
                    4. Last Updated
                */}
                <div className="licensed-employee-header-container">
                    <h2 className="licensed-employee-title">
                        Licensed Employees
                    </h2>
                    <p className="licensed-employee-subtitle">
                        Jumlah pegawai yang memiliki sertifikasi pasar modal: Wakil Perantara Pedagang Efek (WPPE), Wakil Penjamin Emisi Efek (WPEE), dan Wakil Manajer Investasi
                    </p>
                </div>

                <div className="licensed-employee-counter-container">
                    {
                        this.state.employee.map((employee, index) => {
                            return (
                                <div className="licensed-employee-counter-box">
                                    <div className="employee-count">
                                        { employee.count }
                                    </div>
                                    <div className="employee-position">
                                        { employee.name }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <span className="licensed-employee-last-updated">
                    Last Updated: { this.state.lastUpdated }
                </span>
            </div>
        );
    }
}

export default LicensedEmployee;