import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './WinvestDesktopFeature.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const WinvestDesktopFeature = (props) => {
    const { winvest } = Init.config.sectionAssets;
    const features = [
        "Personalisasi Halaman Trading",
        "Sistem Keamanan yang Andal",
        "Notifikasi Real-Time",
        "Akses Offline untuk Data Tertentu"
    ];

    return (
        <div 
            id="winvest-desktop-feature" 
            className="winvest-desktop-feature"
        >
            <div className="winvest-desktop-feature-image-container">
                <img src={winvest.desktopFeatureImage} alt="" />
            </div>
            <div className="winvest-desktop-feature-section">
                <h5 className="winvest-desktop-feature-subtitle">
                    Winvest Trading
                </h5>
                <div className="winvest-desktop-feature-header-container">
                    <h2 className='winvest-desktop-feature-title'>
                        Performa Aplikasi Tinggi Untuk Optimalkan Hasil Trading Anda
                    </h2>
                </div>
                <p className="winvest-desktop-feature-desc">
                    Winvest menghadirkan platform trading desktop yang dirancang khusus untuk memberikan Anda kendali penuh atas investasi Anda. Dengan fitur-fitur canggih dan antarmuka yang intuitif, antara lain:
                </p>
                {/* Item List */}
                <ul className='winvest-desktop-feature-items'>
                    {
                        features.map( (item, index) => {
                            return (
                                <li key={index} className="winvest-desktop-feature-item">
                                    { item }
                                </li>
                            );
                        } )
                    }
                </ul>
            </div>
        </div>
    );
}



export default WinvestDesktopFeature;