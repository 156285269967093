import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './WorkingMethod.scss';

class WorkingMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workingMethod: [
                {
                    image: Init.config.sectionAssets.workMethod.simpleRegister,
                    title: "Buka Akun dengan Mudah",
                    desc: "Registrasi cepat dan aman melalui platform digital kami. Mulai berinvestasi dalam hitungan menit dengan proses yang sederhana."
                },
                {
                    image: Init.config.sectionAssets.workMethod.perfectChoice,
                    title: "Pilih Produk Investasi yang Tepat",
                    desc: "Dengan bantuan tim kami, pilih produk investasi yang sesuai dengan profil risiko dan tujuan keuangan Anda."
                },
                {
                    image: Init.config.sectionAssets.workMethod.investment,
                    title: "Pantau dan Kembangkan Investasi Anda",
                    desc: "Gunakan fitur real-time kami untuk memantau perkembangan portofolio Anda kapan saja. Dapatkan laporan berkala untuk mengukur hasil dan mengoptimalkan strategi."
                },
            ]
        }
    }

    render() {

        return (
            <div className="working-method">
                {/* 
                    1. Title
                    2. 3 Items: Buat akun dengan mudah, pilih produk investasi yang tepat, dan kembangkan investasi anda
                */}
                <h2 className="working-method-title">
                    Cara Kerja Kami
                </h2>
                <div className="working-method-items">
                    {
                        this.state.workingMethod.map((item, index) => {
                            return (
                                <div key={ index } className="working-method-item">
                                    {/* 
                                        1. Image
                                        2. Text Content: Title, Desc
                                    */}
                                    <img src={ item.image } alt="" />
                                    <div className="working-method-item-content">
                                        <h4 className="working-method-item-title">
                                            { item.title }
                                        </h4>
                                        <p className="working-method-item-desc">
                                            { item.desc }
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default WorkingMethod;