import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './InvestmentBankingAdvantageTitle.scss';

class InvestmentBankingAdvantageTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <div className="investment-banking-advantage-title">
                <h2 className="investment-banking-advantage-title-title">
                    Layanan Unggulan Investment Banking dari Waterfront Sekuritas
                </h2>
                {/* <p className='investment-banking-advantage-title-content'>
                    Berbekal pengalaman lebih dari 30 tahun di pasar modal, Waterfront Sekuritas menyediakan berbagai pelayanan Investment Banking mulai dari penawaran umum perdana (IPO), penerbitan obligasi, merger & akuisisi, divestasi, underwriting, penerbitan Hak Memesan Efek Terlebih Dahulu (Rights Issue), private placement, hingga layanan penasihat keuangan (financial advisory). Tentunya, semua ini didukung oleh team yang sudah berpengalaman dan dipercaya di industri pasar modal.
                </p> */}
            </div>
        );
    }
}

export default InvestmentBankingAdvantageTitle;