import { combineReducers } from 'redux';

import productReducer from './Product/product-reducer';
import articleReducer from './Article/article-reducer';
import metatagReducer from './MetaTags/metatag-reducer';
// import authReducer from './Auth/auth-reducer';

const rootReducer = combineReducers({
    article: articleReducer,
    metatag: metatagReducer,
    products: productReducer
});

export default rootReducer;