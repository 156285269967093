import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './WinvestMobileCommitment.scss';

class WinvestMobileCommitment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workingMethod: [
                {
                    image: Init.config.sectionAssets.winvest.commitmentComplete,
                    title: "Lengkap",
                    desc: "Dari simple order hingga fast order, platform ini menyediakan semuanya, cocok untuk trader pemula hingga profesional."
                },
                {
                    image: Init.config.sectionAssets.winvest.commitmentAgile,
                    title: "Cepat",
                    desc: "Menggunakan koneksi langsung dengan infrastruktur IT berkecepatan tinggi. Dijamin, momen trading tepat tak akan terlewat."
                },
                {
                    image: Init.config.sectionAssets.winvest.commitmentTrusted,
                    title: "Terpercaya",
                    desc: "Dirancang dengan prioritas pada keamanan dan mitigasi risiko terbaik. Dana Anda aman bersama mitra terpercaya."
                },
            ]
        }
    }

    render() {

        return (
            <div className="winvest-mobile-commitment">
                {/* 
                    1. Title
                    2. 3 Items: Buat akun dengan mudah, pilih produk investasi yang tepat, dan kembangkan investasi anda
                */}
                <h2 className="winvest-mobile-commitment-title">
                    Komitmen Winvest untuk Kemudahan Berinvestasi Anda
                </h2>
                <div className="winvest-mobile-commitment-items">
                    {
                        this.state.workingMethod.map((item, index) => {
                            return (
                                <div key={ index } className="winvest-mobile-commitment-item">
                                    {/* 
                                        1. Image
                                        2. Text Content: Title, Desc
                                    */}
                                    <img src={ item.image } alt="" />
                                    <div className="winvest-mobile-commitment-item-content">
                                        <h4 className="winvest-mobile-commitment-item-title">
                                            { item.title }
                                        </h4>
                                        <p className="winvest-mobile-commitment-item-desc">
                                            { item.desc }
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default WinvestMobileCommitment;