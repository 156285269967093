import React, {Component, useState, useEffect, useRef, useCallback} from 'react';
import { useLocation } from 'react-router-dom';

import { Card, Accordion, Row, Col } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './FAQ.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';
import { faqRender } from '../../../Data/FAQ';
import { debounce } from '../../../Util/Util';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

const CollapseBox = (props) => {
    const { boxNumber } = props;

    const gaEventCategory = 'FAQ';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const toggleQuestion = () => {
        if(!props.list.open) {
            gaEventTracker('Toggle Open Question', props.list.question);
        }

        props.onToggle();
    };

    return(
        <Accordion>
            <Accordion.Item eventKey={boxNumber} className="question-item">
                <Accordion.Header className="question-box" onClick={toggleQuestion}>
                    <span className="question-title">
                        {props.list.question}
                    </span>
                    {/* <Init.FontAwesomeIcon icon={['fas', (props.list.open ? 'angle-up' : 'angle-down')]} /> */}
                </Accordion.Header>
                <Accordion.Body className='question-content' dangerouslySetInnerHTML={{__html: props.list.answer}}>
                        {/* {props.list.answer}  */}
                    {/* <Card.Body> 
                    </Card.Body> */}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

const FAQ = (props) => {
    const [ list, setList ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const searchTimer = useRef();

    useEffect( () => {
        refreshFAQ();
        setIsLoading(true);
    }, [props.category, props.keyword]);

    // useEffect( () => {
    //     console.log(`Is Loading: ${ isLoading }`);
    //     if( isLoading ) {
    //         refreshFAQ();
    //     }
    // }, [isLoading]);

    // const refreshFAQ = useCallback( debounce(
    //     async () => {
    //         const response = await faqRender(props.category, props.keyword);
    
    //         if( response.errorCode === 0 ) {
    //             setList([
    //                 ...response.data
    //             ]);
    //         } else {
    //             setList([]);
    //         }
    //         setIsLoading(false);
    //     }
    // ), [props.category, props.keyword]);

    const refreshFAQ = () => {
        if( searchTimer.current ) {
            clearTimeout(searchTimer.current);
        }

        searchTimer.current = setTimeout( async () => {
            const response = await faqRender(props.category, props.keyword);

            if( response.errorCode === 0 ) {
                setList([
                    ...response.data
                ]);
            } else {
                setList([]);
            }
            setIsLoading(false);
        }, 3000 );
    }

    const handleToggle = (index) => {
        let listCopy = list;

        listCopy[index].open = !listCopy[index].open;

        setList([
            ...listCopy
        ]);
    };

    return (
        <div id="faq" className="faq-box">
            <div className="faq-box-header">
                <h2 className='faq-title'> FAQ </h2>
            </div>
            {
                isLoading ? (
                    <LoadingPage customClass={"faq-loading-container"} color={`blue`} />
                ) : (
                    <>
                        <div className="question-group">
                            {
                                list.map( (item, index) => {
                                    return(
                                        <CollapseBox key={index} boxNumber={index} list={item} onToggle={ () => handleToggle(index) } />
                                    );
                                } )
                            }
                        </div>
                    </>
                )
            }
        </div>
    );
}

FAQ.defaultProps = {
    category: 1,
    keyword: '',
};

export default FAQ;