import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './MarginFacilityAdvantage.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const MarginFacilityAdvantage = (props) => {
    const { marginFacility } = Init.config.sectionAssets;
    const advantage = [
        {
            title: "Akses Lebih Besar ke Modal",
            desc: "Tingkatkan daya beli Anda tanpa harus menambah modal baru."
        },
        {
            title: "Leverage Fleksibel",
            desc: "Sesuaikan leverage sesuai dengan strategi investasi dan toleransi risiko Anda."
        },
        {
            title: "Proses Cepat & Mudah",
            desc: "Aktivasi cepat melalui akun Anda, sehingga Anda dapat langsung memanfaatkan peluang di pasar."
        },
        {
            title: "Monitoring Risiko Real-Time",
            desc: "Sistem pemantauan real-time memastikan Anda selalu mengontrol posisi dan risiko portfolio."
        }
    ];

    return (
        <div 
            id="margin-facility-advantage" 
            className="margin-facility-advantage"
        >
            <img src={marginFacility.advantage} alt="" />
            <div className="margin-facility-advantage-section">
                <div className="margin-facility-advantage-header-container">
                    <h2 className='margin-facility-advantage-title'>
                        Maksimalkan Potensi Investasi 
                        <br />
                        Anda dengan Margin Facility Kami
                    </h2>
                </div>
                {/* Item List */}
                <ul className='margin-facility-advantage-items'>
                    {
                        advantage.map( (item, index) => {
                            return (
                                <>
                                    <li 
                                        className="margin-facility-advantage-item"
                                        style={{
                                            listStyleImage: `url(${ marginFacility.coins })`,
                                        }}
                                    >
                                        { item.title }
                                        <p>
                                            { item.desc }
                                        </p>
                                    </li>
                                </>
                            );
                        } )
                    }
                </ul>
            </div>
        </div>
    );
}



export default MarginFacilityAdvantage;