import Init from '../Init/Init';
import { getFormattedDatetime, responseFormat } from '../Util/Util';
import api from './API';

const pathList = {
    categoryList: '/research-category/',
    categoryGet: '/research-category/:param',
    list: '/research/',
    get: '/research/:param',
}

const researchDummyImage = Init.config.sectionAssets.research.dummy;

const researchCategory = {
    1: {
        id: 1,
        name: "Daily Report",
        image: researchDummyImage
    },
    2: {
        id: 2,
        name: "Weekly Report",
        image: researchDummyImage
    },
    3: {
        id: 3,
        name: "Monthly Report",
        image: researchDummyImage
    },
    4: {
        id: 4,
        name: "Sectoral Report",
        image: researchDummyImage
    },
    5: {
        id: 5,
        name: "Annual Report",
        image: researchDummyImage
    },
    6: {
        id: 6,
        name: "Company Highlight",
        image: researchDummyImage
    },
};

const research = [
    { id: 1, category_id: 1, name: "Daily Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 2, category_id: 1, name: "Daily Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 3, category_id: 1, name: "Daily Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 4, category_id: 1, name: "Daily Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 5, category_id: 1, name: "Daily Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 6, category_id: 1, name: "Daily Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    
    { id: 7, category_id: 2, name: "Weekly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 8, category_id: 2, name: "Weekly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 9, category_id: 2, name: "Weekly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 10, category_id: 2, name: "Weekly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 11, category_id: 2, name: "Weekly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 12, category_id: 2, name: "Weekly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    
    { id: 13, category_id: 3, name: "Monthly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 14, category_id: 3, name: "Monthly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 15, category_id: 3, name: "Monthly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 16, category_id: 3, name: "Monthly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 17, category_id: 3, name: "Monthly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 18, category_id: 3, name: "Monthly Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },

    { id: 19, category_id: 4, name: "Sectoral Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 20, category_id: 4, name: "Sectoral Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 21, category_id: 4, name: "Sectoral Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 22, category_id: 4, name: "Sectoral Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 23, category_id: 4, name: "Sectoral Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 24, category_id: 4, name: "Sectoral Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    
    { id: 25, category_id: 5, name: "Annual Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 26, category_id: 5, name: "Annual Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 27, category_id: 5, name: "Annual Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 28, category_id: 5, name: "Annual Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 29, category_id: 5, name: "Annual Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 30, category_id: 5, name: "Annual Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    
    { id: 31, category_id: 6, name: "Company Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 32, category_id: 6, name: "Company Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 33, category_id: 6, name: "Company Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 34, category_id: 6, name: "Company Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 35, category_id: 6, name: "Company Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
    { id: 36, category_id: 6, name: "Company Research", created_at: getFormattedDatetime(null, "d M, Y"), uploaded_by: "Waterfront Research Team", research_file: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" },
];

export const researchCategoryList = async (limit = null) => {
    try {
        // const response = responseFormat(
        //     0,
        //     "Fetch static data",
        //     Object.values(researchCategory)
        // );

        const request = {};

        if( limit !== null ) request.limit = limit;

        const response = await api.getCall(pathList.categoryList, null, request);

        return response;
    } catch(e) {
        return responseFormat(-1, 'Failed to get research category!', []);
    }
}

export const researchCategoryGet = async (categoryId) => {
    try {
        // const response = responseFormat(
        //     0,
        //     "Fetch static data",
        //     researchCategory[categoryId]
        // );
        const response = await api.getCall(pathList.categoryGet, categoryId);

        return response;
    } catch(e) {
        return responseFormat(-1, 'Failed to get research category!', []);
    }
}

export const researchList = async (categoryId = 0, year = "", sort = 0, keyword = "") => {
    try {
        // const filteredSearch = research
        //                         .filter( (item) => {
        //                             const thisYear = getFormattedDatetime(item.created_at, "Y");
        //                             const matchCategory = category == null || item.category_id === category;
        //                             const matchYear = year == null || thisYear === year;
        //                             const matchKeyword = keyword == "" || item.name.indexOf(keyword) > -1

        //                             return matchCategory && matchYear && matchKeyword;
        //                         } )
        //                         .sort( (a, b) => {
        //                             return sort == true ? b.id - a.id : a.id - b.id;
        //                         } );

        // const response = responseFormat(
        //     0,
        //     "Fetch static data",
        //     filteredSearch
        // );

        const response = await api.getCall(pathList.list, null, {
            categoryId,
            year,
            sort,
            keyword
        });

        for( let index in response.data ) {
            response.data[index].created_at = getFormattedDatetime(response.data[index].created_at, "d M, Y");
        }

        return response;
    } catch(e) {
        return responseFormat(-1, 'Failed to get research!', []);
    }
};