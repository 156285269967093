import React, {Component, useRef, useState} from 'react';
import './ContactForm.scss';
import Init from '../../../Init/Init';
import FormInput from '../../Utilities/FormInput/FormInput';
import { Col, Row, Toast } from 'react-bootstrap';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';
import { submitInquiry } from '../../../Data/Inquiry';

import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = (props) => {
    const { FontAwesomeIcon, config } = Init;
    const { address, email, phone, fax, website } = props;
    const contacts = [
        { 
            icon: `${ config.markerIcon }`,
            // icon: ['fas', 'map-marker'], 
            label: "Head Office", value: address 
        },
        { 
            icon: `${ config.emailIcon }`,
            // icon: ['fas', 'envelope'], 
            label: "", value: email 
        },
        { 
            icon: `${ config.phoneIcon }`,
            // icon: ['fas', 'phone'], 
            label: "", value: phone 
        },
        { 
            icon: `${ config.faxIcon }`,
            // icon: ['fas', 'print'], 
            label: "", value: fax 
        },
        { 
            icon: `${ config.browserIcon }`,
            // icon: ['fas', 'globe'], 
            label: "", value: website 
        }
    ];
    const [ toast, setToast ] = useState({
        icon: null,
        title: "",
        message: "",
        open: false
    });
    const [allowSubmit, setAllowSubmit] = useState(false);

    const formName = useRef();
    const formCompanyName = useRef();
    const formEmail = useRef();
    const formPhoneNo = useRef();
    const formSubject = useRef();
    const formMessage = useRef();
    const captchaRef = useRef();

    const send = async () => {
        const name = formName.current.value;
        const companyName = formCompanyName.current.value;
        const email = formEmail.current.value;
        const phoneNo = formPhoneNo.current.value;
        const subjectType = formSubject.current.value;
        const message = formMessage.current.value;

        // console.log({
        //     name,
        //     companyName,
        //     email,
        //     phoneNo,
        //     subjectType,
        //     message
        // });

        const response = await submitInquiry(name, companyName, email, phoneNo, subjectType, message);
        let toastCopy = {
            ...toast
        }

        if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
            // Toast
            toastCopy.icon = <FontAwesomeIcon className="text-success mr-2" icon={['fas', 'check-circle']} />
            toastCopy.title = "Success";
            toastCopy.message = response.message;

            formName.current.value = "";
            formCompanyName.current.value = "";
            formEmail.current.value = "";
            formPhoneNo.current.value = "";
            formSubject.current.value = "";
            formMessage.current.value = "";
        } else {
            // Toast
            toastCopy.icon = <FontAwesomeIcon className="text-danger mr-2" icon={['fas', 'times-circle']} />
            toastCopy.title = "Failed";
            toastCopy.message = response.message;
        }

        setToast({
            ...toastCopy,
            open: true
        });
    }
    
    const closeToast = () => {
        setToast({
            ...toast,
            open: false
        });
    }

    // Captcha
    const inputCaptcha = (event) => {
        console.log("Change Captcha");
        // console.log(captchaRef);
        setAllowSubmit( captchaRef.current.getValue() !== null );
    }

    const submitCaptcha = (event) => {
        console.log("Submitted captcha");
        console.log(captchaRef);
    }
    
    return (
        <div className="contact-form">
            <Toast className="contact-form-toast" show={toast.open} onClose={ () => closeToast() }>
                <Toast.Header>
                    <strong className="me-auto">{ toast.title }</strong>
                </Toast.Header>
                <Toast.Body>{ toast.icon } { toast.message }</Toast.Body>
            </Toast>
            {/* 
                1. Contact Info
                2. Contact Form
            */}
            <div className="contact-form-info">
                <h2 className="contact-form-info-title">
                    Get in touch
                </h2>
                {/* 
                    1. Address
                    2. Email
                    3. Phone
                    4. Fax
                    5. Website
                */}
                <div className="contact-form-info-content">
                    {
                        contacts.map( (item, index) => {
                            return (
                                <div key={index} className="contact-form-info-content-item">
                                    {
                                        item.label !== "" ? (
                                            <>
                                                <div className="contact-form-info-content-item-label">
                                                    {/* <FontAwesomeIcon icon={item.icon} /> */}
                                                    <img src={`${ item.icon }`} />
                                                    <b> {item.label} </b>
                                                </div>
                                                <div className="contact-form-info-content-item-value" dangerouslySetInnerHTML={{__html: `${ item.value }`}}>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="contact-form-info-content-item-label">
                                                {/* <FontAwesomeIcon icon={item.icon} /> */}
                                                <img src={`${ item.icon }`} />
                                                <span dangerouslySetInnerHTML={{__html: `${ item.value }`}}></span>
                                            </div>
                                        )
                                    }

                                </div>
                            );
                        } )
                    }
                </div>
            </div>
            <div className="contact-form-form">
                {/* 
                    1. Name
                    2. Company
                    3. Email
                    4. Phone No
                    5. Subject
                    6. Message
                */}
                <h2 className="contact-form-form-title">
                    Send us a message
                </h2>
                <Row className="contact-form-form-form">
                    <FormInput 
                        name="name"
                        attribute={{
                            label: "Nama",
                            value: "",
                            width: 6,
                            placeholder: "",
                            type: 'text',
                            autoComplete: false,
                            ref: formName,
                            required: true
                        }}
                    />
                    <FormInput 
                        name="company_name"
                        attribute={{
                            label: "Nama Perusahaan",
                            value: "",
                            width: 6,
                            placeholder: "",
                            type: 'text',
                            autoComplete: false,
                            ref: formCompanyName,
                            required: true
                        }}
                    />
                    <FormInput 
                        name="email"
                        attribute={{
                            label: "Email",
                            value: "",
                            width: 6,
                            placeholder: "",
                            type: 'text',
                            autoComplete: false,
                            ref: formEmail,
                            required: true
                        }}
                    />
                    <FormInput 
                        name="phone_no"
                        attribute={{
                            label: "Phone Number",
                            value: "",
                            width: 6,
                            placeholder: "",
                            type: 'text',
                            autoComplete: false,
                            ref: formPhoneNo,
                            required: true
                        }}
                    />
                    <FormInput 
                        name="subject_type"
                        attribute={{
                            label: "Select Subject",
                            value: 1,
                            width: 12,
                            type: 'radio',
                            ref: formSubject,
                            required: true,
                            options: [
                                { value: 1, label: "General" }
                            ]
                        }}
                    />
                    {/* Radio Buttons */}
                    <FormInput 
                        name="message"
                        attribute={{
                            label: "Message",
                            value: "",
                            width: 12,
                            placeholder: "Write message...",
                            type: "textarea",
                            autoComplete: false,
                            ref: formMessage,
                            required: true
                        }}
                    />
                    <Col className="contact-form-captcha">
                        <ReCAPTCHA
                            ref={captchaRef}
                            sitekey={ config.googleCaptchaSiteKey }
                            onChange={ inputCaptcha }
                        />
                    </Col>
                    <Col className="contact-form-submit-btn-container" lg={12}>
                        <PrimaryButton 
                            text="Kirim Pesan"
                            customClass="contact-form-submit-btn"
                            onClick={() => send()}
                            disabled={ !allowSubmit }
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ContactForm;