import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './Compliance.scss';
import ContactInfo from '../../../Data/Contact';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

const Compliance = (props) => {
    const { aboutUs } = Init.config.sectionAssets;

    return (
        <div 
            id="compliance" 
            className="compliance"
        >
            <div className="compliance-section">
                <div className="compliance-header-container">
                    <h2 className='compliance-title'>
                        Izin Usaha
                    </h2>
                </div>
                <p className="compliance-desc">
                    PT Waterfront Sekuritas Indonesia memiliki Lisensi Broker-Dealer No. KEP-268/PM/1992 tanggal 18 Juni 1992 yang diterbitkan oleh Badan Pengawas Pasar Modal.
                </p>
                <p className="compliance-desc">
                    PT Waterfront Sekuritas Indonesia memiliki Lisensi Penjamin Emisi No. KEP-02/BL/PEE/2011 tanggal 30 September 2011 yang diterbitkan oleh Badan Pengawas Pasar Modal.
                </p>
            </div>
        </div>
    );
}



export default Compliance;