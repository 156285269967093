import Homepage from '../Container/Homepage/Homepage';

import ErrorPage from '../Container/ErrorPage/ErrorPage';
import ComingSoon from '../Component/PagesComponent/ComingSoon/ComingSoon';
import ArticleDetailPage from '../Container/ArticleDetailPage/ArticleDetailPage';
import ArticlePage from '../Container/ArticlePage/ArticlePage';
import PrivacyPage from '../Container/PrivacyPage/PrivacyPage';
import TermsPage from '../Container/TermsPage/TermsPage';
import FAQPage from '../Container/FAQPage/FAQPage';

import AboutPage from '../Container/AboutPage/AboutPage';
import WinvestMobilePage from '../Container/WinvestMobilePage/WinvestMobilePage';
import WinvestDesktopPage from '../Container/WinvestDesktopPage/WinvestDesktopPage';
import WinvestWebPage from '../Container/WinvestWebPage/WinvestWebPage';
import RepoPage from '../Container/RepoPage/RepoPage';
import MarginFacilityPage from '../Container/MarginFacilityPage/MarginFacilityPage';
import InvestmentBankingPage from '../Container/InvestmentBankingPage/InvestmentBankingPage';
import ContactPage from '../Container/ContactPage/ContactPage';
import ResearchPage from '../Container/ResearchPage/ResearchPage';
import ResearchListPage from '../Container/ResearchListPage/ResearchListPage';

const Routes = () => {
    return [
        {
            path: "/",
            name: "Beranda",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: Homepage
        },
        {
            path: "/about",
            name: "Tentang Kami",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: AboutPage
        },
        {
            path: "",
            name: "Produk",
            visibility: true,
            dropdown: [
                {
                    path: "",
                    name: "Equity Trading",
                    disabled: false,
                    dropdown: [
                        {
                            path: "/winvest-mobile",
                            name: "WINVEST Mobile App",
                            disabled: true,
                        },
                        {
                            path: "/winvest-desktop",
                            name: "WINVEST Desktop App",
                            disabled: true,
                        },
                        {
                            path: "/winvest-web",
                            name: "WINVEST Web",
                            disabled: true,
                        },
                    ]
                },
                {
                    path: "",
                    name: "Transaction Financing",
                    disabled: false,
                    dropdown: [
                        // {
                        //     path: "/repo",
                        //     name: "Repurchase Agreement",
                        //     disabled: false,
                        // },
                        {
                            path: "/margin-facility",
                            name: "Margin Facility",
                            disabled: false,
                        },
                    ]
                },
                {
                    path: "/investment-banking",
                    name: "Investment Banking",
                    disabled: false,
                },
                // {
                //     path: "/",
                //     name: "Fixed Income",
                //     disabled: true,
                // },
                // {
                //     path: "/",
                //     name: "Agen Penjual Efek Reksa Dana (APERD)",
                //     disabled: true,
                // },
            ]
        },
        {
            path: "",
            name: "Links",
            visibility: true,
            dropdown: [
                {
                    path: "https://www.idx.co.id/id",
                    name: "Bursa Efek Indonesia",
                    disabled: false,
                    external: true,
                },
                {
                    path: "https://www.ojk.go.id/id/Default.aspx",
                    name: "Otoritas Jasa Keuangan",
                    disabled: false,
                    external: true,
                },
                {
                    path: "https://www.idclear.co.id/id",
                    name: "KPEI",
                    disabled: false,
                    external: true,
                },
                {
                    path: "https://www.ksei.co.id/",
                    name: "KSEI",
                    disabled: false,
                    external: true,
                },
                {
                    path: "https://akses.ksei.co.id/",
                    name: "Akses KSEI",
                    disabled: false,
                    external: true,
                },
                {
                    path: "https://www.indonesiasipf.co.id/",
                    name: "Indonesia SIPF",
                    disabled: false,
                    external: true,
                },
            ]
        },
        {
            path: "/winvest-mobile",
            name: "Winvest Mobile App",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: WinvestMobilePage
        },
        {
            path: "/winvest-desktop",
            name: "Winvest Desktop App",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: WinvestDesktopPage
        },
        {
            path: "/winvest-web",
            name: "Winvest Web App",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: WinvestWebPage
        },
        // {
        //     path: "/repo",
        //     name: "Repurchase Agreement",
        //     visibility: false,
        //     showNav: true,
        //     showFooter: true,
        //     component: RepoPage
        // },
        {
            path: "/margin-facility",
            name: "Margin Facility",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: MarginFacilityPage
        },
        {
            path: "/investment-banking",
            name: "Investment Banking",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: InvestmentBankingPage
        },
        {
            path: "/research",
            name: "Riset",
            visibility: true,
            showNav: true,
            showFooter: true,
            whiteBg: true,
            component: ResearchPage
        },
        {
            path: "/research/:id",
            name: "Kumpulan Riset",
            visibility: false,
            showNav: true,
            showFooter: true,
            whiteBg: true,
            component: ResearchListPage
        },
        {
            path: "/contact",
            name: "Kontak Kami",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: ContactPage
        },
        {
            path: "/faq",
            name: "Bantuan",
            visibility: true,
            showNav: true,
            showFooter: false,
            component: FAQPage
        },
        {
            path: "/article/:title",
            name: "Article Detail",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: ArticleDetailPage
        },
        {
            path: "/article/",
            name: "Article List",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: ArticlePage
        },
        {
            path: "/privacy-policy",
            name: "Privacy Policy",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: PrivacyPage
        },
        {
            path: "/terms-and-condition",
            name: "Terms & Condition",
            visibility: false,
            showNav: true,
            showFooter: true,
            component: TermsPage
        },
        {
            path: "/coming-soon",
            name: "Coming Soon",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: ComingSoon
        },
        {
            path: "*",
            name: "Default",
            visibility: false,
            showNav: true,
            showFooter: true,
            whiteBg: true,
            component: ErrorPage
        }
    ];
}

export default Routes;