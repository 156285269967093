import React, { useContext, useEffect, useRef } from 'react';
import PrimaryButton from '../../Utilities/PrimaryButton/PrimaryButton';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import Init from '../../../Init/Init';
import './Header.scss';

import { buildWaLink, legalServiceLink, packageLink } from '../../../Util/Util';

let config = Init.config;
let { imageFolder, headerFolder, carousel, logogram } = config;

const TemplateOne = ({ index, backgroundImage, mascot, header, text, ctaButton, affirmation, displayButtonOnMobileView = false }) => {
    return (
        <div id={`header-${index}`} className="header" style={{
            // background: `url(${headerFolder}${backgroundImage})`,
            // backgroundPosition: `center`,
            // backgroundSize: `cover`
        }}>
            <div className="header-logogram">
                <img src={logogram.third} alt="" />
            </div>
            <div className={`header-content ${ index % 2 === 0 ? 'reversed' : '' }`}>
                {/* <div className="header-logo">
                    <img src={imageFolder + mascot} alt="Waterfront Sekuritas Logo" />
                </div> */}
                <div className="header-title-container">
                    <h1 className='header-title' dangerouslySetInnerHTML={{__html: header}}>
                        
                    </h1>
                    <div className='header-tagline'> 
                        <span dangerouslySetInnerHTML={{__html: text.tagline}}>
                        </span>
                        {
                            text.whisper && (
                                <small>({ text.whisper })</small>
                            )
                        }
                    </div>
                    <div className="header-cta-button-container">
                        {
                            ctaButton.map( (item, index) => {
                                const buttonClick = item.hasOwnProperty('callback') ? item.callback : null;

                                return (
                                    <PrimaryButton 
                                        customClass={`header-cta-button ${ displayButtonOnMobileView ? '' : 'd-none' } `} 
                                        text={ item.text } 
                                        href={ item.url } 
                                        onClick={ buttonClick }
                                        key={ index }
                                    />
                                )
                            } )
                        }
                    </div>
                    <small className="notes" dangerouslySetInnerHTML={{__html: affirmation}}>
                    </small>
                </div>
            </div>
        </div>
    );
}

const HeaderCarousel = (props) => {
    let slider = [
        {
            /**
             * Background Image
             * Mascot
             * Header
             * Text(Tagline, Whisper)
             * CTA Button
             * Affirmation: Working with PRP
             */
            backgroundImage: 'header-bg.png',
            header: `<span class="inline-mobile">Berpengalaman lebih dari 30 tahun</span> <span class="inline-mobile">Membantu Mitra dalam Berinvestasi</span>`,
            text: {
                tagline: `Bangun pertumbuhan aset dan raih tujuan finansial Anda bersama Waterfront Sekuritas`,
                // whisper: 'Daripada ujung-ujungnya kena masalah...'
            },
            ctaButton: [{
                text: 'Gabung Sekarang',
                url: buildWaLink("butuh", "layanan trading"),
            }],
            displayButtonOnMobileView: true,
            // affirmation: `Bekerja sama dengan <span class='underline'>Pribadi Randa & Partners Law Firm</span>`,
            templateType: 1
        }, // 1
    ]

    /**
     * Carousels
     */
    let carouselConfig = carousel;

    carouselConfig.containerClass = "header-container";
    carouselConfig.itemClass = "header-item-holder";
    carouselConfig.autoPlay = false;
    carouselConfig.autoPlaySpeed = 5000;
    carouselConfig.showDots = false;
    carouselConfig.infinite = false;
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 900 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 900, min: 650 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 650, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

   return (
        <Carousel
            swipeable={carouselConfig.swipeable}
            draggable={carouselConfig.draggable}
            showDots={carouselConfig.showDots}
            responsive={carouselConfig.responsive}
            ssr={carouselConfig.ssr} // means to render carousel on server-side.
            infinite={carouselConfig.infinite}
            autoPlay={carouselConfig.autoPlay}
            autoPlaySpeed={carouselConfig.autoPlaySpeed}
            keyBoardControl={carouselConfig.keyBoardControl}
            transitionDuration={carouselConfig.transitionDuration}
            removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
            deviceType={carouselConfig.deviceType}
            containerClass={carouselConfig.containerClass}
            dotListClass={carouselConfig.dotListClass}
            itemClass={carouselConfig.itemClass}
            centerMode={carouselConfig.centerMode}
        >
            {
                slider.length > 0 ? (
                    slider.map((v, k) => {
                        return <TemplateOne 
                            key={k} 
                            index={k} 
                            backgroundImage={ v.backgroundImage }
                            mascot={ v.mascot }
                            header={ v.header }
                            text={ v.text }
                            ctaButton={ v.ctaButton }
                            affirmation={ v.affirmation }
                            displayButtonOnMobileView={ v.displayButtonOnMobileView }
                        />
                    })
                ) : (
                    <LoadingPage />
                )
            }
        </Carousel>
    );
}

const Header = (props) => {
    return <HeaderCarousel />
}



export default Header;