import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './MarginFacilityTarget.scss';

class MarginFacilityTarget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            target: [
                {
                    title: "Pengalaman Investasi",
                    description: "Paham dengan risiko leverage dan strategi mitigasi risiko.",
                    image: `${ Init.config.sectionAssets.marginFacility.target1 }`
                },
                {
                    title: "Portfolio Stabil",
                    description: "Memiliki portfolio yang mampu menahan fluktuasi pasar.",
                    image: `${ Init.config.sectionAssets.marginFacility.target2 }`
                },
                {
                    title: "Tujuan Jangka Pendek & Menengah",
                    description: "Ideal untuk investasi yang mencari hasil dalam waktu singkat atau menengah.",
                    image: `${ Init.config.sectionAssets.marginFacility.target3 }`
                },
            ]
        }
    }

    render() {
        const { marginFacility } = Init.config.sectionAssets;
        
        return (
            <div className="margin-facility-target">
                <div className="margin-facility-target-wrapper">
                    <div className="margin-facility-target-title">
                        Margin Facility: Dirancang untuk Anda yang Ingin Berinvestasi Lebih Cerdas
                    </div>
                    <div className="margin-facility-target-items">
                        {
                            this.state.target.map((v, k) => {
                                return (
                                    <div key={k} className="margin-facility-target-item">
                                        <img src={v.image} alt={ v.title } className='margin-facility-target-item-icon' />
                                        <h3 className="margin-facility-target-item-title">
                                                { v.title }
                                        </h3>
                                        {/* <a className="margin-facility-target-item-title-clickable" href={ v.href }>
                                        </a> */}
                                        {
                                            v.hasOwnProperty('description') ? (
                                                <p className="margin-facility-target-item-desc">
                                                    { v.description }
                                                </p>
                                            ) : ''
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default MarginFacilityTarget;