import React, {Component} from 'react';
import './Research.scss';

import Init from '../../../Init/Init';
import ResearchCategory from '../ResearchCategory/ResearchCategory';
import ResearchTitleHeader from '../ResearchTitleHeader/ResearchTitleHeader';

class Research extends Component {
    constructor(props) {
        super(props);
        
        this.config = Init.config;
        this.state = {
            
        }

    }

    resizeEvent(event) {}

    scrollEvent(event) {}

    componentDidMount() {
        window.addEventListener('resize', this.resizeEvent);
        window.addEventListener('scroll', this.scrollEvent);

        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
     }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEvent);
        window.removeEventListener('scroll', this.scrollEvent);
    }

    render() {
        return (
            <div className="research">
                {/* 
                    1. ResearchTitleHeader
                    2. ResearchCategory
                */}
                <ResearchTitleHeader />
                <ResearchCategory limit={this.props.limit} />
            </div>
        );
    }
}

export default Research;