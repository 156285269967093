import React, {Component, Fragment, createRef} from 'react';
import { Image, Col, Row, Form, Button } from 'react-bootstrap';
import Init from '../../../Init/Init';
import './Footer.scss';

import FormInput from '../../Utilities/FormInput/FormInput';

import ContactInfo from '../../../Data/Contact';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

const { FontAwesomeIcon } = Init;
const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Footer Contact';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
}

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerInfo: ContactInfo(),
            footerForm: {
                email: createRef(),
            },
            prohibitSubmit: true
        };

        this.config = Init.config;
        this.formData = {};
    }

    formUpdate(name, value) {
        this.formData[name] = value;

        if(
            ( this.formData.hasOwnProperty('name') && this.formData.name ) 
            && 
            (this.formData.hasOwnProperty('email') && this.formData.email) 
        ) {
            this.prohibitSubmit(false);
        } else {
            this.prohibitSubmit();
        }
    }

    prohibitSubmit(prohibit = true) {
        let state = this.state;
        state.prohibitSubmit = prohibit;

        this.setState(state);
    }

    recursiveIteration(data, keys, result = '', counter = 0) {
        let max = keys.length;

        if(counter >= max) {
            return result;
        } else {
            return keys[counter] + "=" + encodeURIComponent(data[keys[counter]]) + "&" + this.recursiveIteration(data, keys, result, counter + 1);
        }
    }

    formSubmit(event) {
        this.trackGa('Click Submit Button', 'Form Submission');

        let state = this.state;
        let queryString = "";
        let formDataKeys = Object.keys(this.formData);
        let contactInfo = state.footerInfo;
        
        queryString = this.recursiveIteration(this.formData, formDataKeys);

        window.open("mailto:" + contactInfo.email + "?" + queryString.substring(0, queryString.lastIndexOf("&")), "_blank");

        let formElementKeys = Object.keys(state.footerForm);

        for(let i = 0; i < formElementKeys.length; i++) {
            let key = formElementKeys[i];
            state.footerForm[key].value.current.value = "";
        }

        this.prohibitSubmit(true);
    }

    trackGa(action, label) {
        GaTracker({
            action,
            label
        });
    }

    render() {
        let state = this.state;
        let config = this.config;
        let footerInfo = state.footerInfo;

        return (
            <>
                <footer id="contact" className={
                    "footer-contact" 
                    // + 
                    // (this.props.show ? '' : ' d-none')
                }>
                    <div className="footer-contact-content">
                        <div className="content-column first-column">
                            {/* 
                                1. Logo
                                2. Email input
                                3. Address
                            */}
                            {/* <img src={ this.config.imageLogo } alt="" className='contact-logo' /> */}
                            <div className="contact-email input-group">
                                <input type="text" ref={this.state.footerForm.email} className="form-control" placeholder="Enter your email" />
                                <button className="btn-subscribe input-group-append">
                                    <span className="input-group-text">Subscribe Now</span>
                                </button>
                            </div>
                            <div className="contact-address-footer">
                                <h5 className="title">PT Waterfront Sekuritas Indonesia</h5>
                                { footerInfo.address }
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-gridable">
                                <div className="content-column">
                                    {/* 
                                        1. Title
                                        2. Items
                                    */}
                                    <h3 className="content-column-title">
                                        Produk
                                    </h3>
                                    <div className="content-column-items">
                                        <span className="content-column-item disabled">
                                            Online Trading
                                        </span>
                                        <a href="/margin-facility" className="content-column-item">
                                            Margin Facility
                                        </a>
                                        {/* <a href="/repo" className="content-column-item">
                                            Repurchase Agreement
                                        </a> */}
                                        <a href="/investment-banking" className="content-column-item">
                                            Investment Banking
                                        </a>
                                        {/* <a href="" className="content-column-item">
                                            Agen Penjual Efek Reksa Dana (APERD)
                                        </a> */}
                                        {/* <a href="" className="content-column-item">
                                            Fixed Income
                                        </a> */}
                                    </div>
                                </div>
                                <div className="content-column">
                                    {/* 
                                        1. Title
                                        2. Items
                                    */}
                                    <h3 className="content-column-title">
                                        Perusahaan
                                    </h3>
                                    <div className="content-column-items">
                                        <a href="/about" className="content-column-item">
                                            Tentang Kami
                                        </a>
                                        <a href="/research" className="content-column-item">
                                            Riset
                                        </a>
                                        {/* <a href="" className="content-column-item">
                                            Kebijakan Privasi
                                        </a>
                                        <a href="" className="content-column-item">
                                            Syarat & Ketentuan
                                        </a> */}
                                    </div>
                                </div>
                                <div className="content-column">
                                    {/* 
                                        1. Title
                                        2. Items
                                    */}
                                    <h3 className="content-column-title">
                                        Bantuan
                                    </h3>
                                    <div className="content-column-items">
                                        <a href="/faq" className="content-column-item">
                                            FAQ
                                        </a>
                                        <a href="/contact" className="content-column-item">
                                            Kontak Kami
                                        </a>
                                        {/* <a href="" className="content-column-item">
                                            Pusat Bantuan
                                        </a> */}
                                    </div>
                                </div>
                                <div className="content-column">
                                    {/* 
                                        1. Title
                                        2. Items
                                    */}
                                    <h3 className="content-column-title">
                                        Download App
                                    </h3>
                                    <div className="content-column-items">
                                        <a href="/google-play" className={"content-column-item download"}>
                                            <img src={this.config.sectionAssets.winvest.googlePlay} alt="Google Play Store" />
                                        </a>
                                        <a href="/app-store" className={"content-column-item download"}>
                                            <img src={this.config.sectionAssets.winvest.appStore} alt="Apple App Store" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="supporter-logos">
                                {/* 
                                    bursa efek = https://www.idx.co.id/id
                                    OJK = https://www.ojk.go.id/id/Default.aspx
                                    KPEI = https://www.idclear.co.id/id
                                    KSEI = https://www.ksei.co.id/
                                    akses KSEI = https://akses.ksei.co.id/
                                    indonesia SIPF = https://www.indonesiasipf.co.id/ 
                                */}
                                <a target="_blank" href="https://www.ojk.go.id/id/Default.aspx" className="supporter-logo">
                                    <img src={this.config.sectionAssets.supporter.ojkTransparent} alt={`Otoritas Jasa Keuangan`} />
                                </a>
                                <a target="_blank" href="https://www.idx.co.id/id" className="supporter-logo">
                                    <img src={this.config.sectionAssets.supporter.idxTransparent} alt={`IDX`} />
                                </a>
                                <a target="_blank" href="https://www.idclear.co.id/id" className="supporter-logo">
                                    <img src={this.config.sectionAssets.supporter.idclearTransparent} alt={`IDClear`} />
                                </a>
                                <a target="_blank" href="https://www.ksei.co.id/" className="supporter-logo">
                                    <img src={this.config.sectionAssets.supporter.kseiTransparent} alt={`KSEI`} />
                                </a>
                                <a target="_blank" href="https://www.indonesiasipf.co.id/" className="supporter-logo">
                                    <img src={this.config.sectionAssets.supporter.sipfTransparent} alt={`SIPF`} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="contact-info-item copyright">
                        {/* 
                            2 Flex rows
                            - Socmed and Copyright
                            - Logo filling the vw
                        */}
                        <hr />
                        <div className="copyright-container">
                            <div className="socmed-logos">
                                {
                                    footerInfo.socmed.map( (item, index) => {
                                        return (
                                            <a key={index} href={`${ item.link }`} className="socmed-item-logo">
                                                <FontAwesomeIcon icon={item.faIcon} />
                                            </a>
                                        );
                                    } )
                                }
                            </div>
                            <div className="copyright-text">
                                PT Waterfront Sekuritas Indonesia is licensed and supervised by the Otoritas Jasa Keuangan (OJK).
                                <br />
                                © 2024 Waterfront Sekuritas Indonesia. All Rights Reserved
                            </div>
                            {/* <div className="copyright-text">
                            </div> */}
                        </div>
                        <div className="logo-container">
                            <img src={`${ Init.config.imageLogoAlt }`} />
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;