import React, {Component} from 'react';
import Init from '../../../Init/Init';
import './RepoNotes.scss';

class RepoNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <div className="repo-notes">
                <p className='repo-notes-content'>
                    <strong>Repurchase Agreement (REPO)</strong> adalah transaksi jual-beli kembali efek dengan janji beli kembali pada waktu dan harga yang telah ditetapkan. Repo merupakan jenis transaksi pembiayaan keamanan yang juga dikenal sebagai perjanjian jual-dan-beli kembali. Dalam transaksi repo, peminjam meminjamkan sekuritas kepada pemberi pinjaman secara tunai dengan perjanjian untuk membelinya kembali di masa mendatang. Kepemilikan sekuritas tidak berpindah tangan dalam transaksi repo.
                </p>
            </div>
        );
    }
}

export default RepoNotes;